import React, { useState, useEffect } from 'react';
import { Divider, Grid, List } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import ListHead from 'components/ui/list/head/ListHead';
import { Topbar } from 'components/ui/topbar/Topbar';
import AnnouncementItem from './AnnouncementItem';
import { getAnnouncements, deleteAnnouncement as deleteAnnouncementFn } from 'utils/AnnouncementsService';

const Announcements = (props) => {
  const { t } = useTranslation();
  const title = t('announcementsUi.Announcements');
  const listHead = t('announcementsUi.NewAnnouncement');
  const { enqueueSnackbar } = useSnackbar();
  const [announcements, setAnnouncements] = useState([]);

  const onCreate = () => {
    props.history.push({
      pathname: '/announcements/form',
      state: {
        announcement: {
          id: '',
          name: '',
          disabled: false,
          translations: [],
        },
        isEditType: false,
      },
    });
  };

  const deleteAnnouncement = async (id) => {
    try {
      const { affectedIds } = await deleteAnnouncementFn(id);
      if (affectedIds && affectedIds.length) {
        fetchAnnouncements();

        enqueueSnackbar(t('announcementsUi.AnnouncementSuccessfullyDeleted'), {
          variant: 'success',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAnnouncements = async () => {
    const data = await getAnnouncements();
    setAnnouncements(data);
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <div>
      <Topbar title={title} />
      <Grid item pt={7}>
        <ListHead title={listHead} onCreate={onCreate} />
        <List>
          <Divider />
          {announcements && announcements.map((item) => <AnnouncementItem key={item.id} item={item} deleteAnnouncement={deleteAnnouncement} />)}
        </List>
      </Grid>
    </div>
  );
};

export default Announcements;
