import React, { Component } from 'react';
import './SubSidenav.scss';
import { withTranslation } from 'react-i18next';
import { Divider, ListItemIcon, ListItemText, ListItem, Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class SubSidenav extends Component {
    constructor() {
        super();
        this.state = {
            open: false
        }
    }

    open() {
        this.setState({ open: !this.state.open });
    }

    render() {

        const { t, item, showItem } = this.props;
        const { open } = this.state;

        const handleClick = () => {
            this.open();
        }

        return (
            <div>
                <ListItem className='list-item-button' onClick={handleClick} >
                    <ListItemIcon >
                        <FontAwesomeIcon icon={item.icon} />
                    </ListItemIcon>
                    <ListItemText>{t(item.title)}</ListItemText>
                    {open ?
                        <ListItemIcon className="itemIcon">
                            <FontAwesomeIcon icon={faChevronUp} />
                        </ListItemIcon>
                        :
                        <ListItemIcon className="itemIcon" >
                            <FontAwesomeIcon icon={faChevronDown} />
                        </ListItemIcon>}
                </ListItem>
                <Divider />
                <Collapse in={open} title="auto">
                    {
                        item.subItems.map((e, i) =>
                        ((showItem[e.title] == true || showItem[e.title] == null) && 
                        !e.isHidden &&
                            <Link key={i} to={e.path} className="ListItem" replace >
                                <ListItem>
                                    <ListItemIcon className="itemIcon">
                                        <FontAwesomeIcon icon={faChevronDown} style={{ display: 'none' }} />
                                    </ListItemIcon>
                                    <ListItemText>{t(e.title)}</ListItemText>
                                </ListItem>
                                <Divider />
                            </Link>)
                        )
                    }
                </Collapse>
            </div>
        )
    }
}

export default withTranslation()(SubSidenav);