import { axiosA } from './AuthService';

const getPublicTerrtories = async () => {
    const { data } = await axiosA.get('/PublicLot/public')
    return data
}

const getParkingLog = async (lotId, plateNum) => {
    try {
        const body = {
            territoryId: lotId,
            plateNum: plateNum
        }
        const { data } = await axiosA.post('/PublicLot/parkingLog', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const getPublicLot = async (parkingLotId) => {
    try {
        const body = {
            //	territoryId: territoryId,
            parkingLotId: parkingLotId
        }
        const { data } = await axiosA.post('/PublicLot/publicLot', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const getParkingLogById = async (parkingLogId, territoryId) => {
    try {
        const body = {
            territoryId: territoryId,
            parkingLogId: parkingLogId
        }
        const { data } = await axiosA.post('/PublicLot/parkingLogById', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const getCurrentLot = async (plateNum) => {
    try {
        const body = {
            plateNum: plateNum
        }
        const { data } = await axiosA.post('/PublicLot/currentLot', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

export {
    getPublicTerrtories,
    getParkingLog,
    getPublicLot,
    getParkingLogById,
    getCurrentLot
}