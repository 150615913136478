import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid } from '@mui/material';
import PublicTopbar from 'components/public/PublicTopbar';
import { isNative } from '../../utils/bridge.js';

class TermsAndConditions extends Component {
  render() {
    const { t } = this.props;
    return (
      <Grid container direction="column" style={{ height: '100vh' }}>
        <PublicTopbar title={t('Login3.Conditions')}/>
        
        <div style={{ flex: 1, overflow: 'hidden'}}>
          <div style={{ width: isNative() ? "100%" : "calc(100% + 1rem)", height: "100%"}}>
            <iframe
              id='terms-iframe'
              title='Terms and conditions'
              src={t('Login3.TermsAndConditionsUrl')}
              width="100%"
              height="100%"
              overflow='hidden'
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </Grid>
    );
  }
}

export default withSnackbar(withTranslation()(TermsAndConditions));
