import React, { useState, useEffect } from 'react';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import icon_clock from 'assets/icons/publicApp/icon_clock.svg';
import BookingDateTimePicker from './BookingDateTimePicker';

const BookingTimeDataCard = ({ readOnly = false, value, onModifyAfterFinishedSelection, controlledSelectedField, onFinishSelection, payment, parkingLot, showNoVacantBookings }) => {
  const { t } = useTranslation();
  const [vatAmount, setVatAmount] = useState(0.0);
  const [withoutVatAmount, setWithoutVatAmount] = useState(0.0);
  const [totalTime, setTotalTime] = useState('0 h 0 min');

  const formatTime = (days, hours, mins) => {
    // string format - 00d 00h 00min
    let dateString = '';
    if (days !== '00' && days !== 0) {
      dateString = `${days} d `;
    }
    dateString += `${hours} h ${mins} min`;

    return dateString;
  };

  useEffect(() => {
    if (payment && payment.amount) {
      setVatAmount(getVatValue());
      setWithoutVatAmount(getWithoutVatValue());
    }
  }, [payment]);

  useEffect(() => {
    if (value && value.start && value.end) {
      setTotalTime(getTotalTime(value.start, value.end));
    }
  }, [value]);

  const getVatValue = () => {
    if (payment && payment.amount && parkingLot.vat) {
      const result = (payment.amount * parkingLot.vat) / (100 + parkingLot.vat);
      return result.toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getWithoutVatValue = () => {
    if (payment && payment.amount && parkingLot.vat) {
      const result = payment.amount - getVatValue();
      return result.toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getTotalTime = (start, end) => {
    const mins = end.diff(start, 'minutes');
    var days = (mins / (60 * 24)) | 0;
    var hours = ((mins - days * 60 * 24) / 60) | 0;
    var minutes = mins % 60 | 0;

    return formatTime(days, hours, minutes);
  };

  return (
    <Card className="card-shadow" sx={{ p: 1, mx: 1, boxShadow: 0 }}>
      <Grid px={1}>
        {/* Booking time header */}
        <Grid container alignItems="center" sx={{ pb: 0.5 }}>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <img alt="icon-payment" src={icon_clock} style={{ width: 48 }} />
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" className="h4-text">
              {t('publicApp.ParkingTime')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        {/* Booking date time picker */}
        <BookingDateTimePicker
          readOnly={readOnly}
          value={value}
          onModifyAfterFinishedSelection={onModifyAfterFinishedSelection}
          controlledSelectedField={controlledSelectedField}
          onFinishSelection={onFinishSelection}
        />

        {/* Display payment data */}
        {payment && (
          <Grid>
            <Divider />
            <Grid container>
              <Grid container justifyContent="space-between">
                <Grid xs={6} item>
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1, pt: 0.75 }}>
                    {t('publicApp.TotalTime')}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end">
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, pt: 0.75, textAlign: 'right' }}>
                    {totalTime}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid xs={6} item>
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1 }}>
                    {t('publicApp.Amount')}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end">
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                    {withoutVatAmount + ' ' + parkingLot?.currencyShort}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid xs={6} item>
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pl: 1 }}>
                    {t('publicApp.VAT') + ' ' + parkingLot?.vat + '%'}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end">
                  <Typography variant="body2" className="body2-text" gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                    {vatAmount + ' ' + parkingLot?.currencyShort}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ borderColor: '#3268B2' }} />
            <Grid container>
              <Grid item xs={6}>
                <Card sx={{ boxShadow: 0 }}>
                  <Typography variant="h4" className="h4-text-bold" gutterBottom sx={{ pl: 1, pt: 1 }}>
                    {t('publicApp.TotalAmount')}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                  <Typography variant="h4" className="h4-text-bold" gutterBottom sx={{ pr: 1, pt: 1 }}>
                    {payment?.amount.toFixed(2) + ' ' + parkingLot?.currencyShort}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default BookingTimeDataCard;
