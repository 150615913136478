import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Topbar } from '../ui/topbar/Topbar';
import { List, Grid } from '@mui/material';
import ListHead from '../ui/list/head/ListHead';
import { deleteGroupUser, getGroupUsers } from 'utils/GroupUsersService';
import GroupUserItem from './GroupUserItem';
import { useSnackbar } from 'notistack';

const GroupUsers = (props) => {
  const title = i18next.t('groups.groupUsers');
  const [group, setGroup] = useState({});
  const [groupUsers, setGroupUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const goBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    const { group } = props.location.state;
    setGroup(group);
    if (group) {
      fetchData(group.id);
    }
  }, []);

  const fetchData = async (id) => {
    const data = await getGroupUsers(id);
    setGroupUsers(data);
  };

  const onCreate = () => {
    props.history.push({
      pathname: '/groupUsersAdd',
      state: {
        group: group,
      },
    });
  };

  const onDelete = async (id) => {
    const data = await deleteGroupUser(id);
    enqueueSnackbar(i18next.t('notification.success.deleteUser'), {
      variant: 'success',
    });
    await fetchData(group.id);
  };

  return (
    <div>
      <Topbar title={title} cbBack={goBack} />
      <Grid item paddingTop={7}>
        <ListHead title={group.title} onCreate={onCreate}></ListHead>
        <List>
          {groupUsers &&
            groupUsers.map((e, i) => (
              <GroupUserItem
                key={i}
                user={e}
                onDelete={onDelete}
                //history={props.history}
              />
            ))}
        </List>
      </Grid>
    </div>
  );
};
export default GroupUsers;
