import React from 'react';
import './SpaceFormHead.scss';
import background from '../../../../assets/images/exchange_place.svg';

import { Box, Typography, Grid } from '@mui/material';


const SpaceFormHead = (props) => (
  <React.Fragment>
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        paddingTop= '50px'
      >

      <img src={background}
           className="SpaceHeadBackround"
           alt="background" />
      {/*<Box className="SpaceHeadCard" >*/}
      {/*  <Typography*/}
      {/*    //  variant = 'h4' color ='#6A95CC'*/}
      {/*      className="SpaceName"*/}
      {/*  >{props.space.name}</Typography>*/}
      {/*  <Typography>{props.space.parkingLotName}</Typography>*/}
      {/*  <Box className="triangleDown" />*/}
      {/*</Box>*/}
    </Grid>
  </React.Fragment>

);

export default SpaceFormHead;
