import ActionButton from '../../ui/buttons/ActionButton';
import React, { useState } from 'react';
import DeleteDialog from '../../ui/dialogs/DeleteDialog';

const DeleteActionButton = (props) => {
  const { onConfirm, id, text} = props;
  const type = 'delete';
  const [open, setOpen] = useState(false);

  const onClick = async () => {
    await setOpen(false);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ActionButton type={type} onClick={onClick} />
      <div>
        {open && (
          <DeleteDialog
            openIt={open}
            id={id}
            onConfirm={onConfirm}
            onClose={onClose}
            text={text}
          />
        )}
      </div>
    </>
  );
};
export default DeleteActionButton;
