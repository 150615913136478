import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { List, Accordion, AccordionSummary, Typography, AccordionDetails, ListItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class ListExpanded extends Component {

    render() {
        const { names, name, t } = this.props;
        return (
            <div>
                {names && names.length > 0 ?
                    <Accordion className='disableElevation1'>
                        <AccordionSummary className='expandClass' expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}>
                            <Typography className='body' >{t('spaces.All')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List className='listExpand'>
                                {names.map((item, i) => (
                                    <ListItem className='body' key={i}>{item}</ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    : <Typography >{name}</Typography>}
            </div>
        )
    }
}

export default withTranslation()(ListExpanded);