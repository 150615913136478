import { axiosA } from './AuthService';

const getAnnouncements = async () => {
  const { data } = await axiosA.get('/Announcements');
  return data;
};

const getAnnouncementById = async (id) => {
  const { data } = await axiosA.get(`/Announcements/${id}`);
  return data;
};

const checkForAnnouncement = async (userId) => {
  try {
    const { data } = await axiosA.get(`/Announcements/check/${userId}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

const postUserReadAnnouncements = async (announcementIds, userId) => {
  try {
    const body = {
      userId: userId,
      AnnouncementIds: announcementIds
    };
    const { data } = await axiosA.post(`/Announcements/read`, body);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const postAnnouncement = async (announcement) => {
  try {
    const body = {
      name: announcement.name,
      translations: announcement.translations,
      disabled: announcement.disabled
    };
    const { data } = await axiosA.post('/Announcements', body);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const updateAnnouncement = async (announcement) => {
  const body = {
    id: announcement.id,
    name: announcement.name,
    translations: announcement.translations,
    modifiedAt: announcement.modifiedAt,
    disabled: announcement.disabled
  };
  const { data } = await axiosA.put(`/Announcements/${announcement.id}`, body);
  return data;
};

const deleteAnnouncement = async (id) => {
  const { data } = await axiosA.delete(`/Announcements/${id}`);
  return data;
};

export { getAnnouncements, getAnnouncementById, checkForAnnouncement, postUserReadAnnouncements, updateAnnouncement, postAnnouncement, deleteAnnouncement };
