import React from 'react';
import './BottomBar.scss';
import { useTranslation } from 'react-i18next';
import { AppBar, Fab, Grid, Toolbar, Box } from '@mui/material';

export default function BottomBar({ onGoBackFn, submitTitle }) {
  const { t } = useTranslation();
  const submitTitle_ = submitTitle ? submitTitle : t('general.Save')
  return (
    <AppBar color="inherit" position="fixed" className="bottom-bar" elevation={0}>
      <Toolbar>
        <Grid
          spacing={3}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/*<Grid item xs={6}>*/}
          {/*  <Fab*/}
          {/*    className="full-width primary-btn"*/}
          {/*    variant="extended"*/}
          {/*    onClick={onGoBackFn}*/}
          {/*    aria-label="Cancel"*/}
          {/*  >*/}
          {/*    <Box fontSize={14} textAlign="center">*/}
          {/*      {t('general.Cancel')}*/}
          {/*    </Box>*/}
          {/*  </Fab>*/}
          {/*</Grid>*/}
          <Grid item xs={10}>
            <Fab
              className="full-width secondary-btn"
              variant="extended"
              type="submit"
              value="Submit"
              aria-label="Save"
            >
              <Box fontSize={14} textAlign="center">
                {submitTitle_}
              </Box>
            </Fab>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
