import { getParkingRateById } from './ParkingRatesService';
import MomentUtils from '@date-io/moment';

const moment = new MomentUtils();

const getParkingPrice = async (parkingRateId) => {
  let price = 0;
  if (parkingRateId) {
    const parkingRates = await getParkingRateById(parkingRateId);
    price = parkingRates.rate;
  }
  return price;
};

const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' EUR';
};

const countWorkDays = (
  dateFrom,
  dateTo,
  freedays,
  inlcudeWeekendsInSharing
) => {
  const start = moment.date(dateFrom, 'YYYY-MM-DD');
  const end = moment.date(dateTo, 'YYYY-MM-DD');
  const freedays_ = freedays ? freedays : [];
  const holidays = freedays_.map((i) =>
    moment.date(i.start).format('YYYY-MM-DD')
  );
  let days = 0;
  for (let i = start; start.isBefore(end); i.add(1, 'days')) {
    if (
      ((i.day() !== 0 && i.day() !== 6) || inlcudeWeekendsInSharing) &&
      !holidays.includes(i)
    ) {
      days++;
    }
  }
  return days;
};

const getVacantPeriods = (from, to, excludesList, inlcudeWeekendsInSharing) => {
  try {
    const start = moment.date(from).utc(0).startOf('day');
    const end = moment.date(to).utc(0).endOf('day');

    let formattedExcludesList = [];

    formattedExcludesList = excludesList
      .map((period) => {
        const periodFrom = moment.date(period.from).utc(0).startOf('day');
        const periodTo = moment.date(period.to).utc(0).endOf('day');

        if (
          periodFrom.isBetween(start, end) ||
          periodTo.isBetween(start, end) ||
          (periodFrom <= start && periodTo >= end)
        ) {
          return {
            from: periodFrom,
            to: periodTo,
          };
        }
        return null;
      })
      .filter((period) => period !== null);

    formattedExcludesList.sort((periodA, periodB) => {
      return new Date(periodA.from) - new Date(periodB.from);
    });

    const vacantPeriods = calculateVacantPeriods(
      start,
      end,
      formattedExcludesList,
      inlcudeWeekendsInSharing
    );

    return vacantPeriods;
  } catch (error) {
    console.error(error);
  }
};

const calculateVacantPeriods = (
  start,
  end,
  excludesList,
  inlcudeWeekendsInSharing
) => {
  const startMoment = moment.date(start).utc(0);
  const endMoment = moment.date(end).utc(0);

  let vacantPeriods = [];
  let currentPeriod = { from: startMoment, to: startMoment };

  for (const excludedPeriod of excludesList) {
    const periodFrom = moment.date(excludedPeriod.from).utc(0);
    const periodTo = moment.date(excludedPeriod.to).utc(0);

    if (currentPeriod.from.isBefore(periodFrom)) {
      vacantPeriods.push({
        from: currentPeriod.from.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        to: periodFrom
          .subtract(1, 'millisecond')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      });
    }

    currentPeriod.from = periodTo.add(1, 'millisecond');
  }

  if (currentPeriod.from.isBefore(endMoment)) {
    vacantPeriods.push({
      from: currentPeriod.from.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      to: endMoment.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    });
  }


  if (!inlcudeWeekendsInSharing) {
    vacantPeriods = removeWeekendsFromVacantPeriods(vacantPeriods)
  }

  return vacantPeriods;
};

const removeWeekendsFromVacantPeriods = (vacantPeriods) => {
  const newPeriods = [];

  for (const period of vacantPeriods) {
    const startDate = moment.parse(period.from, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const endDate = moment.parse(period.to, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    while (startDate <= endDate) {
      if (startDate.day() === 0 || startDate.day() === 6) {
        // skip weekends
        startDate.add(1, 'days');
      } else {
        const newPeriod = {
          from: startDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        };

        // find the end of the current non-weekend day
        while (startDate.day() !== 0 && startDate.day() !== 6 && startDate <= endDate) {
          startDate.add(1, 'days');
        }

        newPeriod.to = moment.date(endDate).isSameOrBefore(startDate) ? endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : moment.date(startDate).subtract(1, 'milliseconds').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        newPeriods.push(newPeriod);
      }
    }
  }

  return newPeriods;
}

export { getParkingPrice, currencyFormat, countWorkDays, getVacantPeriods };
