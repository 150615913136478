import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import MomentUtils from '@date-io/moment';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import icon_parking from 'assets/icons/publicApp/icon_parking.svg';
import '../PublicParking.scss';

const PublicBookingCard = ({ parkingReceipt, openBookingReceipt }) => {
  const { t } = useTranslation();
  let isSwiping = false;
  const swipeThreshold = 5;

  const moment = new MomentUtils({ locale: 'en' });

  // using .utc() because of incorrect parkingLog datetime in Softra Core. (Should be utc in db).
  const entryDate = moment.date(parkingReceipt.bookedFrom).format('YYYY-MM-DD');
  const entryTime = moment.date(parkingReceipt.bookedFrom).format('HH:mm');
  const exitDate = moment.date(parkingReceipt.bookedTo).format('YYYY-MM-DD');
  const exitTime = moment.date(parkingReceipt.bookedTo).format('HH:mm');

  const handleOpen = () => {
    openBookingReceipt(parkingReceipt);
  };

  return (
    <motion.div
      onPanStart={() => {
        isSwiping = false;
      }}
      onPan={(event, info) => {
        if (Math.abs(info.delta.x) > swipeThreshold || Math.abs(info.delta.y) > swipeThreshold) {
          isSwiping = true;
        }
      }}
      onPanEnd={() => {
        setTimeout(() => (isSwiping = false), 500);
      }}
      onClick={() => {
        if (!isSwiping) {
          handleOpen();
        }
      }}
    >
      <Card className="card-shadow parking-history-card" sx={{ px: 1, py: 0.5, mt: 1, mx: 1 }}>
        <Grid container>
          <Grid item xs={10.5}>
            <Grid container alignItems="center" sx={{ pl: 2, py: 0.5 }}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="#3268B2" fill-opacity="0.87" />
              </svg>

              <Typography variant="string" className="booked-space-badge-text double-letter-spacing text-uppercase" sx={{ py: 0.5, pl: 1 }}>
                {t('publicApp.BookedSpace')}
              </Typography>
            </Grid>
            <Grid container alignItems="center" sx={{ pl: 1, pb: 0.5 }}>
              <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row" px={1}>
                  <Typography variant="h4" className="history-card-date-text" pr={1}>
                    {entryDate}
                  </Typography>
                  <Typography variant="h4" className="history-card-date-text" pr={1.5}>
                    {entryTime}
                  </Typography>
                  <Typography variant="h4" className="history-card-date-text" pr={1.5}>
                    –
                  </Typography>
                  <Typography variant="h4" className="history-card-date-text" pr={1}>
                    {exitDate}
                  </Typography>
                  <Typography variant="h4" className="history-card-date-text">
                    {exitTime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ pl: 1 }}>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} className="prevent-select" />
                <Grid container direction="column" pl={1}>
                  <Typography variant="body2" className="primary-body-text">
                    {parkingReceipt.territoryName}
                  </Typography>
                  <Typography variant="h4" className="history-card-address-text">
                    {parkingReceipt.territoryAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1.5} sx={{ pr: 1.5, pb: 0.5, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="#455D80" style={{ height: 24 }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </Grid>
        </Grid>
      </Card>
    </motion.div>
  );
};

export default PublicBookingCard;
