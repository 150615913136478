import { axiosA } from "utils/AuthService";

const getBusinessPayment = async (body) => {
    try {
        const { data } = await axiosA.post('/BusinessPayment/PaymentData', body)
        return data
    } catch (e) {
        throw null
    }
}

const confirmBusinessPayment = async (payment) => {
	try {
		const body = {
			id: payment.id,
			bankStatus: payment.bankStatus,
			cardStatus: payment.cardStatus,
			statusGroup: payment.statusGroup
		}
		const { data } = await axiosA.post('/BusinessPayment/confirmPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

export {
    getBusinessPayment,
    confirmBusinessPayment
}