import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Box, ButtonBase, Button, Card, Container, Divider, Typography } from '@mui/material';
import MomentUtils from '@date-io/moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faLink } from '@fortawesome/free-solid-svg-icons';

import PublicTopbar from 'components/public/PublicTopbar';
import BookingTimeDataCard from './BookingTimeDataCard';
import LicensePlate from 'components/public/vehicles/LicensePlate';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import PaySlider from 'components/ui/buttons/PaySlider';
import BankImg from '../../providers/kevin/BankImg';
import { CARD, LINKED_BANK, BANK } from 'constants/PaymentTypes';
import { ReactComponent as SwipeArrowRightIcon } from 'assets/icons/publicApp/icon_swipe_arrow_right.svg';
import { ReactComponent as MasterCardLogo } from 'assets/icons/publicApp/logo_mastercard.svg';
import { ReactComponent as VisaLogo } from 'assets/icons/publicApp/logo_visa.svg';
import icon_parking from 'assets/icons/publicApp/icon_parking.svg';
import icon_car from 'assets/icons/publicApp/icon_car.svg';
import { initiatePayment, kevin_banks } from 'utils/KevinService';
import { lastPayment, postPayment } from 'utils/PaymentsService';
import { lastCard } from 'utils/payments/eService/EServiceService';
import { cancelPublicBooking } from 'utils/PublicBookingService';

import './PublicBookingPaymentForm.scss';
import { NotificationManager } from 'react-notifications';

const PublicBookingPaymentForm = (props) => {
  const currentLng = localStorage.getItem('currentLng');
  const momentUtils = new MomentUtils({ locale: currentLng });
  const history = useHistory();
  const { t } = useTranslation();

  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [slideValue, setSlideValue] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [linkedBanks, setLinkedBanks] = useState([]);
  const [clickedCancelButton, setClickedCancelButton] = useState(false);
  const userId = props.currentUser?.id;

  const paymentProvider = 'Kevin';

  useEffect(() => {
    const linkedBanksFromStorage = JSON.parse(localStorage.getItem('kevinTokens')) ?? [];
    setLinkedBanks(linkedBanksFromStorage);

    if (props.location.state && userId) {
      fetchSelectedPaymentMethod(userId);
    }

    const timer = setTimeout(redirectToPublicParking, 10 * 60 * 1000); // after 10 minutes of selection, return user to parkingBooking page.

    return () => clearTimeout(timer);
  }, []);

  const redirectToPublicParking = () => {
    // happens when no data provided, e.g. after page reload
    history.push({
      pathname: 'publicParking',
      state: {
        initialWindowMode: 'booking',
      },
    });
  };

  if (!props.location.state) {
    redirectToPublicParking();
    return <div></div>;
  }

  const { bookedFrom, bookedTo, parkingLot, plateNum } = props.location.state;
  const payment = JSON.parse(props.location.state.payment);

  const onCancelBooking = async () => {
    setClickedCancelButton(true);
    if (payment.bookedSpaceId) {
      await cancelPublicBooking(payment.bookedSpaceId);
    }

    redirectToPublicParking();
  };

  const onCloseCancelAlert = () => {
    setClickedCancelButton(false);
    setShowCancelAlert(false);
  };

  const onCompleteBooking = () => {
    history.push({
      pathname: 'publicParking/params?initialWindowMode=booking&showNotification=true',
    });
  };

  const handleGoBack = () => {
    setShowCancelAlert(true);
  };

  const selectBank = () => {
    props.history.push({
      pathname: 'bankSelection/bank',
    });
  };

  const fetchSelectedPaymentMethod = async (userId) => {
    if (paymentProvider) {
      let selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
      if (!selectedPaymentMethod || selectedPaymentMethod.paymentType === CARD) {
        const plateNum = localStorage.getItem('selectedVehicle');
        await lastPayment(userId, plateNum).then(async (bankId) => {
          if (bankId) {
            const countryCode = bankId.slice(-2);
            localStorage.setItem('countryCode', countryCode);
            let bank = linkedBanks.find((x) => x.id === bankId);
            if (bank) {
              selectedPaymentMethod = { paymentType: LINKED_BANK, bank: bank };
              localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
              setSelectedPaymentMethod(selectedPaymentMethod);
            } else {
              await kevin_banks().then((allBanks) => {
                if (allBanks) {
                  bank = allBanks.find((x) => x.id === bankId);
                  if (bank) {
                    selectedPaymentMethod = { paymentType: BANK, bank: bank };
                    localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
                    setSelectedPaymentMethod(selectedPaymentMethod);
                  }
                }
              });
            }
          }
        });
      } else {
        setSelectedPaymentMethod(selectedPaymentMethod);
      }
    }
  };

  const handleSlide = async () => {
    if (slideValue > 90) {
      await goPayment();
    } else {
      setSlideValue(0);
    }
  };

  const goPayment = async () => {
    const bankId = selectedPaymentMethod?.bank.id;

    let paymentData = {
      redirect: `${window.location.origin}/publicParking/params?initialWindowMode=booking&showNotification=true`,
      webhook: `${window.location.origin}/api/v1/Payments/confirmPayment`,
      lot: parkingLot,
      payment: payment,
      userId: userId,
      bankId: bankId,
      plateNum: plateNum,
      provider: 'Kevin',
      paymentMethodPreferred: 'bank',
    };

    if (payment.amount > 0) {
      if (bankId) {
        // initiate bank payment
        await initiatePayment(paymentData)
          .then((kevin) => {
            if (kevin) {
              window.location.href = kevin.confirmLink;
            }
          })
          .catch((error) => {
            postPayment(paymentData, null, error.message).then(() => {
              NotificationManager.error(error.message);
            });
          });
      } else {
        // redirect to bank selection
        props.history.push({
          pathname: 'bankSelection/payment',
          state: {
            paymentData: paymentData,
          },
        });
      }
    } else {
      await postPayment(paymentData, null, null).then(() => {
        onCompleteBooking();
      });
    }
  };

  return (
    <div className="public-container" style={{ overflow: 'auto' }}>
      <PublicTopbar enableShadow title={t('publicApp.Booking')} onGoBack={handleGoBack} />

      {/* Vehicles card */}
      {plateNum && (
        <Grid id="vehicles-card" pt={1}>
          <Card className="card-shadow" sx={{ p: 1, mx: 1, boxShadow: 0 }}>
            <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1.25 }}>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img alt="icon_car" src={icon_car} style={{ width: 42 }} />
              </Grid>
              <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" className="h4-text">
                  {t('publicApp.YourVehicle')}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ px: 1 }} style={{ textAlign: 'center' }}>
              <Divider />
              <Grid container justifyContent="center" sx={{ mt: 0.75 }}>
                <LicensePlate selected={true} licenseNumber={plateNum} />
              </Grid>
            </Box>
          </Card>
        </Grid>
      )}

      {/* Parking lot card */}
      {parkingLot && (
        <Card id="parking-lot-card" className="card-shadow" sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
          <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} />
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h4" className="h4-text">
                {t('general.ParkingLot')}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ px: 1, width: '100%', textAlign: 'center' }}>
            <Divider />
            {parkingLot && (
              <>
                <Grid sx={{ ml: 1, my: 1, textAlign: 'left' }}>
                  <Typography variant="h4" className="h4-text" gutterBottom>
                    {parkingLot.name}
                  </Typography>
                  {parkingLot.address && (
                    <Typography variant="h4" className="body1-text">
                      {parkingLot.address}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </Card>
      )}

      {/* Booking time card */}
      {bookedFrom && bookedTo && payment && parkingLot && (
        <Grid id="booking-time-card" pt={1}>
          <BookingTimeDataCard readOnly={true} value={{ start: momentUtils.date(bookedFrom), end: momentUtils.date(bookedTo) }} payment={payment} parkingLot={parkingLot} />
        </Grid>
      )}

      {/* Payment slider */}
      <Container maxWidth={false} className="booking-payment-footer-white">
        <Grid container justifyContent="center" sx={{ pb: 4, pt: selectedPaymentMethod ? 1 : 4 }}>
          {selectedPaymentMethod && paymentProvider === 'Kevin' && (
            <Grid container alignItems="center" sx={{ height: '30px', mb: 2 }}>
              <ButtonBase onClick={selectBank}>
                {selectedPaymentMethod.paymentType && selectedPaymentMethod.paymentType === LINKED_BANK && (
                  <Grid sx={{ ml: 1 }}>
                    <FontAwesomeIcon icon={faLink} color="#2B4469" />
                  </Grid>
                )}
                <Grid sx={{ mx: 1 }}>
                  {selectedPaymentMethod.paymentType === CARD ? (
                    <>
                      <VisaLogo />
                      <MasterCardLogo />
                    </>
                  ) : (
                    <BankImg item={selectedPaymentMethod.bank} tinyVariant={true} />
                  )}
                </Grid>
                <Box mr={1}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              </ButtonBase>
            </Grid>
          )}
          <Card className="parking-footer-button">
            <Grid container height="100%" alignItems="center">
              <Grid item xs={2}></Grid>
              <Grid item xs={7} sx={{ alignItems: 'center' }}>
                <Grid className="swipe-arrows" sx={{ bottom: '38px !important' }}>
                  <SwipeArrowRightIcon />
                  <SwipeArrowRightIcon />
                  <SwipeArrowRightIcon />
                  <SwipeArrowRightIcon />
                  <SwipeArrowRightIcon />
                </Grid>
                <Grid className="text-overlay">
                  <Typography variant="h4" className="h4-text-success">
                    {t('publicApp.GoPayment')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4" className="h4-text-success">
                  {payment && payment.amount && parkingLot.currencyShort && `${payment.amount.toFixed(2)} ${parkingLot.currencyShort}`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <PaySlider id="pay-slider" onChange={(event, value) => setSlideValue(value)} onChangeCommitted={handleSlide} step={1} disabled={!paymentProvider} value={slideValue} sx={{ bottom: '54px' }} />
      </Container>

      {/* Cancel booking alert */}
      <PopUpDrawer isWarningMessage header={t('publicApp.CancelBooking')} text={t('publicApp.AreYouSureCancelBooking')} showDrawer={showCancelAlert} onClose={onCloseCancelAlert}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={5} mt={2} mb={4}>
            <Button id="btn-cancel-delete" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={onCloseCancelAlert} sx={{ height: '48px' }}>
              <Typography variant="string" className="button-text">
                {t('general.No')}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={5} mt={2} mb={4}>
            <Button
              id="btn-confirm-delete"
              disableElevation
              disabled={clickedCancelButton}
              type="button"
              variant="contained"
              color="secondary"
              className={clickedCancelButton ? 'button-primary-disabled' : 'button-primary'}
              onClick={onCancelBooking}
            >
              <Typography variant="string" className="button-text">
                {t('general.Yes')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </PopUpDrawer>

      {/* Empty space for small screens */}
      <Box pt={selectedPaymentMethod ? 18 : 15} />
    </div>
  );
};

export default PublicBookingPaymentForm;
