import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Topbar } from '../ui/topbar/Topbar';
import { List, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import ListHead from '../ui/list/head/ListHead';
import { getParkingGroups } from 'utils/ParkingGroupsService';
import ParkingGroupItem from './ParkingGroupItem';
import { deleteParkingGroup } from 'utils/ParkingGroupsService';

const ParkingGroups = (props) => {
  const title = i18next.t('groups.groups');
  const listHead = i18next.t('groups.parkingGroups');
  const [groups, setGroups] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const goBack = () => {
    props.history.goBack();
  };

  const onCreate = () => {
    props.history.push({
      pathname: '/parkingGroup/form',
      state: {
        group: {
          id: null,
          title: '',
          isClosed: false,
        },
        isChange: false,
      },
    });
  };

  const getGroups = async () => {
    const data = await getParkingGroups();
    setGroups(data);
  };

  const deleteGroup = async (id) => {
    const deleteGroupFn = async (id) => {
      try {
        const { affectedIds } = await deleteParkingGroup(id);
        if (affectedIds && affectedIds.length) {
          {
            getGroups();
          }
          enqueueSnackbar(i18next.t('notification.success.deleteGroup'), {
            variant: 'success',
          });
        }
      } catch (e) {
        console.error(e);
      }
    };
    deleteGroupFn(id);
  };

  useEffect(() => {
    {
      getGroups();
    }
  }, []);

  return (
    <div>
      <Topbar title={title} />
      <Grid item paddingTop={7}>
        <ListHead title={listHead} onCreate={onCreate}></ListHead>
        <List>
          {groups &&
            groups.map((e, i) => (
              <ParkingGroupItem
                Item
                key={i}
                group={e}
                deleteGroup={deleteGroup}
                history={props.history}
              />
            ))}
        </List>
      </Grid>
    </div>
  );
};
export default ParkingGroups;
