import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import icon_car from '../../../assets/icons/publicApp/icon_car.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/publicApp/icon_trash.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/publicApp/icon_cross.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/publicApp/icon_check.svg';
import LicensePlate from './LicensePlate';
import './VehiclesCard.scss';
import ModernLoader from 'ui-component/ModernLoader';

const VehiclesCard = ({ t, vehicles, selectVehicle, addVehicle, deleteVehicle, vehiclesLimit = 3, userId }) => {
  const replaceSpecialCharacters = require('replace-special-characters');

  const [vehicleSelectMode, setVehicleSelectMode] = useState(false);
  const [newVehicle, setNewVehicle] = useState({
    makeId: '',
    modelId: '',
    plateNum: '',
    ownerId: '',
  });
  const [openInput, setOpenInput] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState();

  useEffect(() => {
    setNewVehicle({
      ...newVehicle,
      ownerId: userId,
    });
  }, [userId]);

  useEffect(() => {
    if (vehicles && vehicles.length === 0) {
      setVehicleSelectMode(false);
    } else if (vehicles && vehicles.length > 0) {
      setSelectedVehicle(vehicles.find((e) => e.plateNum === localStorage.getItem('selectedVehicle')));
    }
  }, [vehicles]);

  const handleSelection = (plateNum) => {
    if (vehicleSelectMode) {
      selectVehicle(plateNum);
      setSelectedVehicle(vehicles.find((e) => e.plateNum === localStorage.getItem('selectedVehicle')));
    }
    setVehicleSelectMode(!vehicleSelectMode);
    setOpenInput(false);
  };

  const handleInput = (event) => {
    const inputValue = event.target.value;
    const replacedValue = replaceSpecialCharacters(inputValue);
    const sanitizedValue = replacedValue.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters
    setNewVehicle({
      ...newVehicle,
      plateNum: sanitizedValue.toUpperCase(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newVehicle.plateNum !== '') {
      await addVehicle(newVehicle);
      setVehicleSelectMode(false);
    }
    handleEraseInputData();
  };

  const handleEraseInputData = () => {
    setOpenInput(false);
    setNewVehicle({
      ...newVehicle,
      plateNum: '',
    });
  };

  const handleCloseLicensePlateSelection = () => {
    handleEraseInputData();
    setVehicleSelectMode(false);
  };

  const handleDelete = async (plateNum) => {
    await deleteVehicle(plateNum);
    handleEraseInputData();
  };

  return (
    <Card className="card-shadow" sx={{ p: 1, mx: 1, boxShadow: 0 }}>
      <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1.25 }}>
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={icon_car} style={{ width: 42 }} />
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" className="h4-text">
            {t('publicApp.YourVehicle')}
          </Typography>
        </Grid>
        {vehicleSelectMode && vehicles && vehicles.length > 0 && (
          <Grid item xs={2} sx={{ textAlign: 'right', height: 40 }}>
            <IconButton onClick={handleCloseLicensePlateSelection}>
              <CrossIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box sx={{ pl: 1, pr: 0.25 }} style={{ textAlign: 'center' }}>
        <Grid pr={0.75}>
          <Divider />
        </Grid>
        <Grid sx={{ pl: vehicleSelectMode ? 1 : 0 }}>
          {vehicles && vehicles.length > 0 && (
            <Box sx={{ mt: 1 }}>
              {vehicleSelectMode ? (
                vehicles.map((item) => (
                  <Grid key={item.plateNum} container justifyContent={vehicleSelectMode ? 'space-between' : 'center'}>
                    <LicensePlate licenseNumber={item.plateNum} selected={selectedVehicle && item.id === selectedVehicle.id} onClick={() => handleSelection(item.plateNum)} />
                    <IconButton onClick={() => handleDelete(item.plateNum)}>
                      <TrashIcon />
                    </IconButton>
                  </Grid>
                ))
              ) : (
                <Grid container justifyContent="center">
                  <LicensePlate licenseNumber={selectedVehicle && selectedVehicle.plateNum ? selectedVehicle.plateNum : vehicles[0].plateNum} onClick={() => handleSelection(vehicles[0].id)} />
                </Grid>
              )}
            </Box>
          )}

          {vehicles ? (
            (vehicles.length === 0 || (vehicles.length > 0 && vehicles.length < vehiclesLimit && vehicleSelectMode)) && (
              <Grid container justifyContent={vehicleSelectMode ? 'left' : 'center'}>
                {!openInput ? (
                  <LicensePlate licenseNumber="+" selected={false} simplified={true} onClick={() => setOpenInput(true)} />
                ) : (
                  <Grid sx={{ my: 1 }}>
                    <input id="input-new-license-plate" type="text" autoFocus className="input-number-plate" value={newVehicle.plateNum} onChange={handleInput} />
                  </Grid>
                )}
              </Grid>
            )
          ) : (
            <Grid sx={{ pt: 1.5, pb: 0.6 }}>
              <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                <ModernLoader />
              </Grid>
            </Grid>
          )}
        </Grid>
        {openInput && (
          <Grid container alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <IconButton onClick={handleEraseInputData}>
                <CrossIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton onClick={handleSubmit}>
                <CheckIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default VehiclesCard;
