import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import PublicTopbar from '../../PublicTopbar';
import { deleteCard, getUserCards, setActive } from 'utils/payments/eService/EServiceService';
import {
    Box,
    Card,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/publicApp/icon_trash.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddBoxIcon from '@mui/icons-material/AddBox';

class CardList extends Component {
    constructor() {
        super();
        this.state = {
            user: null,
            cards: []
        };
    }
    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, false);
        if (this.props.currentUser) {
            const user = this.props.currentUser;
            this.setState({ user: user });

            getUserCards().then((response) => {
                if (response.data) {
                    this.setState({ cards: response.data })
                }
            })
        }
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleDelete = async (id) => {
        deleteCard(id).then((response) => {
            if (response.data) {
                this.setState({ cards: response.data })
            }
        })
    }

    handleActive = async (id) => {
        setActive(id).then((response) => {
            if (response.data) {
                this.setState({ cards: response.data })
            }
        })
    }

    handleAdd = async () => {
        this.props.history.push({
            pathname: 'card',
            state: { action: 'new' }
        });
    }

    render() {
        const { user, cards } = this.state;
        const { t } = this.props;
        return (
            <div className="public-form">
                <PublicTopbar title={t('publicApp.Cards')} enableShadow />
                <Box sx={{ mt: 1.75 }}>
                    {
                        cards.map((item) => (
                            <Grid
                                key={item.id}
                                container
                                justifyContent={'end'}
                                paddingRight={3}
                                paddingLeft={3}
                                paddingTop={1}
                            >
                                <IconButton onClick={() => this.handleActive(item.id)} >
                                    {item.lastCard ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                </IconButton>
                                <Typography variant="h5" className='h4-text-bold' sx={{ padding: 2 }}>
                                    {'**** ' + item.name + ' ' + item.expiryMonth + '/' + item.expiryYear}
                                </Typography>
                                <IconButton onClick={() => this.handleDelete(item.id)}>
                                    <TrashIcon />
                                </IconButton>
                            </Grid>
                        ))
                    }
                    <Grid
                        container
                        justifyContent={'end'}
                        padding={3}
                    >
                        <IconButton onClick={() => this.handleAdd()} >
                            <AddBoxIcon />
                        </IconButton>
                    </Grid>
                </Box>
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(CardList));