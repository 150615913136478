import React, { useState } from "react";
import "./SwitchSmall.scss";


const SwitchSmall = ({ checked, onChange} ) => {



    return(

                <label className="switchSm">
                    <input type ="checkbox" checked={checked} onChange={onChange}/>
                    <span className="sliderSm" />
                </label>


    );
};

export default SwitchSmall;