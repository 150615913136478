import React, { useState } from 'react';
import {TextField, Input, Grid, Button} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export default function SettingForm({
    setting, type, onUpdateSettingItem , onSaveDefault
}) {

    const [value, setValue] = useState(setting.value);
    const [ischange, setIsChange] = useState(false);

    const onChange = (event) => {
        let newValue = event.target.value.replace(/[^0-9]/g, '');
        while (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1);
        }
        if (newValue === '') {
            newValue = '0';
        }
        setIsChange(true);

        setValue(newValue);
        setting.value = newValue;

    }
      const onSave = () => {
          onUpdateSettingItem(setting);
          onSaveDefault(setting);
          setIsChange(false);
}

    const SearchButton = () => (
        ( ischange &&
        <Button className="actionBtn" onClick={onSave} >
            <FontAwesomeIcon icon={faCheck} size="xs" />
        </Button>)
    )

    return (
        <Grid container  xs={12}>
            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
        <TextField
            variant="standard"
            id="outlined-number"
            InputLabelProps={{
                            shrink: true,
                        }}
                        value={value}
            onChange={onChange}
            InputProps={{endAdornment: <SearchButton />}}
        />
            </Grid>
        </Grid>
        //     <input
        //         id="outlined-number"
        //         // type={type}
        //         InputLabelProps={{
        //             shrink: true,
        //         }}
        //         value={value}
        //         onChange={onChange}
        //         InputProps={{endAdornment: <SearchButton />}}
        //     />

    )
}