import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './PublicBookingNotification.scss';

const PublicBookingNotification = ({ open = true, variant = 'success', text }) => {
  const { t } = useTranslation();
  const defaultText = t(variant === 'success' ? 'notification.success.BookedSpace' : 'notification.error.BookedSpace');

  // TODO: Change all public card variants with this animation
  const cardVariants = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        opacity: { duration: 0.2 },
        height: { duration: 0.3, delay: 0.2 }
      },
    },
    visible: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.2, delay: 0.2 }
      },
    },
  };
  return (
    <AnimatePresence>
      {open && (
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={cardVariants} className="public-booking-notification-container">
          <Grid container justifyContent="center" pt={1}>
            <Grid item xs={12}>
              <Card className={variant === 'success' ? 'public-booking-success-notification' : 'public-booking-error-notification'} sx={{ boxShadow: 0 }}>
                <Grid className="public-booking-notification-text-container">
                  <Typography variant="custom" className="public-booking-notification-text">
                    {text ?? defaultText}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PublicBookingNotification;
