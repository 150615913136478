import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import './SilencePeriodForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import BottomBar from '../../ui/bottom-bar/BottomBar';
import InputDate from '../../ui/inputs/date/InputDate';
import { withTranslation } from 'react-i18next';
import { Grid, Checkbox, Typography } from '@mui/material';
import { withSnackbar } from 'notistack';
import {
  checkForBookedSpaces,
  checkForSilencePeriods,
  postSilencePeriod,
} from '../../../utils/SilencePeriodsService';
import SilencePeriodFormHead from '../head/SilencePeriodFormHead';

import MomentUtils from '@date-io/moment';
import {
  putSharedSpaceRequest,
  deleteSharedSpaceRequest,
  requestSharedSpace,
  checkForSharedSpaceRequests,
} from '../../../utils/SharedSpaceRequestService';
import { resolveEndOfDay, resolveStartOfDay } from 'utils/DateTimeResolver';
import DeleteDialog from 'components/ui/dialogs/DeleteDialog';
import { featureEnabled } from 'utils/FeatureTogglerService';
import { AUTO_MATCH } from 'constants/Features';

function SilencePeriodForm({ t, enqueueSnackbar }) {
  const [currentLng, setCurrentLng] = useState(
    localStorage.getItem('currentLng')
  );

  const moment = new MomentUtils({ locale: currentLng });

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const setStartOfDay = (date) => {
    if (date) {
      return moment.date(resolveStartOfDay(date));
    }
    return moment.date(new Date()).utc(0).startOf('day');
  };

  const setEndOfDay = (date) => {
    if (date) {
      const date_ = moment.date(resolveEndOfDay(date));
      return date_;
    }
    return moment.date(new Date()).utc(0).endOf('day');
  };

  const setFrom = (state) => {
    const from_ = state && state.item ? state.item.from : null;
    return setStartOfDay(from_);
  };

  const setTo = (state) => {
    const to_ = state && state.item != null ? state.item.to : null;
    return setEndOfDay(to_);
  };

  const setMin = (state) => {
    const min_ = state ? state.minDate : null;
    return setStartOfDay(min_);
  };

  const setMax = (state) => {
    let max_ = state ? state.maxDate : null;
    if (state.subscribeLimit && state.subscribeLimit > 0) {
      const maxSubscribeTo = moment.date().add(state.subscribeLimit - 1, 'd').format('YYYY-MM-DDT23:59:59.999').toString();
      max_ = !max_ || max_ > maxSubscribeTo ? maxSubscribeTo : max_
    }
    return max_ ? setEndOfDay(max_) : moment.date().add(1, 'y');
  };

  const [from, setFromDate] = useState(setFrom(location.state));
  const [to, setToDate] = useState(setTo(location.state));
  const [formTitle, setFormTitle] = useState('subscriptions.SilencePeriod');
  const [dialogType, setDialogType] = useState({
    type: null,
    modificationMethod: '',
    text: '',
  });

  useEffect(() => {
    if (isNew) {
      if (location.state && location.state.subscribe) {
        setFormTitle('subscriptions.Request');
        setDialogType({
          type: 'request',
          modificationMethod: 'create',
          text: 'confirm.deleteSilencePeriodsDuringSubscriptionCreation',
        });
      } else {
        setFormTitle('subscriptions.SilencePeriod');
        setDialogType({
          type: 'silence',
          modificationMethod: 'create',
          text: 'confirm.deleteBookedSpaces',
        });
      }
    } else {
      if (location.state && location.state.subscribe) {
        setFormTitle('subscriptions.EditRequest');
        setDialogType({
          type: 'request',
          modificationMethod: 'update',
          text: 'confirm.deleteSilencePeriodsDuringSubscriptionUpdate',
        });
      }
    }

    setCurrentLng(localStorage.getItem('currentLng'));
  }, [location.state]);

  const createSilencePeriod = async (body) => {
    await postSilencePeriod(body);
    enqueueSnackbar(t('notification.success.SilencePeriodCreated'), {
      variant: 'success',
    });
  };

  const createSharedSpaceRequest = async (body) => {
    await requestSharedSpace(from, to, body.userId);
    enqueueSnackbar(t('notification.success.SubscriptionCreated'), {
      variant: 'success',
    });
  };

  const updateSharedSpaceRequest = async (body) => {
    await putSharedSpaceRequest(body, location.state.item.id);
    enqueueSnackbar(t('notification.success.SharedSpaceRequestUpdated'), {
      variant: 'success',
    });
  };

  const minDate = setMin(location.state);
  const maxDate = setMax(location.state);
  const isNew = params.action === 'new';
  const [submitTitle, setSubmitTitle] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [body, setBody] = useState();

  const handleAfterConfirmDialog = () => {
    if (dialogType && dialogType.type === 'request') {
      if (dialogType.modificationMethod === 'create') {
        createSharedSpaceRequest(body);
      } else if (dialogType.modificationMethod === 'update') {
        updateSharedSpaceRequest(body);
      }
    } else if (
      dialogType &&
      dialogType.type === 'silence' &&
      dialogType.modificationMethod === 'create'
    ) {
      createSilencePeriod(body);
    }
    setOpenConfirmationDialog(false);
    goBack();
  };

  useEffect(() => {
    setBody({
      // id: location.state && location.state.item ? location.state.item.id : null,
      from: from.format('YYYY-MM-DDT00:00:00.000').toString(),
      to: to.format('YYYY-MM-DDT23:59:59.999').toString(),
      userId:
        location.state && location.state.user ? location.state.user.id : '',
    });
  }, [from, to]);

  const excludeDates =
    location.state && location.state.sharedDates && location.state.bookedSpaces
      ? location.state.sharedDates.concat(
          location.state.bookedSpaces.map((space) => ({
            from: moment
              .date(space.bookedFrom)
              .format('YYYY-MM-DDT00:00:00.000')
              .toString(),
            to: space.bookedTo.endsWith('Z')
              ? space.bookedTo.slice(0, -1)
              : space.bookedTo,
          }))
        )
      : null;

  const onClose = () => {
    setOpenConfirmationDialog(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (isNew) {
        if (location.state.subscribe) {
          //create subscription request
          // await checkForSilencePeriods(body.userId, body.from, body.to)
          //   .then((response) => {
          //     if (response) {
          //       setDialogType({
          //         type: 'request',
          //         modificationMethod: 'create',
          //         text: 'confirm.deleteSilencePeriodsDuringSubscriptionCreation',
          //       });
          //       setOpenConfirmationDialog(true);
          //     } else {
          //       //create without dialog
          //       createSharedSpaceRequest(body);
          //       goBack();
          //     }
          //   })
          //   .catch((error) => console.error(error));
          createSharedSpaceRequest(body);
          goBack();
        } else {
          //create silence period
          let willDeletePeriods = false;
          // await checkForBookedSpaces(body.userId, body.from, body.to)
          //   .then((response) => {
          //     if (response) {
          //       setDialogType({
          //         type: 'silence',
          //         modificationMethod: 'create',
          //         text: 'confirm.deleteBookedSpaces',
          //       });
          //       setOpenConfirmationDialog(true);
          //       willDeletePeriods = true;
          //     }
          //   })
          //   .catch((error) => console.error(error));

          // if (!willDeletePeriods) {
          //   const autoMatch = await featureEnabled(AUTO_MATCH);

          //   if (autoMatch) {
          //     await checkForSharedSpaceRequests(body.userId, body.from, body.to)
          //       .then((response) => {
          //         if (response) {
          //           setDialogType({
          //             type: 'silence',
          //             modificationMethod: 'create',
          //             text: 'confirm.deleteBookedSpacesAndSubscriptions',
          //           });
          //           setOpenConfirmationDialog(true);
          //           willDeletePeriods = true;
          //         }
          //       })
          //       .catch((error) => console.error(error));
          //   }
          // }
          if (!willDeletePeriods) {
            //create without dialog
            createSilencePeriod(body);
            goBack();
          }
        }
      } else {
        if (submitTitle) {
          const { affectedIds } = await deleteSharedSpaceRequest(
            location.state.item.id
          );

          if (affectedIds && affectedIds.length) {
            goBack();
            enqueueSnackbar(
              t('notification.success.DeleteSharedSpaceRequest'),
              {
                variant: 'success',
              }
            );
          }
        } else {
          // update subscription request
          // await checkForSilencePeriods(body.userId, body.from, body.to)
          //   .then((response) => {
          //     if (response) {
          //       setDialogType({
          //         type: 'request',
          //         modificationMethod: 'update',
          //         text: 'confirm.deleteSilencePeriodsDuringSubscriptionUpdate',
          //       });
          //       setOpenConfirmationDialog(true);
          //     } else {
          //       //update without dialog
          //       updateSharedSpaceRequest(body);
          //       goBack();
          //     }
          //   })
          //   .catch((error) => console.error(error));
          updateSharedSpaceRequest(body);
          goBack();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSetFromDate = (date) => {
    setFromDate(date);
    if (moment.isAfter(date, to)) {
      setToDate(date);
    }
  };

  const onChangeSetToDate = (date) => {
    setToDate(date);
    if (moment.isBefore(date, from)) {
      setFromDate(date);
    }
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setSubmitTitle(t('general.Delete'));
    } else {
      setSubmitTitle(null);
    }
  };

  return (
    <React.Fragment>
      <div className="ExchageContainer">
        <Topbar
          title={t(formTitle)}
          location={location}
          cbBack={goBack}
        ></Topbar>
        <SilencePeriodFormHead
          isSilencePeriod={location.state?.subscribe ?? true}
        />
        <form
          className="SilencePeriodFormFormContainer"
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={1}>
            {isNew && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  excludesList={excludeDates}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                  maxDate={maxDate}
                />
              </Grid>
            )}
            {!isNew && maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {!isNew && !maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  minDate={minDate}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {isNew && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={from}
                  id="to"
                  excludesList={excludeDates}
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                  maxDate={maxDate}
                />
              </Grid>
            )}
            {!isNew && maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={minDate}
                  maxDate={maxDate}
                  id="to"
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {!isNew && !maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={minDate}
                  id="to"
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}

            {!isNew && location.state?.subscribe && (
              <Grid item xs={12} className="CheckBoxContainer">
                <Checkbox
                  className="CheckBoxComponent"
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography>
                  {t('subscriptions.DeleteSharedSpaceRequest')}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs={12} className="SharedWithContainer">
              {isAdmin && user && (
                <UserListItem user={user} className="UserCard" />
              )}
            </Grid> */}
          </Grid>
          {openConfirmationDialog && (
            <DeleteDialog
              openIt={openConfirmationDialog}
              onConfirm={handleAfterConfirmDialog}
              onClose={onClose}
              text={dialogType.text}
              isDefaultTextNeeded={false}
              isConfirmationDialog={true}
            />
          )}
          <BottomBar onGoBackFn={goBack} submitTitle={submitTitle}></BottomBar>
        </form>
      </div>
    </React.Fragment>
  );
}

export default withSnackbar(withTranslation()(SilencePeriodForm));
