import React, { Component } from 'react';
import './VehiclesOwnerList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import VehiclesOwnerListItem from './item/VehiclesOwnerListItem';
import { List } from '@mui/material';

class VehiclesOwnerList extends Component {
  render() {
    const { t, onCreate, vehicles, onDelete, activeBookingsAmount, activeSubscriptionsAmount} = this.props;
    return (
      <React.Fragment>
        <ListHead title={t('vehicles.Title')} onCreate={onCreate} />
        <List className="vehicles-list">
          {vehicles &&
            vehicles.map((e, i) => (
              <VehiclesOwnerListItem
                key={e.id + i}
                vehicle={e}
                onDelete={onDelete}
                vehiclesAmount={vehicles.length}
                activeBookingsAmount={activeBookingsAmount}
                activeSubscriptionsAmount={activeSubscriptionsAmount}
              />
            ))}
        </List>
      </React.Fragment>
    );
  }
}
export default withTranslation()(VehiclesOwnerList);
