import { axiosA } from './AuthService';

const postPayment = async (paymentData, paymentId, response) => {
	try {
		const body = {
			territoryId: paymentData.lot.id,
			userId: paymentData.userId,
			amount: paymentData.payment.amount,
			parkingLogId: paymentData.payment.parkingLogId,
			bookedSpaceId: paymentData.payment.bookedSpaceId,
			currencyCode: paymentData.lot.currencyCode,
			response: response,
			plateNum: paymentData.plateNum,
			bankId: paymentData.bankId,
			paymentId: paymentId
		}
		const { data } = await axiosA.post('/Payments', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const checkPayment = async (plateNum, paymentId) => {
	try {
		const body = {
			plateNum: plateNum,
			paymentId: paymentId
		}
		const { data } = await axiosA.post('/Payments/checkPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const confirmPayment = async (payment) => {
	try {
		const body = {
			id: payment.id,
			bankStatus: payment.bankStatus,
			cardStatus: payment.cardStatus,
			statusGroup: payment.statusGroup
		}
		const { data } = await axiosA.post('/Payments/confirmPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const postError = async (id, error) => {
	try {
		const body = {
			id: id,
			error: error
		}
		const { data } = await axiosA.post('/Payments/postError', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const lastPayment = async (userId, plateNum) => {
	try {
		const body = {
			userId: userId,
			plateNum: plateNum
		}
		const { data } = await axiosA.post('/Payments/lastPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

export {
	postPayment,
	checkPayment,
	confirmPayment,
	postError,
	lastPayment
}