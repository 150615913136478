import './AvailableSites.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { Topbar } from '../ui/topbar/Topbar';
import { InputSelect } from 'components/ui/inputs/select/Select';
import {
  getLocales,
  getTranslationsForEditing,
  putTranslations,
  getAvailableSites,
} from 'utils/AvailableSitesService';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../constants/Colors';

export default function AvailableSites() {
  const { t } = useTranslation();
  const currentLng = localStorage.getItem('currentLng');
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const formatOption = useCallback(
    (langKey) => {
      return {
        value: langKey,
        label: t(`locales.${langKey}`),
      };
    },
    [t]
  );

  const [selectedLocale, setSelectedLocale] = useState('');
  const [locales, setLocales] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [availableSites, setAvailableSites] = useState([]);

  const fetchAvailableSites = useCallback(async () => {
    try {
      const { data } = await getAvailableSites();
      setAvailableSites(data.items);
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const fetchTranslations = useCallback(async () => {
    let translations = [];
    try {
      let lang = selectedLocale ? selectedLocale.value : currentLng;
      const { data } = await getTranslationsForEditing(lang);
      translations = data.items;
    } catch (e) {
      throw new Error(e);
    }
    setTranslations(translations);
  }, [selectedLocale, currentLng]);

  const fetchLocales = useCallback(async () => {
    let preparedLocales = [];
    try {
      const { data } = await getLocales();
      preparedLocales = data.items.map((e) => {
        return formatOption(e.key);
      });
    } catch (e) {
      throw new Error(e);
    }
    setLocales(preparedLocales);
  }, []);

  const onChangeLocale = async (e) => {
    setSelectedLocale(e);
  };

  useEffect(() => {
    async function loadData() {
      await fetchLocales();
      await fetchTranslations();
      await	 fetchAvailableSites()
    }
    loadData();
  }, [fetchLocales, fetchTranslations, fetchAvailableSites]);

  const onClickEdit = (item) => {
    setEditItem(Object.assign({}, { ...item }));
    setOpenDialog(true);
  };

  const onValueChange = (e) => {
    setEditItem({ ...editItem, value: e.target.value });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleYes = async () => {
    try {
      await putTranslations(editItem);
      enqueueSnackbar(t('notification.success.updateTranslation'), {
        variant: 'success',
      });

      fetchTranslations();

      setOpenDialog(false);
    } catch (error) {
      // leave open
    }
  };

  const title = `administration.Translations`;

  return (
    <React.Fragment>
      <Topbar title={t(title)} location={location} history={history}>
        <InputSelect
          options={locales}
          onChange={onChangeLocale}
          value={selectedLocale}
          errorStyles="availableSitesSelectInputError"
          placeholder={t('locales.Languages')}
        />
      </Topbar>
      <List className="availableSitesContainer">
        {translations &&
          translations.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem className="availableSitesContainerListItem">
                <ListItemText
                  primary={
                    <Typography className="availableSitesListItemTitle">
                      {t(`messages.${item.property}`)}
                    </Typography>
                  }
                  secondary={item.value}
                ></ListItemText>
                <ListItemSecondaryAction>
                  {
                    <IconButton
                      onClick={() => onClickEdit(item)}
                      edge="end"
                      aria-label="Delete"
                      className="delete-btn button-edit-margin"
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={Colors.icon}
                        size="xs"
                      />
                    </IconButton>
                  }
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t(`messages.${editItem.property}`)}
        </DialogTitle>
        <DialogContent>
          <div className="availableSitesFormContainer">
            <TextField
              id="standard-full-width"
              multiline
              fullWidth
              value={editItem.value}
              onChange={(e) => onValueChange(e)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('general.Cancel')}
          </Button>
          <Button onClick={handleYes} color="primary">
            {t('general.Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
