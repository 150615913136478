import { axiosA } from './AuthService';
import MomentUtils from '@date-io/moment'
import { NotificationManager } from 'react-notifications';
import i18next from 'i18next';

const moment = new MomentUtils({ locale: 'lt' })

const getParkingLot = async (parkingLotId) => {
	try {
		const { data } = await axiosA.get(`/ParkingLots/${parkingLotId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getLastTerritory = async () => {
	const { data } = await axiosA.get('/ParkingLots/last')
	return data
}

const getParkingLots = async () => {
	const { data } = await axiosA.get('/ParkingLots')
	return data
}

const getPrimaryParkingLots = async () => {
	const { data } = await axiosA.get('/ParkingLots/primary')
	return data
}

const getParkingTerritories = async () => {
	const { data } = await axiosA.get('/ParkingLots?Territory=true')
	return data
}

const getParkingSpace = async (parkingSpaceId) => {
	try {
		const { data } = await axiosA.get(`/ParkingSpaces/${parkingSpaceId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getParkingSpaces = async (ParkingLotId, ParkingSpaceName = '', offset) => {
	let link = `/ParkingSpaces?Limit=20`;

	if (ParkingSpaceName.length) {
		link += '&ParkingSpaceName=' + ParkingSpaceName;
	}

	if (ParkingLotId) link += '&ParkingLotId=' + ParkingLotId
	if (offset) link += '&Offset=' + offset
	const { data } = await axiosA.get(link)
	return data
}

const getSharedSpaces = async (ParkingSpaceId = '', Booking = false) => {
	const link = `/SharedSpaces/?ParkingSpaceId=${ParkingSpaceId}&IncludeBookings=${Booking}&Limit=100`;
	try {
		let { data } = await axiosA.get(link)
		return data

	} catch (e) {
		throw new Error(e)
	}
}

const getSharedSpace = async (id) => {
	const link = `/SharedSpaces/${id}`;
	try {
		let { data } = await axiosA.get(link)

		return data

	} catch (e) {
		throw new Error(e)
	}
}

const historySharedSpacesList = async (userId, offset) => {
	return getSharedSpacesList(userId, 'history', offset);
}

const getUserBookingSpacesListHistory = async (userId) =>
{
	let url = '?1=1'
	let today = moment.date().utc(0)
	let to = today.format('YYYY-MM-DDT00:00:00').toString();
	let from = today.startOf('month').format('YYYY-MM-DDT00:00:00').toString();
	//let status === 'user-history';


	let	link = `/bookedspaces/actions?1=1&UserId=${userId}&FullList=null&From=${from}&To=${to}&Limit=-1`
	try {
		let { data } = await axiosA.get(link)
		return data

	} catch (e) {
		throw new Error(e)
	}


}
const getUserSharingSpacesList = async (userId, from, to, desc, limit) =>
{
	// let url = '?1=1'
	// let today = moment.date().utc(0)
	// let to = today.format('YYYY-MM-DDT00:00:00').toString();
	// let from = today.startOf('month').format('YYYY-MM-DDT00:00:00').toString();
	//let status === 'user-history';


	let	link = `/SharedSpaces/actions?UserId=${userId}`

	if(from) link += `&From=${from}`
	if(to) link += `&To=${to}`
	if(desc) link += `&Desc=${desc}`
	if(limit) link += `&Limit=${limit}`
	
	try {
		let { data } = await axiosA.get(link)
		return data

	} catch (e) {
		throw new Error(e)
	}


}


const getSharedSpacesList = async (userId, status, offset, limit) => {
	let url = '?1=1'
	let today = moment.date().utc(0)

	let from = today.format('YYYY-MM-DDT00:00:00.000').toString();
	let to = today.subtract(1, 'day').format('YYYY-MM-DDT23:59:59.999').toString();

	if (status === 'history') {
		// let historyTo = today.format('YYYY-MM-DDT00:00:00.000').toString();
		url += `&To=${to}`;
	}
	if (status === 'active' || status === 'pending') url += `&From=${from}`

	if (status === 'active' || status === 'history') {
		if (limit) {
			url += `&Limit=${limit}`;
		} else {
			url += `&Limit=10`;
		}
	} else if (status === 'user-history') {
		if (limit) {
			url += `&Limit=${limit}`;
		} else {
			url += `&Limit=5`;
		}
	} else if (status === 'pending') {
		if (limit) {
			url += `&Limit=${limit}`;
		}
		url += `&IncludeBookings=true`;
	} else {
		url += `&Limit=-1`;
	}

	if (offset) url += `&Offset=${offset}`

	if (status === 'active' || status === 'history') {
		if (userId) url += `&OwnerId=${userId}`;
	} else {
		if (userId) url += `&OwnerId=${userId}`;
	}


	let link = `/SharedSpaces${url}`;

	if (status === 'active') {
		link = `/bookedspaces/active${url}`;
	} else if (status === 'user-history') {
		link = `/bookedspaces/history${url}`;
	}

	try {
		let { data } = await axiosA.get(link)
		return data

	} catch (e) {
		throw new Error(e)
	}
}

const findSharedSpaces = async (dates, offset) => {

	let fromDate = dates["VacantFrom"];
	if (fromDate) {
		fromDate = fromDate.format('YYYY-MM-DDT00:00:00.000').toString();
	}

	let toDate = dates["VacantTo"];
	if (toDate) {
		toDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
	}

	let TerritoryId = dates['TerritoryId'];

	// let link = `/SharedSpaces/Find?Limit=10`;
	let link = `/AssignSharedSpace/Sharings?Limit=10`;
	if (TerritoryId) link += `&TerritoryId=${TerritoryId}`;
	if (fromDate) link += `&VacantFrom=${fromDate}`;
	if (toDate) link += `&VacantTo=${toDate}`;
	if (offset) link += `&Offset=${offset}`;

	try {
		let { data } = await axiosA.get(link)
		return data

	} catch (e) {
		throw new Error(e)
	}
}

const setParkingOwner = async (space, ownerId) => {
	const operation = space.isOwned ? 'replace' : 'add';
	const body = [
		{
			op: operation,
			path: '/OwnerId',
			value: ownerId
		}
	]

	try {
		const { data } = await axiosA.patch(`/ParkingSpaces/${space.id}`, body);
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const resolveSpaceLot = async (spaces, parkingLots) => {
	  
	//Promise.all(
	return	spaces.map(el => {
		if (el.bookingLevel > 0) {
			const parkingLotNames = parkingLots.filter(e => e.territoryId === el.parkingLotId).map(x => x.name);
			// console.log(parkingLotNames);
			// const { data } = await axiosA.get(`/ParkingSpaces/territory/${el.parkingLotId}`);
			return {
				...el,
				parkingLotNames: parkingLotNames
			}
		}
		else {
			const lot = parkingLots.find(e => e.id === el.parkingLotId);
			return {
				...el,
				parkingLotName: lot.name,
			}
		}
	});
	// );
}

const getParkingLotNames = async (parkingLotId) => {
	const {data} = await axiosA.get(`/ParkingSpaces/territory/${parkingLotId}`);
	return data;
}

const shareSpace = async (params, id) => {
	if (id == '') {
		try {
			const { data } = await axiosA.post('/SharedSpaces', params)
			return data
		} catch (e) {
			throw new Error(e)
		}
	}
	else {
		try {
			const { data } = await axiosA.put(`/SharedSpaces/${id}`, params)
			return data
		} catch (e) {
			throw new Error(e)
		}
	}
}

const shareSpaceList = async (params) => {
	try {
		const data = await axiosA.post('/AssignSharedSpace/List', params);
		return data;
	} catch (e) {
		throw new Error(e);
	}
}

const bookSpace = async (params, id) => {
	if (id) {
		try {
			const { data } = await axiosA.put(`/BookedSpaces/${id}`, params)
			return data
		} catch (e) {
			throw new Error(e)
		}
	} else {
		try {
			const { data } = await axiosA.post('/BookedSpaces', params)
			return data
		} catch (e) {
			throw new Error(e)
		}
	}
}

const deleteShareSpace = async (id) => {
	try {

		const { data } = await axiosA.get(`/SharedSpaces/${id}/occupancy`);

		if (['None', 'Partial'].includes(data.key)) {

			// const confirmMessage = i18next.t(((data.key == 'None') ? 'confirm.DeleteUnoccupiedShare' : 'confirm.DeletePartiallyOccupiedShare'));
			//
			// if (window.confirm(confirmMessage)) {
				const { data } = await axiosA.delete(`/SharedSpaces/${id}`)
				return data;
			//}

		} else {
			NotificationManager.error(i18next.t('error.DeleteShareFullyOccupied'));
			return false;
		}

	} catch (e) {
		return new Error(e);
	}
	return false;
}

const deleteBookedSpace = async (id) => {
	try {
		const { data } = await axiosA.delete(`/BookedSpaces/${id}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getUserBookedSpaces = async (userId, status, offset, sharedSpaceId, fullList, limit) => {
	let url = '?1=1';
	let today = moment.date().utc(0)
	let from = moment.date().utc(0).startOf('day').format('YYYY-MM-DDT00:00:00.000').toString();
	let to = today.subtract(1, "days").utc(0).endOf('day').format('YYYY-MM-DDT23:59:59.999').toString();

	if (status === 'history') url += `&To=${to}`
	if (status === 'active') url += `&From=${from}`

	if (status === 'history') {
		if (limit) {
			url += `&Limit=${limit}`;
		} else {
			url += `&Limit=5`;
		}
	} else {
		url += `&Limit=-1`;
	}

	if (offset) url += `&Offset=${offset}`
	if (userId) url += `&UserId=${userId}`
	if (fullList) url += `&FullList=${fullList}`
	if (sharedSpaceId) url += `&ParkingSpaceId=${sharedSpaceId}`

	try {
		const { data } = await axiosA.get(`/BookedSpaces${url}`)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const getOwnerSpaces = async (OwnerId, parkingLots) => {
	// const { data } = await axiosA.get(`/ParkingSpaces?OwnerId=${OwnerId}`)
	const { data } = await axiosA.get(`/ParkingSpaces/Owner/${OwnerId}`)

	if (!parkingLots) {
		const lots = await getParkingLots()
		parkingLots = lots.items
	}
	return resolveSpaceLot(data, parkingLots)
}

const getOwnerParkingSpaces = async (OwnerId, parkingLots) => {
	 const { data } = await axiosA.get(`/ParkingSpaces?OwnerId=${OwnerId}`)
	//const { data } = await axiosA.get(`/ParkingSpaces/Owner/${OwnerId}`)

	if (!parkingLots) {
		const lots = await getParkingLots()
		parkingLots = lots.items
	}
	return resolveSpaceLot(data.items, parkingLots)
}

const postParkingLot = async (lot, territory) => {
	try {
		const body = {
			name: lot.name,
			parkingLotId: lot.parkingLotId,
			aggregateLotId: territory.id,
			territoryName: territory.name
		}
		const { data } = await axiosA.post('/ParkingLots', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const putParkingLot = async (lot, territory) => {
	const body = {
		name: lot.name,
		parkingLotId: lot.parkingLotId,
		aggregateLotId: territory.id,
		territoryName: territory.name
	}
	const { data } = await axiosA.put(`/ParkingLots/${lot.id}`, body)
	return data
}

const getBookedSpace = async (bookedSpaceId) => {
	try {
		const { data } = await axiosA.get(`/BookedSpaces/${bookedSpaceId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const spacesData = async (ids) => {
	try {
		const { data } = await axiosA.post('/ParkingSpaces/spaces', ids);
		return data;
	} catch (e) {
		throw new Error(e);
	}
}
const getFreeEntrance = async () => {
	const { data } = await axiosA.get('/ParkingLots/freeEntrance')
	return data
}

export {
	getParkingLots,
	getParkingLot,
	getParkingTerritories,
	getParkingSpaces,
	getParkingSpace,
	getUserBookedSpaces,
	shareSpace,
	bookSpace,
	getSharedSpaces,
	findSharedSpaces,
	deleteShareSpace,
	deleteBookedSpace,
	getOwnerParkingSpaces,
	resolveSpaceLot,
	setParkingOwner,
	getSharedSpacesList,
	getSharedSpace,
	historySharedSpacesList,
	getLastTerritory,
	postParkingLot,
	putParkingLot,
	getBookedSpace,
	getPrimaryParkingLots,
	getParkingLotNames,
	spacesData,
	shareSpaceList,
	getOwnerSpaces,
	getUserBookingSpacesListHistory,
	getUserSharingSpacesList,
	getFreeEntrance
};

