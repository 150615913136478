import React from 'react';
import './BookingSpaceFormHead.scss';
import background from '../../../../assets/images/orders.svg';

import { Box, Typography, Grid } from '@mui/material';


const BookingSpaceFormHead = (props) => (
  <React.Fragment>
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <img src={background} className="BookingHeadBackround" alt="background" />
      {/*<Box className="SpaceHeadCard" >*/}
      {/*  <Typography className="SpaceName">{props.space.name}</Typography>*/}
      {/*  <Typography>{props.space.parkingLotName}</Typography>*/}
      {/*  <Box className="triangleDown" />*/}

      {/*</Box>*/}
    </Grid>
  </React.Fragment>

);

export default BookingSpaceFormHead;
