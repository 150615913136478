import './SettingsView.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import SettingsList from '../SettingsList';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  UserNotificationConfig,
  DeadlineTime,
  ReminderTime,
  VehiclesLimit,
} from '../SettingProperties';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getHubPath } from 'utils/ProxyPathService'

export default function SettingsView({ my, title, currentUser, setUnbookingTime }) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const setTo = (state) => {
    const thisUser_ = state && state.user != null ? state.user : currentUser;

    return thisUser_;
  }
  const goBack = () => {
    history.goBack()
  }

  const [hubConnection, setHubConnection] = useState(null);
  const [lastUpdateDt, setLastUpdateDt] = useState(new Date());
  const [user, setUser] = useState(setTo(location.state));
  const showAdmin = location.state && location.state.user != null ? false : true;
  const autoMatch = location.state && location.state.autoMatch != null ? location.state.autoMatch : null;
  let isAdmin = user != null && user.role === 'Admin' ? true : false;
  const { t } = useTranslation();
  const [listTitle, setListTitle] = useState(title || 'settings.Title');

  const getUserSettings = (item) => {
    const userconsfig = [
      UserNotificationConfig,
      'UserNotificationConfigViolation',
      'UserNotificationConfigSilencePeriod',
      'UserNotificationConfigBookedSpace',
      'UserNotificationConfigReminderNotification',
      'UserNotificationConfigAdminViolationReport',
      'SendMonthlyFinanceReport',
      DeadlineTime,
      ReminderTime,
      VehiclesLimit,
    ];
    return userconsfig.includes(item.property);
  };

  const filterSystemSettings = (items) => {
    const filtered = {};
    if (!items) return filtered;
    items.map((item, i) => {
      if (
        !item.ownerId &&
        getUserSettings(item)
      )
        filtered[item.property] = item;
    });

    return filtered;
  };

  useEffect(() => {

    const hubConnection = new HubConnectionBuilder()
      .withUrl(getHubPath())
      .withAutomaticReconnect()
      .build();

    setHubConnection(hubConnection);
    hubConnection
      .start()
      .then(() => console.log('Connection started!'))
      .catch((err) => console.log('Error while establishing connection :('));

    hubConnection.on('ReceiveMessage', (data) => {
      if (data !== 'settings') {
        return;
      }

      setLastUpdateDt(new Date());
    });

    return () => {
      hubConnection
        .stop()
        .then(() => console.log('connection closed'))
        .catch(() => console.log('failed to close'));
    };
  }, []);

  return (
    <React.Fragment>
      {!showAdmin ? (<Topbar title={t(listTitle)} cbBack={goBack} ></Topbar>) : (<Topbar title={t(listTitle)} ></Topbar>)}

      <div className="settingsViewContainer">
        {!showAdmin ? (<SettingsList
          title={t('settings.UserSettings')}
          currentUser={user}
          lastUpdateDt={lastUpdateDt}
          autoMatch={autoMatch}
        ></SettingsList>) : null}

        {showAdmin && isAdmin ? (
          <SettingsList
            title={t('settings.SystemSettings')}
            currentUser={currentUser}
            settingsFilterFn={filterSystemSettings}
            forceEnableEditing={true}
            isAdmin={isAdmin}
            showAdmin={showAdmin}
            autoMatch={autoMatch}
          ></SettingsList>
        ) : null}
      </div>
    </React.Fragment>
  );
}
