import React, {Component} from 'react';
import './BookedList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import { List, Divider, ListItem } from '@mui/material';
import BookedListItem from './BookedListItem';

class BookedList extends Component {

  render() {
    const {
      t, 
      onCreate,
      onDelete, 
      bookedSpaces, 
      history,
      needLoad,
      load,
      title,
      shared,
      parkingSpaces,
      parkingLots,
      spaceNames
    } = this.props;

    let listHeadTitle = 'Labas';

    if( title ){
      listHeadTitle = title;
    }
    
 
    return (
      <React.Fragment>
        {!history && <ListHead title={listHeadTitle} />} 
        <List>
          {bookedSpaces && bookedSpaces.map((bookedSpace, i) => (
            <React.Fragment
              key={bookedSpace.id}
            >
              <BookedListItem
                bookedSpace={bookedSpace}
                parkingSpaces={parkingSpaces}
                parkingLots={parkingLots}
                spaceName={spaceNames ? spaceNames.find(x => x.id === bookedSpace.parkingSpaceId) : null}
              />
              <Divider />
            </React.Fragment>
          ))}
          {needLoad &&
            <ListItem
              key="more"
              onClick={() => load(true)}
              className="LoadMore"
            >
              <p style={{ textAlign: "center" }}>
                <b>{t('general.LoadMore')}</b>
              </p>
            </ListItem>
          }
        </List>
      </React.Fragment>
    );
  }
}
export default withTranslation()(BookedList);
