import React, { Component } from 'react';
import './BookingSpaceForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import InputDate from '../../ui/inputs/date/InputDate';
import { Grid, Fab, Box, Dialog, CircularProgress, Checkbox, Typography, List } from '@mui/material';
import { getUsers } from '../../../utils/UsersService';
import { bookSpace, deleteBookedSpace, getFreeEntrance } from '../../../utils/ParkingService';
import { withSnackbar } from 'notistack';
import BookingSpaceFormHead from './head/BookingSpaceFormHead';
import MomentUtils from '@date-io/moment';
import { InputSearch } from '../../ui/inputs/search/Search';
import UserListItem from '../../users/list/item/UserListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import { getParkingPrice } from "../../../utils/PricingHelpers";
import PriceInfoCard from '../../ui/informationCard/PriceInfoCard';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../../constants/Api';
import { assignSharedSpace } from 'utils/AssignSharedSpaceService';
import DeleteDialog from 'components/ui/dialogs/DeleteDialog';
import { checkForSilencePeriods } from 'utils/SilencePeriodsService';
import { getValidDates } from 'utils/CalendarService';
const searchAPIDebounced = AwesomeDebouncePromise(getUsers, Api.SEARCH_DEBOUNCE);

const moment = new MomentUtils({ locale: 'lt' })

class BookingSpaceForm extends Component {
  state = {
    from: moment.date().utc(0).startOf('day'),
    to: moment.date().utc(0).endOf('day'),
    bookedSpace: {
      id: null,
      sharedSpaceId: null,
      userId: null,
      bookedFrom: null,
      bookedTo: null,
      autoCreatedDt: null
    },
    user: {
      name: ''
    },
    usersList: [],
    openedUsersDialog: false,
    isNew: true,
    minDate: null,
    maxDate: null,
    clicked: false,
    submitTitle: null,
    deleteChecked: false,
    price: null,
    freedays: null,
    inlcudeWeekendsInSharing: false,
    showDeleteConfirmationDialog: false,
    submitData: null,
    startValidDates: null,
    endValidDates: null,
    startMinDate: null,
    startMaxDate: null,
    endMinDate: null,
    endMaxDate: null
  }

  resolveDate = ({ sharedFrom, sharedTo }) => {

    const { vacantTo } = this.props.location.state;

    const dateTo = () => {
      if (vacantTo) {
        if (sharedTo) {
          if (moment.date(sharedTo) > moment.date(vacantTo)) {
            return (moment.date(vacantTo).utc(3).endOf('day'))
          } else {
            return (sharedTo ? moment.date(sharedTo).utc(0).endOf('day') : moment.date(sharedFrom).utc(0).endOf('day'))
          }
        }
        else {
          return (sharedTo ? moment.date(sharedTo).utc(0).endOf('day') : moment.date(sharedFrom).utc(0).endOf('day'))
        }
      } else {
        return (sharedTo ? moment.date(sharedTo).utc(0).endOf('day') : moment.date(sharedFrom).utc(0).endOf('day'))
      }
    }
    const dates = {
      from: moment.date(sharedFrom).utc(0).startOf('day'),

      to: sharedTo ? moment.date(sharedTo).utc(0).endOf('day') : moment.date(sharedFrom).utc(0).endOf('day')
    }
    return dates
  }

  fetchUsers = async (query, offset, loadingMore) => {
    try {
      let hasMore = false;

      const resp = await searchAPIDebounced(query, offset)
      if (resp.nextOffset) hasMore = true

      if (!loadingMore) {
        this.setState(() => ({ usersList: [...resp.items], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
      } else {
        this.setState(prevState => ({ usersList: [...new Set([...prevState.usersList, ...resp.items])], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
      }

    }
    catch (e) {
      console.error(e)
    }
  }

  search = (e) => {
    // pakeisti i debounce o state po atsakymo
    this.fetchUsers(e.target.value)
  }

  changeShareWith = async () => {
    await this.fetchUsers()
    await this.setState({ openedUsersDialog: true })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleClose = () => {
    this.setState({ openedUsersDialog: false })
  }

  changeOwner = (user) => {
    this.setState({ user, openedUsersDialog: false })
  }

  initiateSubmit = async (event) => {
    try {
      let fromDate = this.state.from;
      let toDate = this.state.to;

      if (fromDate) {
        fromDate = fromDate.format('YYYY-MM-DDT00:00:00.000').toString();
      }

      if (toDate) {
        toDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
      }

      const params = {
        userId: this.state.user.id,
        bookedFrom: fromDate,
        bookedTo: toDate,
        sharedSpaceId: this.state.space.id,
        bookingLevel: this.state.space.bookingLevel
      }

      if (!this.state.isNew) {
        params.id = this.state.bookedSpace.id
      }

      if (this.state.space.parkingGroupId) {
        params.parkingGroupId = this.state.space.parkingGroupId
      }

      if (this.state.submitTitle == null) {
        if (this.state.space.bookingLevel === 0) {
          const { affectedIds } = await bookSpace(params, this.state.bookedSpace.id)
          if (affectedIds && affectedIds.length) {
            this.props.enqueueSnackbar(this.props.t(this.state.isNew ? 'notification.success.BookedSpace' : 'notification.success.updateBookedSpace'), {
              variant: 'success',
            });
            this.props.history.goBack()
          }
        } else if (this.state.space.bookingLevel === 2) {
          let data;

          if (this.state.bookedSpace.id) {
            const request = await bookSpace(params, this.state.bookedSpace.id);
            data = request;
          } else {
            const request = await assignSharedSpace(params);
            data = request;
          }

          if (data) {
            this.props.enqueueSnackbar(this.props.t(this.state.isNew ? 'notification.success.BookedSpace' : 'notification.success.updateBookedSpace'), {
              variant: 'success',
            });
            this.props.history.goBack();
          }
        }
      } else {
        const { affectedIds } = await deleteBookedSpace(this.state.bookedSpace.id);
        if (affectedIds && affectedIds.length) {
          this.props.enqueueSnackbar(
            this.props.t('notification.success.DeleteBookedSpaces'),
            {
              variant: 'success',
            }
          );
          this.props.history.goBack()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    if (this.props.location.state) {
      const inlcudeWeekendsInSharing = (this.props.settings.Customer.InlcudeWeekendsInSharing?.value ?? 'false') === 'true'
      this.setState({ inlcudeWeekendsInSharing: inlcudeWeekendsInSharing });
      let action = this.props.match.params.action;

      if (action === 'new') {

        const { space, user } = this.props.location.state;

        if (space && user) {
          const dates = this.resolveDate(space)
          this.setState({ space, user, ...dates })
        } else {
          this.props.history.goBack()
        }
      }
      else if (action === 'edit') {
        const { space, bookedSpace, user, minDate_, maxDate_, sharedDates } = this.props.location.state;

        if (space) {
          const params = {
            bookedSpaceId: bookedSpace.id,
            dateFrom: bookedSpace.bookedFrom,
            dateTo: bookedSpace.bookedTo
          }
          const validDates = await getValidDates(params, 'BookedSpaceEdit');
          if (validDates) {
            this.setState({
              startValidDates: validDates.startValidDates,
              endValidDates: validDates.endValidDates,
              startMinDate: validDates.startMinDate,
              startMaxDate: validDates.startMaxDate,
              endMinDate: validDates.endMinDate,
              endMaxDate: validDates.endMaxDate
            });
          }

          const from = moment.date(bookedSpace.bookedFrom).utc(0);
          const to = moment.date(bookedSpace.bookedTo).utc(0);
          const minDate = moment.date(minDate_).utc();
          const maxDate = moment.date(maxDate_).utc();
          this.setState({
            space, user, bookedSpace, from, to, minDate, maxDate, sharedDates,
            isNew: false,
            submitTitle: bookedSpace.autoCreatedDt ? this.props.t("general.Delete") : null,
            deleteChecked: bookedSpace.autoCreatedDt
          });
        }
      }

      if (this.props.location.state.space.parkingRateId) {
        const rate = await getParkingPrice(this.props.location.state.space.parkingRateId);
        this.setState({ price: rate })
      }
      const freedays = await getFreeEntrance();
      this.setState({ freedays: freedays });

    } else {
      this.props.history.goBack()
    }
  }

  setFromDate(momentDate) {
    momentDate = moment.date(momentDate).utc(0).startOf('day');
    this.setState({ from: momentDate });
    if (this.state.to) {
      if (momentDate.isAfter(this.state.to)) {
        this.setToDate(momentDate);
      }
    }
  }

  setToDate(momentDate) {
    momentDate = moment.date(momentDate).utc(0).endOf('day');
    this.setState({ to: momentDate });
    if (this.state.from) {
      if (momentDate.isBefore(this.state.from)) {
        this.setFromDate(momentDate);
      }
    }
  }

  setSubmitTitle = (event) => {
    this.setState({ deleteChecked: event.target.checked });
    if (event.target.checked) {
      this.setState({ submitTitle: this.props.t("general.Delete") });
    } else {
      this.setState({ submitTitle: null });
    }
  }

  handleCloseDeleteConfirmationDialog = () => {
    this.setState({ showDeleteConfirmationDialog: false });
  }

  handleAfterConfirmDialog = () => {
    this.initiateSubmit(this.state.submitData);
    this.setState({ showDeleteConfirmationDialog: false });
  }

  handleSubmit = async (event) => {
    this.setState({ clicked: true });
    event.preventDefault()

    let fromDate = this.state.from;
    if (fromDate) {
      fromDate = fromDate.format('YYYY-MM-DDT00:00:00.000').toString();
    }

    let toDate = this.state.to;
    if (toDate) {
      toDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
    }

    // if (this.state.space.bookingLevel === 2) {
    //   await checkForSilencePeriods(this.state.user.id, fromDate, toDate)
    //     .then((response) => {
    //       if (response) {
    //         this.setState({ submitData: event, showDeleteConfirmationDialog: true });
    //       } else {
    //         //create without dialog
    //         this.initiateSubmit(event);
    //       }
    //     })
    //     .catch((error) => console.error(error));
    // }
    await this.initiateSubmit(event);

    this.setState({ clicked: false });
  }

  render() {
    const currentLng = localStorage.getItem('currentLng');
    const { from, to, space, openedUsersDialog, user, isNew, minDate, maxDate, sharedDates, submitTitle, bookedSpace, deleteChecked, price, freedays, inlcudeWeekendsInSharing, showDeleteConfirmationDialog,
    startValidDates, endValidDates, startMinDate, startMaxDate, endMinDate, endMaxDate } = this.state;
    const { t, location, isAdmin } = this.props;
    const actionType = this.props.match.params.action;
    const submitTitle_ = submitTitle ? submitTitle : t('general.Save');

    return (
      <React.Fragment>
        <div className="ExchageContainer">
          <Topbar
            disableShadow={true}
            cbBack={this.goBack}
            title={t(isNew ? 'exchanges.BookSpace' : 'exchanges.BookedPeriodChange')}
            childClass="BookingHeadContainer"
            location={location}>

          </Topbar>
          {space && <BookingSpaceFormHead space={space} />}
          {isAdmin && <Dialog fullScreen open={openedUsersDialog} onClose={this.handleClose} >
            <Topbar
              title={t("users.Title")}
              closeDialog={this.handleClose}
              cbCreate={this.createUser}
              disableShadow={true}
            >
              <InputSearch onChange={this.search} placeholder={t("general.Search")} />
            </Topbar>
            {this.state.usersList &&
              <div id="UsersDialogContainer" className="UsersDialogContainer">
                <List className="UsersList" >
                  <InfiniteScroll
                    dataLength={this.state.usersList.length}
                    hasMore={(this.state.hasMore)}
                    next={() => this.fetchUsers(this.state.query, this.state.nextOffset, true)}
                    loader={
                      <p>{t('general.loading')}...</p>
                    }
                  >
                    {this.state.usersList.length > 0 &&
                      this.state.usersList.map((e, i) => (
                        <UserListItem
                          user={e}
                          key={(e.id + i)}
                          onDelete={this.deleteUser}
                          onMainClick={this.goToProfile}
                          onSelect={this.changeOwner}
                        />
                      ))}
                  </InfiniteScroll>
                </List>
              </div>
            }
          </Dialog>}
          <form
            className="BookingFormContainer"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <Grid container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} >
                <Grid container paddingLeft={1} >
                  {isNew &&
                    <Grid item xs={6}>
                      <InputDate
                        minDate={space && space.sharedFrom}
                        maxDate={space && space.sharedTo}
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        currentLng={currentLng}
                        validDates={startValidDates}
                      />
                    </Grid>
                  }
                  {!isNew &&
                    <Grid item xs={6}>
                      <InputDate
                        minDate={startMinDate ? startMinDate : minDate}
                        maxDate={startMaxDate ? startMaxDate : maxDate}
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        disableWeekend={!inlcudeWeekendsInSharing}
                        excludesList={sharedDates}
                        currentLng={currentLng}
                        readOnly={bookedSpace.autoCreatedDt || from < moment.date().utc(0).startOf('day')}
                        validDates={startValidDates}
                      />
                    </Grid>
                  }
                  {isNew &&
                    <Grid item xs={6}>
                      <InputDate
                        maxDate={space && space.sharedTo}
                        minDate={space && space.sharedFrom}
                        value={to}
                        label={t('exchanges.End')}
                        id="to"
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        currentLng={currentLng}
                        validDates={endValidDates}
                      />
                    </Grid>
                  }
                  {!isNew &&
                    <Grid item xs={6}>
                      <InputDate
                        minDate={endMinDate ? endMinDate : maxDate}
                        maxDate={endMaxDate ? endMaxDate : minDate}
                        value={to}
                        label={t('exchanges.End')}
                        id="to"
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        disableWeekend={!inlcudeWeekendsInSharing}
                        excludesList={sharedDates}
                        currentLng={currentLng}
                        readOnly={bookedSpace.autoCreatedDt}
                        validDates={endValidDates}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={-2}>
                {isAdmin && user && <UserListItem
                  user={user}
                  onChange={isAdmin ? this.changeShareWith : false}
                />
                }
              </Grid>
              {!isNew &&
                <Grid item xs={12} className="CheckBoxContainer">
                  <Checkbox className="CheckBoxComponent"
                    onChange={this.setSubmitTitle} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                    disabled={bookedSpace.autoCreatedDt} checked={deleteChecked}
                  />
                  <Typography >
                    {t("general.DeleteBooking")}
                  </Typography>
                </Grid>}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PriceInfoCard
                type="booking"
                dateFrom={from}
                dateTo={to}
                parkingInfo={space}
                bookedSpace={bookedSpace}
                price={price}
                freedays={freedays}
                inlcudeWeekendsInSharing={inlcudeWeekendsInSharing}
                excludesList={sharedDates}
              />
              <Grid item xs={10} py={2}>
                <Fab disabled={this.state.clicked}
                  className="full-width secondary-btn" variant="extended"
                  type="submit"
                  value="Submit"
                  aria-label="Save">
                  {this.state.clicked ?
                    <Box position="relative" display="inline-flex">
                      <CircularProgress size={30} color={'secondary'} />
                    </Box> :
                    <Box fontSize={14} textAlign="center">
                      {submitTitle_}
                    </Box>
                  }
                </Fab>
              </Grid>
            </Grid>
            <DeleteDialog
              openIt={showDeleteConfirmationDialog}
              onConfirm={this.handleAfterConfirmDialog}
              onClose={this.handleCloseDeleteConfirmationDialog}
              text={actionType === 'new' ? 'confirm.deleteSilencePeriodsDuringBookingCreation' : 'confirm.deleteSilencePeriodsDuringBookingUpdate'}
              isDefaultTextNeeded={false}
              isConfirmationDialog={true}
            />
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(BookingSpaceForm))