import React, { useEffect, useState } from 'react';
import { currencyFormat } from '../../../utils/PricingHelpers';
import { ListItem, IconButton, Box, Typography, Button, Divider, Grid } from '@mui/material';
import MomentUtils from "@date-io/moment";
import ListExpanded from 'components/ui/list/expanded/ListExpanded';
import './ActiveHistoryItem.scss';
const ActiveHistoryItem = (props) => {
    // const {from, to , sharedSpaceId, parkingSpaceId, parkingLotId, sharedSpaceId, price, type, exchange} =props;
    const { exchange, lots } = props;

    // const from = exchange.bookedFrom? exchange.bookedFrom: exchange.sharedFrom;
    // const to = exchange.bookedTo? exchange.bookedFrom: exchange.sharedFrom;
    const from = exchange.from;
    const to = exchange.to;

    const currentLng = localStorage.getItem('currentLng');
    const moment = new MomentUtils({ locale: currentLng });
    const fromFormated = moment.date(from).utc(0).format('MMM DD');
    const toFormated = moment.date(to).utc(0).format('MMM DD');
    const [spaceName, setSpaceName] = useState();
    const [lotName, setLotName] = useState();
    const [parkingLotNames, setParkingLotNames] = useState();
    //
    // parkingLotId: "32e0ddb0-626b-42d6-bcb2-9eb01bda5b94"
    //
    // parkingSpaceId: "8ccc13bb-ca8e-452e-963a-650cc60ec2d4"
    //
    // price: 5
    //
    // sharedSpaceId: "86a4632c-23b1-4ac6-bfea-bc9c6d469072"

    // console.log ('exchange', exchange);
    const [totalPrice, setTotalPrice] = useState();

    useEffect(() => {
        { fetchInfo() }
    }, []);

    const fetchInfo = async () => {
        const parkingLotId = exchange.parkingLotId;
        const parkingSpaceId = exchange.parkingSpaceId;

        if (exchange.bookingLevel == 2) {
            const lotNames = lots.filter(x => x.territoryId == exchange.territoryId).map(x => { return x.name });
            if (lotNames.length > 1) {
                setParkingLotNames(lotNames);
            }
        }
        setLotName(exchange.lotName);
        setSpaceName(exchange.spaceName);

    }

    return (
        <React.Fragment>

            {spaceName && lotName && <ListItem
                // button={onBookSpace && isNew}
                // onClick={() => onBookSpace && isNew && onBookSpace({ ...parkingSpace, ...exchange })}
                className="ActiveHistoryItemContainer"
            >
                <Grid container>
                    <Grid item xs={12} sx={{ background: '#fffff', borderRadius: '10px', borderColor: "#3A4D8F", border: '2px solid #3A4D8F' }}>
                        <Grid container >
                            <Grid item xs={2} >
                                <Grid container direction='column' alignItems='center' sx={{ py: 1.5 }}>
                                    <Grid item xs sx={{ py: 1, px: 1, background: '#fffff', borderRadius: '5px', borderColor: "#3A4D8F", border: '1px solid #3A4D8F' }}>
                                        <Typography variant="h4" color="#3A4D8F" >
                                            {fromFormated}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs marginTop={1} sx={{ py: 1, px: 1, background: '#fffff', borderRadius: '5px', borderColor: "#3A4D8F", border: '1px solid #3A4D8F' }}>
                                        <Typography variant="h4" color="#3A4D8F">
                                            {toFormated}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs sx={{ background: '#ffffff', py: 0.8, px: 2, borderRadius: '10px' }}> */}
                            <Grid item xs sx={{ py: 1.5, px: 2 }}>
                                {parkingLotNames && parkingLotNames.length > 1 ?
                                    <div>
                                        <Typography variant='h3'>
                                            {exchange.territoryName}
                                        </Typography>

                                        <Typography variant='h3'>{spaceName && ` (${spaceName}) `}</Typography>
                                    </div>
                                    :
                                    <Typography variant='h3'>{spaceName}</Typography>}

                                <div className='ExchangeSpaceSubtitle'>
                                    {lotName && <ListExpanded names={parkingLotNames} name={lotName}></ListExpanded>}
                                </div>
                            </Grid>

                            <Grid item sx={{ py: 1.5, px: 2 }}>
                                <Typography className='type'>
                                    {exchange.type}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>}

        </React.Fragment>
    )

}

export default ActiveHistoryItem;