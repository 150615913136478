import { Component } from "react";
import { Topbar } from "components/ui/topbar/Topbar";
import { withTranslation } from 'react-i18next';
import BusinessParking from "components/ui/calendar/payment/BusinessParking";
import LinkBank from "components/public/providers/kevin/LinkBank";

class Payments extends Component {
    constructor() {
        super();
    }

    navigate = async (pathname) => {
        this.props.history.push({ pathname: pathname });
    }

    render() {
        const { t, currentUserId } = this.props;
        return (
            <div>
                <Topbar title={t("administration.Payments")} cbBack={false} ></Topbar>
                <div className="dashboardContainer">
                    <BusinessParking
                        navigate={this.navigate}
                        currentUserId={currentUserId}
                    />
                    <LinkBank navigate={this.navigate} />
                </div>

            </div>
        )
    }

}
export default withTranslation()(Payments);