import React, { Component } from 'react';
import { ListItem, Grid, Typography, Button, CircularProgress, Box, ButtonBase } from '@mui/material';
import { withSnackbar } from 'notistack';
import i18next from 'i18next';
import MomentUtils from '@date-io/moment';
import x_circle from '../../../../assets/icons/publicApp/x-circle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLink, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { getBusinessPayment, confirmBusinessPayment } from 'utils/payments/BusinessPaymentService';
import { getTenantPrefix } from 'utils/ProxyPathService';
import { lastPayment, postPayment } from 'utils/PaymentsService';
import { LINKED_BANK } from 'constants/PaymentTypes';
import { initiatePayment } from 'utils/KevinService';
import { NotificationManager } from 'react-notifications';
import BankImg from 'components/public/providers/kevin/BankImg';

const moment = new MomentUtils({ locale: 'en' });

class BusinessParking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentResponse: null,
      paymentCompleted: false,
      paymentError: false,
      businessPaymentData: null,
      selectedPaymentMethod: null
    };
  }

  getDate = () => {
    return moment.date().format('YYYY-MM-DD').toString();
  };

  componentDidMount = async () => {
    await this.getPaymentStatus();
    await this.fetchSelectedPaymentMethod(this.props.currentUserId);
    await this.getPaymentData(this.props.currentUserId);
  }

  getPaymentStatus = async () => {
    if (localStorage.getItem('businessPayment')) {
      const businessPayment = JSON.parse(localStorage.getItem('businessPayment'))
      this.setState({ businessPayment });
      const payment = {
        id: businessPayment.paymentId,
        bankStatus: businessPayment.bankStatus,
        cardStatus: businessPayment.cardStatus,
        statusGroup: businessPayment.statusGroup
      }
      const success = payment.statusGroup === 'completed';
      confirmBusinessPayment(payment).then(() => {
        this.setState({ businessPayment: null })
        if (success) {
          this.setState({ paymentCompleted: true })
        }
        else {
          this.setState({ paymentError: true })
          this.timer = setInterval(
            () => { this.setState({ paymentError: false }) },
            () => this.getPaymentData(this.props.currentUserId),
            5000
          );
        }
      });
    }
    localStorage.removeItem('businessPayment')
  }

  getPaymentData = async (userId) => {
    if (!this.state.paymentResponse) {
      const body = {
        userId: userId
      }
      getBusinessPayment(body).then((businessPaymentData) => {
        if (businessPaymentData) {
          this.setState({ businessPaymentData, paymentCompleted: businessPaymentData.paymentCompleted })
        }
      })
    }
  }

  fetchSelectedPaymentMethod = async (userId) => {
    let selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
    if (!selectedPaymentMethod) {
      const plateNum = localStorage.getItem('selectedVehicle');
      lastPayment(userId, plateNum).then((bankId) => {
        if (bankId && bankId !== 'card') {
          const countryCode = bankId.slice(-2);
          const linkedBanks = JSON.parse(localStorage.getItem('kevinTokens')) ?? [];
          localStorage.setItem('countryCode', countryCode);
          let bank = linkedBanks.find((x) => x.id === bankId);
          if (bank) {
            selectedPaymentMethod = { paymentType: LINKED_BANK, bank: bank };
            localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
            this.setState({ selectedPaymentMethod });
          }
        }
      });
    } else {
      this.setState({ selectedPaymentMethod });
    }
  };

  goPayment = () => {
    const tenantId = getTenantPrefix();
    const tenantPrefix = tenantId && tenantId !== '' ? tenantId + '/' : '';
    const bankId = this.state.selectedPaymentMethod?.bank.id;

    let paymentData = {
      redirect: `${window.location.origin}/businessPaymentConfirm`,
      webhook: `${window.location.origin}/api/${tenantPrefix}v1/BusinessPayment/confirmPayment`,
      lot: this.state.businessPaymentData.lot,
      payment: this.state.businessPaymentData.payment,
      userId: this.props.currentUserId,
      bankId: bankId,
      plateNum: this.state.businessPaymentData.plateNum,
      provider: this.state.businessPaymentData.payment.paymentProvider,
      paymentMethodPreferred: 'bank'
    }

    const state = {
      paymentData: paymentData
    }

    if (bankId) {
      initiatePayment(paymentData)
        .then((kevin) => {
          if (kevin) {
            window.location.href = kevin.confirmLink;
          }
        })
        .catch((error) => {
          postPayment(paymentData, null, error.message).then(() => {
            NotificationManager.error(error.message);
          });
        });
    } else {
      this.props.navigate('bankSelection/payment', state, null)
    }
  }

  selectBank = () => {
    this.props.navigate('bankSelection/bank', null, null)
  };

  render() {
    const { t } = this.props;
    const { paymentResponse, paymentCompleted, paymentError, businessPaymentData, selectedPaymentMethod } = this.state
    return (
      <div>
        {(businessPaymentData || paymentResponse || paymentCompleted || paymentError) && <ListItem className="DashboardItemContainer">
          <Grid container>
            <Grid item xs={12} sx={{ background: '#F2F2F2', py: 1, px: 2, borderRadius: '10px', borderColor: '#3A4D8F', border: '2px solid #3A4D8F' }}>
              <Grid container direction="row" alignItems="center" paddingTop={1}>
                <Grid item xs>
                  <Typography color="#3A4D8F" style={{ fontWeight: '500 !important' }}>
                    {t('businessParking.DailyPayment')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography color="#3A4D8F">{this.getDate()}</Typography>
                </Grid>
              </Grid>
              <Grid item xs paddingTop={2}>
                {paymentCompleted ? (
                  <Grid container>
                    <Grid item xs={2} paddingTop={0.5}>
                      <FontAwesomeIcon icon={faCheck} color="#67D889" size="2x" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className="h1" color="#3A4D8F">
                        {t('businessParking.Completed')}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography className="h1" color="#3A4D8F">
                    {(businessPaymentData.payment?.amount ?? 0) + ' EUR'}
                  </Typography>
                )}
              </Grid>
              {selectedPaymentMethod && selectedPaymentMethod.paymentType === LINKED_BANK &&
                businessPaymentData.payment && businessPaymentData.payment.amount > 0 && (
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} textAlign="left">
                      <ButtonBase onClick={this.selectBank}>
                        <Grid sx={{ ml: 1 }}>
                          <FontAwesomeIcon icon={faLink} color="#2B4469" />
                        </Grid>
                        <Grid sx={{ mx: 1 }}>
                          <BankImg item={selectedPaymentMethod.bank} tinyVariant={true} />
                        </Grid>
                        <Box mr={1}>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </Box>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                )}
              <Grid container py={1}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8} textAlign="center">
                  {paymentResponse ? (
                    <CircularProgress size={37} thickness={5} color="primary" />
                  ) : paymentError ? (
                    <Box>
                      <img src={x_circle} style={{ width: '37px', height: '37px' }} />
                    </Box>
                  ) : (
                    <Button
                      id="btn-pay"
                      disableElevation
                      fullWidth
                      type="button"
                      disabled={paymentCompleted}
                      variant="contained"
                      color='secondary'
                      onClick={() => this.goPayment()}
                      style={{
                        borderRadius: '8px',
                        padding: '5px 0',
                      }}
                    >
                      {i18next.t('businessParking.Pay')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>}</div>
    );
  }
}
export default withSnackbar(withTranslation()(BusinessParking));
