import React, {Component} from 'react';
import './UsersList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import { List, Divider } from '@mui/material';
import UserListItem from './item/UserListItem';

class UsersList extends Component {

  render() {
    const {t, onCreate, users} = this.props;
    return (
      <React.Fragment>
      <ListHead title={t('users.Title')} onCreate={onCreate} />
        <List>
          {users && users.map( user => (
            <React.Fragment
            key={user.id}
            >
              <UserListItem
                shadow={true}
                user={user}
                {...this.props}
                />
                <Divider />
            </React.Fragment>
          ))}
        </List>
      </React.Fragment>
      );

  }
}
export default withTranslation()(UsersList);
