import { axiosA } from './AuthService';

const getGroupUsers = async (groupId) => {
    const { data } = await axiosA.get(`/GroupUsers/${groupId}`)
    return data
}

const postGroupUser = async (groupUser) => {
    try {
        const body = {
            parkingGroupId: groupUser.groupId,
            userId: groupUser.userId
        }
        const { data } = await axiosA.post('/GroupUsers', body)
        return data

    } catch (e) {
        throw new Error(e)
    }
}

const deleteGroupUser = async (id) => {
    const { data } = await axiosA.delete(`/GroupUsers/${id}`)
    return data
}

const getUsers = async (groupId, query = '', Offset, Limit = 20) => {

    try {
        let link = `/GroupUsers/Search?Search=${query}&Limit=${Limit}&GroupId=${groupId}`
        if (Offset) link += '&Offset=' + Offset
        const { data } = await axiosA.get(link)
        return data
    } catch (e) {
        throw new Error(e)
    }
}


export {
    getGroupUsers,
    postGroupUser,
    deleteGroupUser,
    getUsers
}