import { axiosA } from './AuthService';
import * as pushServiceWorker from '../pushServiceWorker';
import { NotificationManager } from 'react-notifications';
import { isNative, sendToNative } from 'utils/bridge';
import i18next from 'i18next';

const getSettings = async (userId, checkDisabled) => {
  try {
    let query = '?true';
    if (userId) {
      query += `&OwnerId=${userId}`;
    }
    if (checkDisabled) {
      query += `&CheckDisabled=${checkDisabled}`;
    }
    const { data } = await axiosA.get(`/Settings${query}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const getUserSettings = async (userId) => {
  try {
    const { data } = await axiosA.get(`/Settings/user/${userId}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const updateSettingsLocale = async (Locale, userId) => {
  try {
    const items = await getUserSettings(userId);
    const LocaleSettings = await items.find((i) => i.property === 'Locale');

    let localeSettings = {
      value: Locale,
      description: 'User locale setting',
      category: 'User',
      property: 'Locale',
    };

    if (LocaleSettings) {
      localeSettings['id'] = LocaleSettings.id;
      localeSettings['category'] = LocaleSettings.category;
      await axiosA.put(`/Settings/${LocaleSettings.id}`, localeSettings);
    } else {
      const settings = await getSettings();
      var global = await settings.items.find(x => x.property === 'EnabledLocales' && !x.ownerId);
      localeSettings['category'] = global.customerId ? 'User' : 'Custom';
      await axiosA.post(`/Settings`, localeSettings);
    }
  } catch (e) {
    throw new Error(e);
  }
};

const updateNotificationConfig = async (
  isSystemSettings = false,
  settingObj,
  notificationConfig,
  settingId,
  userId
) => {
  let customerNotificationConfig = {
    value: JSON.stringify(notificationConfig),
    description: settingObj.description,
    category: isSystemSettings ? settingObj.category : 'User',
    property: settingObj.property,
  };
  try {
    if (isSystemSettings) {
      if (settingObj.isReadOnly) {
        customerNotificationConfig['isReadOnly'] = settingObj.isReadOnly;
      }
    } else {
      if (settingObj.ownerId) {
        customerNotificationConfig['ownerId'] = settingObj.ownerId;
      }

      if (userId) {
        customerNotificationConfig['ownerId'] = userId;
      }
    }
    if (!isSystemSettings) {
      const settings = await getSettings();
      var global = await settings.items.find(x => x.property === settingObj.property && !x.ownerId);
      customerNotificationConfig['category'] = global.customerId ? 'User' : 'Custom';
    }
    if (settingId && customerNotificationConfig['category'] === settingObj.category) {
      customerNotificationConfig['Id'] = settingId;
      await axiosA.put(`/Settings/${settingId}`, customerNotificationConfig);
    } else {
      await axiosA.post(`/Settings`, customerNotificationConfig);
    }
  } catch (e) {
    new Error(e);
  }
};

const updateUserSetting = async (
  isSystemSettings = false,
  settingObj,
  settingId,
  userId
) => {
  try {
    let userValue = {
      value: settingObj.value,
      description: settingObj.description,
      category: isSystemSettings ? settingObj.category : 'User',
      property: settingObj.property,
    };

    if (isSystemSettings) {
      if (settingObj.isReadOnly) {
        userValue['isReadOnly'] = settingObj.isReadOnly;
      }
    } else {
      if (settingObj.ownerId) {
        userValue['ownerId'] = settingObj.ownerId;
      }

      if (userId) {
        userValue['ownerId'] = userId;
      }
    }
    if (!isSystemSettings) {
      const settings = await getSettings();
      var global = await settings.items.find(x => x.property === settingObj.property && !x.ownerId);
      userValue['category'] = global.customerId ? 'User' : 'Custom';
    }
    if (settingId && userValue['category'] === settingObj.category) {
      userValue['Id'] = settingId;
      await axiosA.put(`/Settings/${settingId}`, userValue);
    } else {
      await axiosA.post(`/Settings`, userValue);
    }
  } catch (e) {
    new Error(e);
  }
};

// TODO split this method into more manageable :)
const requestNotificationPermissionAndUpdate = async (
  isSystemSettings = false,
  currentUser,
  setting,
  notificationConfig,
  t
) => {
  let userId = currentUser?.id;
  let settingId = setting?.id;

  try {
    // TODO: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    let deviceId = undefined;
    if (isNative()) {
      console.log('request push permission native');
      sendToNative('requestApnDeviceToken', 'message-from-webview');
      // INFO: device id is set on refresh on ios, so we can access it through localStorage.getItem('deviceId')
      deviceId = pushServiceWorker.getDeviceId();
    } else {
      if (notificationConfig && notificationConfig.NotifyPush) {
        console.log('request push permission');
        const permission = await Notification.requestPermission();
        if (
          permission !== 'granted' &&
          notificationConfig &&
          notificationConfig.NotifyPush
        ) {
          console.warn('error.BrowserNotificationsDisabled');
          // NotificationManager.error(
          //   i18next.t('error.BrowserNotificationsDisabled')
          // );
        }
      }
    }

    // TODO: when administrator modifies settings he has different url, so even when disables for customer
    // he unsubscribes, this requires checking of scope of registration maybe
    // if users settings then check scope for user, else unsubscribe yours
    // as option is changed no actual unsubscribing should be required as its disabled in BE
    if (notificationConfig && notificationConfig.NotifyPush) {
      console.log('register deviceId');
      await pushServiceWorker.register(undefined, deviceId);
    }

    // accountprovider checks if push enabled for user, and tries to subscribe after login
    // no need to update setting
    if (setting && notificationConfig) {
      await updateNotificationConfig(
        isSystemSettings,
        setting,
        notificationConfig,
        settingId,
        userId
      ).catch((error) => {
        console.error(error);
        NotificationManager.error(
          i18next.t('error.NotificationSettingsUpdateFailed')
        );
      });
    }
  } catch (error) {
    if (error === 'consent.redirectTo') {
      throw Error(error);
    }
    console.error(error);
  }
};

const updateDateTimeSetting = async (isSystemSettings, settingItem, user) => {
  const setting = settingItem;
  await updateUserSetting(isSystemSettings, setting, setting.id, user?.id);
};

const unsubscribePush = async () => {
  await pushServiceWorker.unsubscribe();
};

const getCurrentLng = async () => {
  const { data } = await axiosA.get('/Settings/CurrentLng');
  return data;
};

export {
  getSettings,
  updateSettingsLocale,
  updateNotificationConfig,
  updateUserSetting,
  requestNotificationPermissionAndUpdate,
  updateDateTimeSetting,
  getUserSettings,
  unsubscribePush,
  getCurrentLng,
};
