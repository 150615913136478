import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './TerritoryForm.scss';
import { withSnackbar, SnackbarProvider } from 'notistack';
import { Container, AppBar, Fab, Grid, Toolbar, Box, Typography } from '@mui/material';
import { getTerritoryById, postTerritory, putTerritory } from '../../../utils/TerritoriesService';
import { InputText } from '../../ui/inputs/text/Text';
import { getParkingRateById, getParkingRates } from '../../../utils/ParkingRatesService';
import { InputSelect } from "../../ui/inputs/select/Select";
import i18n from 'utils/i18n';

class TerritoryForm extends Component {
    constructor() {
        super();
        this.state = {
            parkingId: '',
            rate: [],
            selected: '',
            parkingRates: {
                title: '',
                rate: '',
                id: ''
            },
            territory: {
                name: '',
                parkingRateId: ''
            },
            _territory: {
                name: '',
                parkingRateId: ''

            },
            isNew: true,
            title: ''
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        if (this.props.location.state) {

            const { territory } = this.props.location.state;
            if (territory) {
                this.setState({ isNew: false });
                const data = await getTerritoryById(territory.id);
                this.setState({
                    territory: {
                        id: data.id || '',
                        name: data.name || '',
                        parkingRateId: data.parkingRateId || null

                    },
                    _territory: {
                        id: data.id || '',
                        name: data.name || '',
                        parkingRateId: data.parkingRateId
                    },
                    title: data.name,
                });

                if (this.state.territory.parkingRateId) {
                    const resp = await getParkingRateById(this.state.territory.parkingRateId);
                    this.setState({ selected: resp });

                }
                const rate = await getParkingRates();
                this.setState({ rate: rate });
            } else {
                this.setState({ isNew: true });
            }
        }
    }

    handleSelectChange = async (event) => {
        await this.setState({ selected: event });
        this.setState({ territory: { ...this.state.territory, parkingRateId: event.id } });
    };

    goBack = () => {

        if (this.state.isNew) {
            this.props.history.push({ pathname: '/administration' })
        } else {
            this.props.history.push({
                pathname: '/territory',
                state: { territory: this.state._territory }
            })
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.state.territory.id) {
            const resp = await postTerritory(this.state.territory)
            this.setState({ _territory: this.state.territory });
            this.goBack();
        } else {
            const resp = await putTerritory(this.state.territory);
            this.setState({ _territory: this.state.territory });
            this.goBack();
        }

    }

    handleChange = (event) => {
        this.setState({ territory: { ...this.state.territory, [event.target.id]: event.target.value } });

    }

    render() {
        const { t } = this.props;
        const { territory }
            = this.state;

        return (
            <div>
                <Topbar title={t("administration.Territory")} cbBack={this.goBack}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="SpaceBlock"
                    >


                    </Grid>
                </Topbar>
                <div className="territoryNewContainer">
                    <Grid xs container direction='row' sx={{ p: 2, py: 1.5, background: '#3A4D8F', border: '1px solid' }}>
                        <Grid item xs >
                            <Typography variant='h3' color='#ffffff'>
                                {this.state.title}
                            </Typography>
                        </Grid>

                    </Grid>

                    <form
                        onSubmit={this.handleSubmit}
                        noValidate
                    >
                        <SnackbarProvider>
                            <Container fixed className="adm_form-container">
                                <InputText
                                    type="text"
                                    id="name"
                                    onChange={this.handleChange}
                                    value={territory.name}
                                    label={t('administration.Name')}
                                    placeholder={t('administration.Name')}
                                />

                                {/*TODO vertimai*/}
                                <Grid>


                                    <InputSelect
                                        options={this.state.rate}
                                        onChange={this.handleSelectChange}
                                        value={this.state.selected}
                                        label={i18n.t('ParkingRatesForm.DailyRate')}
                                        getOptionLabel={(option) => option['title'] + ' - ' + option['rate'] + ' EUR'}
                                        getOptionValue={(option) => option['id']}
                                        placeholder={i18n.t('ParkingRatesForm.DailyRate')}
                                        maxMenuHeight={100} />
                                </Grid>
                            </Container>

                        </SnackbarProvider>
                        <AppBar color='inherit' position="fixed" className="bottom-bar" elevation={0}>
                            <Toolbar>
                                <Grid
                                    spacing={3}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Grid item xs={10}>
                                        <Fab
                                            className="full-width secondary-btn" variant="extended"
                                            type="submit"
                                            value="Submit"
                                            aria-label="Save">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Save')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </form>
                </div>
            </div>
        )
    }

}
export default withTranslation()(TerritoryForm);