import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import './UserListItem.scss';
import UserAvatar from '../../avatar/UserAvatar';
import DeleteActionButton from '../../../uiactions/actionbuttons/DeleteActionButton';

export default function UserListItem(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ListItem
        className={`item ${props.shadow ? 'item-shadow' : ''}`}
        onClick={
          props.onMainClick && (() => props.onMainClick({ ...props.user }))
        }
      >
        {props.user.name && (
          <ListItemAvatar>
            {props.user && <UserAvatar name={props.user.name} />}
          </ListItemAvatar>
        )}
        <ListItemText primary={props.user.name} />
        <ListItemSecondaryAction>
          {props.onSelect && (
            <IconButton
              className="delete-btn"
              aria-label="Select"
              onClick={() => props.onSelect(props.user)}
            >
              <FontAwesomeIcon icon={faCheck} size="xs" />
            </IconButton>
          )}
          {props.onChange && (
            <Button
              className="change-btn"
              aria-label="Change"
              onClick={() => props.onChange(props.user)}
            >
              <Typography className="change-btn-text">
                {t('general.Change')}
              </Typography>
            </Button>
          )}
          {props.onDelete && (
            <DeleteActionButton
              openit={false}
              onConfirm={() => props.onDelete(props.user.id)}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
}
