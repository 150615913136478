import i18next from 'i18next';
import '../authentication3/Login.scss';
import React, { useState } from 'react';
import background from '../../../../assets/images/Spacer.svg';
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Button, useMediaQuery, Box } from '@mui/material';
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import useAuth from 'hooks/useAuth';
import PublicAuth from '../public-auth/PublicAuth';

const Login = (state) => {
    const theme = useTheme();
    const { isLoggedIn } = useAuth();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [publicLogin, setPublic] = useState(false);

    const googleHandler = () => {
        setPublic(!publicLogin);
    };

    const onCreated = (email) => {
        setPublic(!publicLogin);
    }

    const goTerms = () => {
        window.location.href = `${window.location.origin}/terms`
    }

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh', touchAction: 'none' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh )' }}>
                        <Grid item >
                            <AuthCardWrapper>
                                <Grid container justifyContent="center" alignItems="center">
                                    <img src={background} className="login-head-image" alt="background" />
                                </Grid>
                                {(publicLogin) && (
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} >
                                            <PublicAuth onCreated={onCreated}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!publicLogin) && (
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} >
                                            <AuthLogin />
                                        </Grid>
                                    </Grid>
                                )}
                                {/* <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography sx={{ mt: 0 }}
                                                    variant="caption"
                                                    fontSize={"0.875rem"}
                                                >
                                                    <Button sx={{ p: 0 }} onClick={googleHandler}>
                                                        {!publicLogin ?
                                                            ('Don’t have an account? Sign up')
                                                            : ('Already have an account?')}
                                                    </Button>
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12}>

                                    <Box sx={{ mt: 3 }}>
                                         <Typography
                                            id='terms-and-conditions'
                                            align='center'
                                            size="large"
                                            type="button"
                                            variant="h4"
                                            color="secondary"
                                            className='h4-text-light-blue'
                                            onClick={goTerms}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {i18next.t('Login3.Conditions')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Login;
