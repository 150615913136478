class CustomFade {
  constructor(selector = "", scale = 1) {
    this._flicking = null;
    this._selector = selector;
    this._scale = scale;
  }

  get selector() {
    return this._selector;
  }

  get scale() {
    return this._scale;
  }

  set selector(val) {
    this._selector = val;
  }

  set scale(val) {
    this._scale = val;
  }

  init(flicking) {
    if (this._flicking) {
      this.destroy();
    }

    this._flicking = flicking;

    flicking.on("move", this._onMove.bind(this));
    flicking.on("afterResize", this.update.bind(this));
    this._onMove();
  }

  destroy() {
    if (!this._flicking) return;

    this._flicking.off("move", this._onMove.bind(this));
    this._flicking.off("afterResize", this.update.bind(this));
    this._flicking = null;
  }

  update() {
    this._onMove();
  }

  _onMove() {
    const flicking = this._flicking;
    const selector = this._selector;
    const scale = this._scale;

    if (!flicking) return;

    const panels = flicking.visiblePanels;

    panels.forEach((panel, index) => {
      const progress = panel.outsetProgress;
      const el = panel.element;
      const target = selector ? el.querySelector(selector) : el;

      if (target) {
        const opacity = Math.min( 1, Math.max(0, 1 - Math.abs(progress * scale) + 0.85));
        target.style.opacity = `${opacity}`;
      }
      
    });
  }
}

export default CustomFade;
