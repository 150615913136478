import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './LotForm.scss';
import { withSnackbar, SnackbarProvider } from 'notistack';
import {
    Container,
    AppBar,
    Fab,
    Grid,
    Toolbar,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Button, IconButton,
} from '@mui/material';
import { getParkingLot, postParkingLot, putParkingLot } from '../../../utils/ParkingService';
import { deleteLot } from '../../../utils/AdministrationService';
import { InputText } from '../../ui/inputs/text/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

class LotForm extends Component {
    constructor() {
        super()
        this.state = {
            lot: {
                id: '',
                name: '',
                territoryId: '',
                parkingLotId: ''
            },
            _lot: {
                id: '',
                name: '',
                territoryId: '',
                parkingLotId: ''
            },
            isNew: true,
            territory: {},
            open: false,
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        if (this.props.location.state) {

            const { lot, territory } = this.props.location.state;
            await this.setState({
                lot: { id: '', name: '', territoryId: territory.id }, territory: territory
            });
            if (lot) {
                this.setState({ isNew: false });
                const data = await getParkingLot(lot.id);

                this.setState({
                    lot:
                        data
                    ,
                    territory: territory,
                    _lot: data
                });
            } else {
                this.setState({ isNew: true });
            }
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.state.lot.id) {
            const resp = await postParkingLot(this.state.lot, this.state.territory)
            this.setState({_lot : this.state.lot});
            this.goBack();
        } else {
            const resp = await putParkingLot(this.state.lot, this.state.territory);
            this.setState({_lot : this.state.lot});
            this.goBack();
        }
    }

    handleChange = (event) => {
        this.setState({ lot: { ...this.state.lot, [event.target.id]: event.target.value } });
    }

    goBack = () => {
        if (this.state.isNew) {
            this.props.history.push({
                pathname: '/territory',
                state: { territory: this.state.territory, expanded: 'lots' }
            })
        } else {
            this.props.history.push({
                pathname: '/lot',
                state: { lot: this.state._lot, territory: this.state.territory }
            })
        }
    }

    delete = () => {
        this.setState({ open: true })
    }

    confirmDelete = async () => {
        const resp = await deleteLot(this.state.lot.id);
        this.goBack();
    }

    render() {
        const { t } = this.props
        const { lot } = this.state;
        const title = this.state.isNew ? t("territory.NewLot") : this.state.lot.name
        const handleClose = () => {
            this.setState({ open: false });
        };

        const handleYes = () => {
            this.confirmDelete();
            this.setState({ open: false });
        };
        return (
            <div>
                <Topbar title={t("general.ParkingLot")} cbBack={this.goBack}>

                </Topbar>
                <div className="territoryNewContainer">
                    <Grid  xs container direction='row' sx={{p: 2, py:1.5,  background:'#3A4D8F', border: '1px solid'}}>
                        <Grid item  xs >
                            <Typography variant='h3' color="#ffffff">
                                {this.state._lot.name}
                            </Typography>
                            <Typography variant ='h5' color ='#ffffff'>
                                {this.state.territory.name}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Box>
                                {/*<div className='pb-10'>*/}
                                {/*    <Button className="actionBtn" aria-label="Delete" onClick={() => onBookSpace(exchange, parkingSpace.name, parkingLotName)}>*/}
                                {/*        <Typography className="shareBtn-text">{changeTitle}</Typography>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}

                                <div className='pb-10'>
                                    <Button className="actionBtn" aria-label="Delete" onClick={this.delete}>
                                        <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                                    </Button>

                                </div>

                            </Box>
                        </Grid>
                    </Grid>
                    <form
                        onSubmit={this.handleSubmit}
                        noValidate
                    >
                        <SnackbarProvider>
                            <Container fixed className="adm_form-container">
                                <InputText
                                    type="text"
                                    id="name"
                                    onChange={this.handleChange}
                                    value={lot.name}
                                    label={t('administration.Name')}
                                    placeholder={t('administration.Name')}
                                />
                            </Container>
                        </SnackbarProvider>
                        <AppBar color='inherit' position="fixed" className="bottom-bar" elevation={0}>
                            <Toolbar>
                                <Grid
                                    spacing={3}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {/*<Grid item xs={6}>*/}
                                    {/*    <Fab className="full-width primary-btn" variant="extended" onClick={this.goBack} aria-label="Cancel">*/}
                                    {/*        <Box fontSize={14} textAlign="center">*/}
                                    {/*            {t('general.Cancel')}*/}
                                    {/*        </Box>*/}
                                    {/*    </Fab>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={10}>
                                        <Fab
                                            className="full-width secondary-btn" variant="extended"
                                            type="submit"
                                            value="Submit"
                                            aria-label="Save">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Save')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                    {/*<Grid item xs={6}>*/}
                                    {/*    <Fab className="full-width delete-btn" variant="extended" onClick={this.delete} aria-label="Delete">*/}
                                    {/*        <Box fontSize={14} textAlign="center">*/}
                                    {/*            {t('general.Delete')}*/}
                                    {/*        </Box>*/}
                                    {/*    </Fab>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </form>
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("confirm.Delete")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.lot.name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                        <Button onClick={handleYes} color="primary">
                            {t("general.Confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withTranslation()(LotForm)