import React from 'react';
import { styled, keyframes } from '@mui/material/styles';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled('div')(() => ({
  position: 'relative',
  width: '48px',
  height: '48px',
  '&:before, &:after': {
    content: '""',
    borderRadius: '50%',
    position: 'absolute',
    inset: 0,
    //boxShadow: '0 0 10px 2px #dbdfe6 inset',
  },
  '&:after': {
    boxShadow: '0 3px 0 #455d80 inset',
    animation: `${rotate} 2s linear infinite`,
  },
}));

const ModernLoader = () => {
  return <Loader />;
};

export default ModernLoader;
