const getImage = async (fileName) => {
  let result = null;
  try {
    let url = window.baseURL + 'Images/' + fileName;
    const response = await fetch(url)
      .catch((error) => console.error(error));

    if (response && response.ok && response.url) {
      result = response.url;
    }
  } catch (error) {
    console.error(error);
    //failed for unknown reasons
  }
  return result;
};

export { getImage };
