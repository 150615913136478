import React, { useState } from "react";
import "./Switch.scss";


const Switch = ({ disabled, isToggle, onToggle, name }) => {



    return (
        disabled ? (
            <label className="switch">
                <input type="checkbox" checked={isToggle} />
                <span className="sliderOff" />
            </label>
        ) : (
            <label className="switch">
                <input type="checkbox" checked={isToggle} onChange={onToggle} name={name} />
                <span className="slider" />
            </label>
        )
    );
};

export default Switch;