import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid, Typography, Box, Button, FormControl, TextField, InputAdornment, Divider, Card } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone } from '@fortawesome/free-solid-svg-icons';
import './Settings.scss';
import { putUser, getUser } from 'utils/UsersService';
import { NotificationManager } from 'react-notifications';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import PublicTopbar from '../PublicTopbar';
import { ReactComponent as PSpacerVectorLogo } from '../../../assets/icons/publicApp/logo_vector_p_spacer.svg';

class AccountDetails extends Component {
  constructor() {
    super();
    this.state = {
      user: {
        phone: '',
        name: '',
        iban: '',
      },
      showDeleteDrawer: false,
      showConfirmDelete: false,
      isUpdateButtonDisabled: true,
      isDeleteConfirmDisabled: false,
    };
  }

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, false);
    if (this.props.currentUser) {
      const userId = this.props.currentUser.id;
      getUser(userId)
        .then((user) => {
          if (user) {
            this.setState({ user: user });
          }
        })
        .catch((error) => {});
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isUpdateButtonDisabled: true });
    await putUser(this.state.user)
      .then(async (resp) => {
        if (resp) {
          await getUser(resp)
            .then((user) => {
              NotificationManager.success(this.props.t('notification.success.UpdateUser'));
              this.setState({ isUpdateButtonDisabled: true });
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isUpdateButtonDisabled: false });
      });
  };

  handleCard = (event) => {
    console.log(event);
    const { id, value } = event.target;

    switch (id) {
      case 'name':
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            [id]: value,
          },
          isUpdateButtonDisabled: false,
        }));
        break;

      case 'phone':
        // TODO: use react library https://www.npmjs.com/package/react-phone-number-input
        const phoneRegex = /^[0-9+()\-\s]*$/;
        if (phoneRegex.test(value)) {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              [id]: value,
            },
            isUpdateButtonDisabled: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  handleShowDeleteDrawer = () => {
    this.setState(() => ({ showDeleteDrawer: true }));
  };

  handleShowConfirmDeleteButton = () => {
    this.setState(() => ({ showConfirmDelete: true }));
  };

  handleDeleteAccount = async () => {
    const { deleteAccount } = this.props;
    this.setState(() => ({ isDeleteConfirmDisabled: true }));
    try {
      await deleteAccount(this.state.user.id);
      NotificationManager.success(this.props.t('notification.success.deleteUser'));
    } catch (error) {
      console.error(error);
      NotificationManager.error(this.props.t('notification.error.deleteUser'));
    }
    this.setState(() => ({ isDeleteConfirmDisabled: false }));
  };

  handleCancelAccountDelete = () => {
    this.setState(() => ({
      showDeleteDrawer: false,
      showConfirmDelete: false,
    }));
  };

  handleSignUp = () => {
    this.props.logoutGuest();
    window.location.reload(true);
  };

  render() {
    const { user, showDeleteDrawer, isUpdateButtonDisabled, showConfirmDelete, isDeleteConfirmDisabled } = this.state;
    const { t } = this.props;
    return (
      <div>
        <PublicTopbar title={t('publicApp.EditProfileDetails')} />

        {this.props.currentUser && (
          <>
            <form noValidate onSubmit={this.handleSubmit}>
              <Box sx={{ pt: 0.5, mx: 3 }}>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="name"
                      value={user.name}
                      onChange={this.handleCard}
                      className="input-account"
                      placeholder={t('publicApp.NameSurname')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ px: 1 }}>
                            <FontAwesomeIcon icon={faUser} size="1x" color="rgba(35, 49, 68, 0.25)" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ m: 3, mb: 7 }}>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="phone"
                      value={user.phone}
                      onChange={this.handleCard}
                      className="input-account"
                      placeholder={t('publicApp.Phone')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ px: 1 }}>
                            <FontAwesomeIcon icon={faPhone} size="1x" color="rgba(35, 49, 68, 0.25)" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ mb: 0, px: 3, pb: 3 }}>
                <Button id="update" disableElevation fullWidth size="large" type="submit" variant="contained" color="secondary" className="auth-button" disabled={isUpdateButtonDisabled}>
                  <Typography variant="string" className="button-text" sx={{ fontWeight: 400 }}>
                    {t('publicApp.Update')}
                  </Typography>
                </Button>
              </Box>
            </form>

            <Box>
              <Divider />
            </Box>

            <Card className="card-shadow" sx={{ mx: 1, my: 3 }}>
              <Grid container justifyContent="center">
                <Grid item xs={10} p={1} textAlign="center">
                  <Typography variant="body2" gutterBottom className="body2-text double-letter-spacing" sx={{ pt: 2 }}>
                    {t('publicApp.DeleteAccountWarningText')}
                  </Typography>
                </Grid>

                <Grid item xs={9} pt={1} pb={3}>
                  <Button id="btn-delete-account" className="button-warning-outlined" onClick={this.handleShowDeleteDrawer}>
                    <Typography variant="string" className="button-text">
                      {t('publicApp.DeleteAccount')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </>
        )}

        {this.props.currentUser ? (
          <PopUpDrawer showDrawer={showDeleteDrawer} isWarningMessage header={t('publicApp.DeleteAccount')} text={t('publicApp.AreSureDeleteAccount')} onClose={this.handleCancelAccountDelete}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={5} mt={2} pb={4}>
                <Button
                  id="cancel-delete-account"
                  disableElevation
                  type="button"
                  variant="contained"
                  className="button-secondary-outlined"
                  onClick={() => this.handleCancelAccountDelete()}
                  sx={{ height: '48px' }}
                >
                  <Typography variant="string" className="button-text">
                    {t('publicApp.CancelLogout')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={5} mt={2} pb={4}>
                {showConfirmDelete ? (
                  <Button
                    id="confirm-account-delete"
                    disableElevation
                    type="button"
                    className={isDeleteConfirmDisabled ? 'button-primary-disabled' : 'button-warning'}
                    onClick={() => this.handleDeleteAccount()}
                    sx={{ height: '48px' }}
                    disabled={isDeleteConfirmDisabled}
                  >
                    <Typography variant="string" className="button-text" sx={{ color: 'white' }}>
                      {t('general.Confirm')}
                    </Typography>
                  </Button>
                ) : (
                  <Button id="delete-account" disableElevation type="button" className="button-warning-outlined" onClick={() => this.handleShowConfirmDeleteButton()} sx={{ height: '48px' }}>
                    <Typography variant="string" className="button-text">
                      {t('general.Delete')}
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </PopUpDrawer>
        ) : (
          <>
            {/* Sign up suggestion screen for guests */}
            <Grid container direction="column" justifyContent="center" alignItems="center" height="80vh">
              <PSpacerVectorLogo className="login-head-image" alt="logo" />
              <Grid sx={{ mt: 7, mx: 5, textAlign: 'center' }}>
                <Typography variant="h5" className="h5-text">
                  {t('publicApp.PleaseSignUp')}
                </Typography>
              </Grid>
            </Grid>
            <Grid className="bottom-button" sx={{ position: 'absolute', pb: 4, width: '100%' }}>
              <Grid sx={{ mx: 3 }}>
                <Button id="sign-up" disableElevation type="button" className="button-primary" onClick={() => this.handleSignUp()}>
                  <Typography variant="string" className="button-text" sx={{ color: 'white' }}>
                    {t('publicApp.SignUp')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    );
  }
}

export default withSnackbar(withTranslation()(AccountDetails));
