const UserNotificationConfig = 'UserNotificationConfig';
const DeadlineTime = 'DeadlineTime';
const ReminderTime = 'ReminderTime';
const VehiclesLimit = 'VehiclesLimit';
const RequestToTrack = 'RequestToTrack';

export { UserNotificationConfig, DeadlineTime, ReminderTime, VehiclesLimit, RequestToTrack };

const UserNotificationConfig_PATH = '/settings/notification/form';
const DateTimeForm_PATH = '/settings/datetime/form';

export { UserNotificationConfig_PATH, DateTimeForm_PATH };

const DateTimeDisabled = '0001-01-01 00:00:00';

export { DateTimeDisabled };

const InputTypes = [
    { property: 'VehiclesLimit', type: 'Number', showValue: true },
    { property: 'UserNotificationConfig', type: 'NotificationConfig', showValue: false },
    { property: 'UserNotificationConfigViolation', type: 'UserNotificationConfigViolation', showValue: false },
    { property: 'UserNotificationConfigSilencePeriod', type: 'UserNotificationConfigSilencePeriod', showValue: false },
    { property: 'UserNotificationConfigBookedSpace', type: 'UserNotificationConfigBookedSpace', showValue: false },
    { property: 'UserNotificationConfigReminderNotification', type: 'UserNotificationConfigReminderNotification', showValue: false },
    { property: 'UserNotificationConfigAdminViolationReport', type: 'UserNotificationConfigAdminViolationReport', showValue: false },
    { property: 'DeadlineTime', type: 'DateTime', showValue: true },
    { property: 'ReminderTime', type: 'DateTime', showValue: true },
    { property: RequestToTrack, type: RequestToTrack, showValue: false},
];

const PropertySettings = [
    { property: 'VehiclesLimit', showType: 'Others'  },
    { property: 'UserNotificationConfig', showType: 'Notification' },
    { property: 'UserNotificationConfigViolation', showType: 'Notification' },
    { property: 'UserNotificationConfigSilencePeriod', showType: 'Notification' },
    { property: 'UserNotificationConfigBookedSpace', showType: 'Notification' },
    { property: 'UserNotificationConfigReminderNotification', showType: 'Notification' },
    { property: 'UserNotificationConfigAdminViolationReport', showType: 'Notification' },
    { property: 'DeadlineTime', showType: 'DateTime' },
    { property: 'ReminderTime', showType: 'DateTime'},
    { property: 'SendMonthlyFinanceReport', showType: 'Reports' },
    { property: 'SendMonthlyUserReport', showType: 'Reports'},
    { property: 'SendMonthlyViolationReport', showType: 'Reports'},
    { property: RequestToTrack, showType: RequestToTrack},
];

export { InputTypes };
