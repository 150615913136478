import React, { Component } from 'react';
import './SpacesOwnerList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import SpacesOwnerListItem from './item/SpacesOwnerListItem';
import { List, FormControlLabel, Grid } from '@mui/material';
import SwitchSmall from "../../ui/inputs/switchSmall/SwitchSmall";
//import { createTheme } from '@mui/material/styles';
import { createTheme } from '@material-ui/core';
import {  ThemeProvider } from "@material-ui/styles";

class SpacesOwnerList extends Component {
  state = {
    checked: false,
    spaces_: []
  }

  handleChange = async (event, listLimit) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked) {
      this.props.spaces.forEach((space, i) => {
        if (i > (listLimit - 1)) {
          setTimeout(() => {
            let newArr = this.state.spaces_;
            newArr.push(space);
            this.setState({ spaces_: newArr })
          }, i * 100);
        } else {
          let newArr = this.state.spaces_;
          newArr.push(space);
          this.setState({ spaces_: newArr })
        }
      })
    } else {
      this.setState({ spaces_: [] })
    }
  }

  render() {
    const { t, onCreate, spaces, spaceNames, hideOwnerIcon, listLimit, onReassign } = this.props;
    const { checked, spaces_ } = this.state;

    // const theme = createTheme({
    //   overrides: {
    //     MuiSwitch: {
    //
    //       switchBase: {
    //         color: "white !important",
    //       },
    //       colorPrimary: {
    //         "&$checked": {
    //           color: "white !important"
    //         }
    //       },
    //       track: {
    //         opacity: 0.2,
    //         backgroundColor: "#27348A",
    //         "$checked$checked + &": {
    //           opacity: 0.7,
    //           backgroundColor: "#27348A"
    //         }
    //       }
    //     }
    //   }
    // });

    return (
      <React.Fragment>
       {/*TODO t('exchanges.Change') nauja reiksme perskirti}*/}
        <Grid py={1}>
        </Grid>
        <ListHead title={t('spaces.Spaces')} onCreate={onCreate} onReassign={onReassign} onReassignTitle={'Keisti'} />
        {spaces && spaces.length > listLimit ?
          <React.Fragment>
            <Grid container direction="row" justifyContent="flex-end" >
              <Grid item style={{ paddingRight: '20px' }}>
                {/*<ThemeProvider theme={theme}>*/}
                {t("spaces.ShowAll") + ': ' + spaces.length}
                </Grid>
                <Grid item style={{ paddingRight: '10px', paddingTop:'5px' }}>
                  {<SwitchSmall checked={checked} onChange={(event) => this.handleChange(event, listLimit)} color="primary" />}
                </Grid>

                {/*{t("spaces.ShowAll") + ': ' + spaces.length}*/}
                {/*     {<SwitchSmall checked={checked} onChange={(event) => this.handleChange(event, listLimit)} color="primary" />}*/}


                {/*</ThemeProvider>*/}

            </Grid>
            {!checked && <List>
              {spaces && spaces.map((space, index) => ((index < listLimit) && (

                <SpacesOwnerListItem
                  key={index}
                  space={space}
                  spaceName={spaceNames ? spaceNames.find(x => x.id === space.id) : null}
                  showOwnerIcon={!hideOwnerIcon}
                  {...this.props}
                />

              )))}</List>}

            {checked &&
              <List>
                {spaces_ && spaces_.map((space, i) => (

                  <SpacesOwnerListItem
                    key={i}
                    space={space}
                    spaceName={spaceNames ? spaceNames.find(x => x.id === space.id) : null}
                    showOwnerIcon={!hideOwnerIcon}
                    {...this.props}
                  />

                ))}
              </List>}

          </React.Fragment>
          :
          <List>
            {spaces && spaces.map((space, i) => (
              <SpacesOwnerListItem
                key={i}
                space={space}
                spaceName={spaceNames ? spaceNames.find(x => x.id === space.id) : null}
                showOwnerIcon={!hideOwnerIcon}
                {...this.props}
              />
            ))}
          </List>
        }

      </React.Fragment>
    );

  }
}
export default withTranslation()(SpacesOwnerList);
