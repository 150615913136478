import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import FormValidation from '../../../../utils/FormValidation';
import { isValidToken, getLoginUiConfig } from '../../../../utils/AuthService';
import PublicLogin from '../public-auth/PublicLogin';
import './Login.scss';

class LoginMain extends Component {
  constructor() {
    super();
    this.state = {
      redirectToReferrer: false,
      email: '',
      magicLinksSent: false,
      loading: false,
      isValidToken: true,
      loginConfig: { enableSocialLogin: false, enableSignup: false },
      doneLoadingConfig: false,
    };
    this.validator = new FormValidation({
      locale: 'lt-LT',
      className: 'input-errormsg',
    });
  }

  login = async (token, e) => {
    if (e) e.preventDefault();

    await this.setState({ loading: true });
    try {
      if (token) {
        await this.props.login(this.props.history, null, token);
      } else {
        if (this.validator.allValid() && this.state.email) {
          const resp = await this.props.login(this.props.history, this.state.email);
          await this.setState({ magicLinksSent: resp ? true : false });
        } else {
          await this.props.login(this.props.history, this.state.email);
          if (e) {
            this.validator.showMessages();
            this.forceUpdate();
          }
        }
      }
    } catch (e) {
      // TODO: requires single place logger for non production environment
      // to display all errors
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
    }
    this.setState({ loading: false });
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  checkToken() {
    const sparams = window.location.search;
    if (sparams.startsWith('?token=')) {
      this.login(sparams.slice(7));
    } else {
      this.login();
    }
  }

  componentWillMount() {
    isValidToken().then((isValidToken) => {
      this.setState({ isValidToken });
    });

    getLoginUiConfig()
      .then((config) => this.setState({ loginConfig: config }))
      .then((ok) => this.setState({ doneLoadingConfig: true }));
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const qr = url.searchParams.get('qr');
    if (qr === 'true') {
      console.log(url);
      localStorage.removeItem('tenantId');
      if (!this.props.currentUser) {
        localStorage.setItem('isGuest', true);
      }
      localStorage.setItem('currentPath', '/publicParking');
      window.location = `${window.location.origin}/publicParking`;
    }

    this.checkToken();
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { redirectToReferrer, isValidToken } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    // Dont show login screen if this just users reload action
    if (isValidToken) return null;

    return <PublicLogin />;
  }
}
export default withTranslation()(LoginMain);
