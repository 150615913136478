import { axiosA } from './AuthService';

const getLocales = async () => {
  try {
    return await axiosA.get('Translations/locales');
  } catch (e) {
    throw new Error(e);
  }
};

const getTranslationsForEditing = async (localeKey) => {
  if (!localeKey) {
    localeKey = 'lt-LT';
  }
  try {
    return await axiosA.get(`Translations/forediting?Locale=${localeKey}`);
  } catch (e) {
    throw new Error(e);
  }
};

const putTranslations = async (translation) => {
  try {
    return await axiosA.put(`Translations/${translation.id}`, translation);
  } catch (e) {
    throw new Error(e);
  }
};

const getAvailableSites = async () => {
  let query = '10';
  try {
    return await axiosA.get(`AvailableSites`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserAvailableSites = async (user) =>{
  try {
    return await axiosA.get(`AvailableSites/user?userId=${user}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getByTenantId = async (tenantId) => {
	try {
		const data = await axiosA.get(`/AvailableSites/tenant/${tenantId}`)
    console.log('getByTenantId', data )
		return data
	} catch (e) {
    // TODO: not sure if this should throw
    console.error(e)
	}
}

export {
  getLocales,
  getTranslationsForEditing,
  putTranslations,
  getAvailableSites,
  getUserAvailableSites,
  getByTenantId
};
