import React, { useState, useEffect } from 'react';
import './Dialog.scss';
import { Dialog, Grid, Typography, Fab } from '@mui/material';
import trash from '../../../assets/images/trash.svg';
import { useTranslation } from 'react-i18next';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

const DeleteDialog = (props) => {
  const { openIt, onConfirm, onClose, text, isConfirmationDialog = false, isDefaultTextNeeded = true } = props;
  const defaultText = 'confirm.Delete';
  const { t } = useTranslation();
  const [open, setOpen] = useState(openIt);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose !== undefined) {
      onClose();
    }
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  useEffect(() => {setOpen(openIt)}, [openIt])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p: 3 }}
      >
        <Grid
          item
          xs={12}
          container
          paddingTop={3}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <img src={trash} width="50vh" alt="background" />
        </Grid>

        {typeof text === 'undefined' && (
          <Grid
            item
            xs={12}
            container
            paddingTop={4}
            paddingBottom={2}
            paddingLeft={2}
            paddingRight={2}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography className="titleRed" color="#FF8B79" align="center">
              {t(defaultText)}
            </Typography>
          </Grid>
        )}

        {typeof text !== 'undefined' && (
          <Grid
            item
            xs={12}
            container
            paddingTop={4}
            paddingBottom={0}
            paddingLeft={3}
            paddingRight={3}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography className="titleRed" color="#FF8B79" align="center">
              {t(text) + ' '}
              {isDefaultTextNeeded && t(defaultText)}
            </Typography>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          paddingTop={4}
        >
          <Grid item paddingLeft={-1}>
            <Fab
              id='btn-cancel'
              className=" blue-btn"
              variant="extended"
              aria-label="Save"
              size="small"
              onClick={handleClose}
            >
              <Typography padding={1}>{t('general.Cancel')}</Typography>
            </Fab>
          </Grid>
          <Grid item paddingLeft={2}>
            <Fab
              id='btn-confirm'
              className=" red-btn"
              variant="extended"
              aria-label="Save"
              size="small"
              onClick={handleConfirm}
            >
              <Typography padding={2}>{t(isConfirmationDialog ? 'general.Confirm' : 'general.Delete')}</Typography>
            </Fab>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
