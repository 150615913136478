import React, {Component} from 'react';
import  './Select.scss';
import {Box, Grid, InputLabel, Typography} from '@mui/material';
import Select from 'react-select';

export class InputSelect extends Component {

 colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#A1CAFF" : null,
        border: isFocused ? 0: 0,
        // This line disable the blue border
        borderColor: isFocused ? null : null,
        "&:hover": {
          border: isFocused ? 0 : 0
        },
        color: "#3B4754"
      };
    }
  };

  render() {
    const {label, validator, errorStyles} = this.props;
    return (
    <Box className='form-controll-select'>
      {label && <InputLabel
      className={`input-label`}
      >{label} </InputLabel>}
      <Grid item xs={12}  >
        <Select  {...this.props} z-index={2}
            background ='#ffffff'
                 styles={this.colourStyles}
          menuContainerStyle={{'zIndex': 999 }}

        />
      </Grid>

      <Typography className={`input-error select ${errorStyles}`}>{validator}</Typography>
    </Box>


 
    );
  }
}