import { axiosA } from './AuthService';

const getParkingRates = async () => {
  const { data } = await axiosA.get('/ParkingRates')
  return data
}

const getParkingRateById = async (Id) => {
  try {
    const { data } = await axiosA.get(`/ParkingRates/${Id}`)
    return data
  } catch (e) {
    throw new Error(e);
  }
}

const postParkingRates = async (parkingRates) => {
  try {
    const body = {
      title: parkingRates.title,
      rate: parkingRates.rate,
      id: parkingRates.id

    }
    const { data } = await axiosA.post('/ParkingRates', body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const putParkingRate = async (parkingRate) => {
  const body = {
    title: parkingRate.title,
    rate: parkingRate.rate,
    id: parkingRate.id
  }
  const { data } = await axiosA.put(`/ParkingRates/${parkingRate.id}`, body)
  return data
}
const deleteParkingRate = async (id) => {
  const {data} = await axiosA.delete(`/ParkingRates/${id}`)
  return data
}

export {
  getParkingRates,
  getParkingRateById,
  putParkingRate,
  postParkingRates,
    deleteParkingRate
}