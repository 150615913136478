import { axiosA } from './AuthService';

const getValidDates = async (params, action) => {
    try {
        const { data } = await axiosA.post(`/Calendar/${action}`, params)
        return data
    } catch (e) {
        return null
    }
}

export {
    getValidDates
}