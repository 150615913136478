import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/app';
import 'firebase/auth';

// action - state management
import { LOGIN, LOGOUT } from '../stores/actions';
import loginReducer from '../stores/loginReducer';
import * as jwtDecode from 'jwt-decode';
// project imports
import config from '../config';
import Api from '../constants/Api';
import { getTenantId, signin } from 'utils/AuthService';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  firebaseUser: null,
};

// ==============================|| User CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  let firebaseUser = undefined;

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        try {
          clearInterval(tokenRefreshInterval);
        } catch (e) {
          console.error(e);
        }
        if (user) {
          user
            .getIdToken()
            .then((token) => {
              localStorage.setItem('userSite', 'authUser');
              if (user.tenantId && !localStorage.getItem('privateTenantId')) {
                localStorage.setItem('tenantId', user.tenantId);
                localStorage.setItem('privateTenantId', user.tenantId);
              }
              localStorage.setItem(Api.ACCESS_KEY, token);
              const currentPath = localStorage.getItem('tenantId') ? '/dashboard' : localStorage.getItem('currentPath') ?? '/publicMap';
              localStorage.setItem('currentPath', currentPath);
            })
            .catch((e) => {
              console.error(e);
            });
          firebaseUser = user;

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              firebaseUser: {
                id: user.uid,
                email: user.email,
                name: user.displayName,
              },
            },
          });
        } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
            console.log('input email', email);
          }
          let params = new URLSearchParams(window.location.href);
          let tenantId = params.get('tenantId');
          if (tenantId) {
            firebase.auth().tenantId = tenantId;
          }
          console.log('should auth with email', email);
          firebase
            .auth()
            .signInWithEmailLink(email, window.location.href)
            .then((result) => {
              window.localStorage.removeItem('emailForSignIn');
              console.log('signed in with email', result);
            })
            .catch((error) => {
              alert(error);
              window.location.href = '/login';
            });
        } else if (localStorage.getItem('isGuest')) {
          localStorage.setItem('userSite', 'quest');
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              initialState: true,
              firebaseUser: null,
            },
          });
        } else {
          localStorage.clear();
          sessionStorage.clear();
          firebase.auth().tenantId = null;
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const checkTokenValidity = () => {
    const token = localStorage.getItem(Api.ACCESS_KEY);
    if (!token) {
      console.warn('no token nothing to refresh');
      return;
    }

    const info = jwtDecode(token);
    if (!info?.firebase) {
      console.log('token is not issued by firebase ignore');
      return;
    }

    const secondsLeft = info.exp - parseInt(Date.now() / 1000) - 180; //180 chosen as a middle value to use the firebase auth token 5 min refreshing period before it actually expires
    if (secondsLeft < 180) {
      console.log('token will expire in ' + secondsLeft + ' seconds -> refreshing');
      refreshToken();
    }
  };

  const tokenRefreshInterval = firebase.auth().currentUser ? setInterval(checkTokenValidity, 60000) : null;

  const refreshToken = () => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error('unable to refresh user null');
      user = firebaseUser;
      if (!user) {
        console.error('unable to refresh user not cached');
        return;
      }
    }
    user
      .getIdToken(true)
      .then((token) => {
        console.log('token', 'new token acquired', token);
        localStorage.setItem(Api.ACCESS_KEY, token);
      })
      .catch((e) => {console.error(e)});
  };

  const firebaseEmailPasswordSignIn = (email, password) => {
    try {
      const res = firebase.auth().signInWithEmailAndPassword(email, password);
      firebase.auth().user = res.user;
      localStorage.setItem('emailForSignIn', email);
    } catch (err) {
      console.log(err.message);
    }
  };

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const firebaseEmailLink = async (email) => {
    const { tenantId } = await getTenantId(email);
    if (tenantId) {
      firebase.auth().tenantId = tenantId;
      localStorage.setItem('tenantId', tenantId);
    } else {
      localStorage.removeItem('tenantId');
    }
    localStorage.setItem('userSite', 'signIn');

    const host = window.location.href;
    const actionCodeSettings = {
      url: `${host}`,
      handleCodeInApp: true,
    };

    const signInBody = {
      Email: email,
      ActionCodeSettings: actionCodeSettings,
    };

    return await signin(signInBody);
  };

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return null;
  }

  const joinAsGuest = async () => {
    localStorage.setItem('isGuest', true);
    window.location = `${window.location.origin}/publicMap`;
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailLink,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
        resetPassword,
        updateProfile,
        refreshToken,
        joinAsGuest,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
