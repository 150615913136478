import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './SpaceForm.scss';
import { withSnackbar, SnackbarProvider } from 'notistack';
import {
    Container,
    AppBar,
    Fab,
    Grid,
    Toolbar,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Button
} from '@mui/material';
import { getParkingSpace } from '../../../utils/ParkingService';
import { deleteSpace, postParkingSpace, putParkingSpace } from '../../../utils/AdministrationService';
import { InputText } from '../../ui/inputs/text/Text';

class SpaceForm extends Component {
    constructor() {
        super()
        this.state = {
            space: {
                id: '',
                name: '',
                parkingLotId: '',
                ownerId: '',
                lotPlaceId: '',

            },
            isNew: true,
            lot: {},
            territory: {},
            open: false,
            _space: {}
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        if (this.props.location.state) {

            const { space, lot, territory } = this.props.location.state;
            await this.setState({
                space: { id: '', name: '', parkingLotId: lot.id }, lot: lot, territory: territory
            });
            if (space) {
                this.setState({ isNew: false });
                const data = await getParkingSpace(space.id);
                this.setState({
                    space: data,
                    lot: lot,
                    territory: territory,
                    _space: data
                });
            } else {
                this.setState({ isNew: true });
            }
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.isNew) {
            const resp = await postParkingSpace(this.state.space, this.state.territory)
            this.setState({space: this.state.space});
            this.goBack();
        } else {
            const resp = await putParkingSpace(this.state.space, this.state.territory);
            this.setState({space: this.state.space});
            this.goBack();
        }
    }

    handleChange = (event) => {
        this.setState({ space: { ...this.state.space, [event.target.id]: event.target.value } });
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/lot',
            state: { territory: this.state.territory, expanded: 'spaces', lot: this.state.lot }
        })
    }

    delete = () => {
        this.setState({ open: true })
    }

    confirmDelete = async () => {
        const resp = await deleteSpace(this.state.space.id);
        this.goBack();
    }

    render() {
        const { t } = this.props
        const { space } = this.state;
        const handleClose = () => {
            this.setState({ open: false });
        };

        const handleYes = () => {
            this.confirmDelete();
            this.setState({ open: false });
        };
        return (
            <div>
                <Topbar title={t("spaces.Spaces")} cbBack={this.goBack} >

                </Topbar>
                <div className="territoryNewContainer">
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="SpaceBlock"
                    >
                        <Typography className="SpaceName">
                            {this.state._space.name}
                        </Typography>
                        <Typography className="LotName">
                            {this.state.lot.name}
                        </Typography>
                        <Typography className="LotName">
                            {this.state.territory.name}
                        </Typography>
                    </Grid>
                    <form
                        onSubmit={this.handleSubmit}
                        noValidate
                    >
                        <SnackbarProvider>
                            <Container fixed className="adm_form-container">
                                <InputText
                                    type="text"
                                    id="name"
                                    onChange={this.handleChange}
                                    value={space.name}
                                    label={t('administration.Name')}
                                    placeholder={t('administration.Name')}
                                />
                            </Container>
                        </SnackbarProvider>
                        <AppBar color='inherit' position="fixed" className="bottom-bar" elevant = {0}>
                            <Toolbar>
                                <Grid
                                    spacing={3}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >

                                    <Grid item xs={10}>
                                        <Fab
                                            className="full-width secondary-btn" variant="extended"
                                            type="submit"
                                            value="Submit"
                                            aria-label="Save">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Save')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                    {/* {this.state.isNew ? null :
                                        <Grid item xs={6}>
                                            <Fab className="full-width delete-btn" variant="extended" onClick={this.delete} aria-label="Delete">
                                                <Box fontSize={14} textAlign="center">
                                                    {t('general.Delete')}
                                                </Box>
                                            </Fab>
                                        </Grid>
                                    } */}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </form>
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("confirm.Delete")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.space.name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                        <Button onClick={handleYes} color="primary">
                            {t("general.Confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withTranslation()(SpaceForm)