import React, { Component } from 'react';
import './ConsentForm.scss';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import {
    Fab,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    AppBar,
    Toolbar,
    Grid,
    DialogTitle,
    DialogContentText,
    Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faSave, faBackspace } from '@fortawesome/free-solid-svg-icons';
import { postConsentVersion, getActualVersion } from '../../../utils/ConsentService';
import renderHTML from 'react-render-html';
import MomentUtils from '@date-io/moment';
const moment = new MomentUtils({locale: 'lt', });

class ConsentForm extends Component {
    constructor() {
        super();
        this.state = {
            htmlContent: '',
            open: false,
            selectFile: null,
            openConfirm: false,
            created: null,
            creator: null,
            openClear: false,
            contentChanged: false
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        await this.fetchVersion();
    }

    fetchVersion = async() =>{
        const version = await getActualVersion();
        if (version) {
            const date = moment.date(new Date(version.createdDateTime)).utc(0);
            const created = date.format('YYYY-MM-DD HH:mm').toString();
            await this.setState({ htmlContent: version.content, created: created, creator: version.creator });
        }
    }

    showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            this.setState({ open: false, htmlContent: text, contentChanged: true });
        };
        reader.readAsText(e.target.files[0])
    }

    save = () => {
        this.setState({ openConfirm: true });
    }

    clear = () => {
        this.setState({ openClear: true });
    }

    confirmSave = async () => {
        const { data } = await postConsentVersion(this.state.htmlContent);
        await this.fetchVersion();
        this.setState({ contentChanged: false });
    }

    render() {
        const { t } = this.props;
        const { htmlContent, created, creator, contentChanged } = this.state;

        const title = this.state.selectFile == null ? t("Administration.SelectFile") : this.state.selectFile;

        const handleClose = () => {
            this.setState({ open: false, openConfirm: false, openClear: false });
        };

        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        const handleYes = async () => {
            await this.confirmSave();
            this.setState({ openConfirm: false });
        };

        const handleClear = async () => {
            await this.fetchVersion();
            this.setState({ openClear: false });
        };

        return (
            <div >
                <Topbar title={t("consent.Edit")} cbEdit={handleClickOpen}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >

                    </Grid>
                </Topbar>
                {/* <div className="html_container" dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
                <div className="html_container">
                    <Grid  xs container direction='row' sx={{p: 2.5, py:1.5,  background:'#3A4D8F', border: '1px solid'}} >
                        <Grid item  xs >
                            {creator &&<Typography variant = 'h4' color ='#ffffff'>
                                {creator}
                            </Typography>}
                            {created &&  <Typography variant = 'h5' color ='#ffffff'>
                            {created}
                            </Typography>}
                        </Grid>
                    </Grid>
<Grid paddingTop={2.5} sx={{p: 2}}>  {renderHTML(htmlContent)}</Grid>

                </div>
                {contentChanged &&   <AppBar color='inherit' position="fixed" className="bottom-bar" elevation={0}>
                    <Toolbar>
                        <Grid
                            spacing={1}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={10}>
                                <Fab
                                  className="full-width secondary-btn" variant="extended"
                                  onClick={this.save} disabled={!contentChanged}>
                                    <Box fontSize={14} textAlign="center">
                                        {t('general.Save')}
                                    </Box>
                                </Fab>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Fab className="primary-btn" onClick={this.clear} >*/}
                            {/*        <FontAwesomeIcon icon={faBackspace} />*/}
                            {/*    </Fab>*/}
                            {/*</Grid>*/}
                            {/*<Grid item>*/}
                            {/*    <Fab onClick={handleClickOpen}>*/}
                            {/*        <FontAwesomeIcon icon={faFileDownload} />*/}
                            {/*    </Fab>*/}
                            {/*</Grid>*/}
                            {/*<Grid item >*/}
                            {/*    <Fab className="secondary-btn" onClick={this.save} disabled={!contentChanged}>*/}
                            {/*        <FontAwesomeIcon icon={faSave} />*/}
                            {/*    </Fab>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Toolbar>
                </AppBar>}
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("consent.LoadHtmlText")}
                    </DialogTitle>
                    <DialogContent>
                        <input type="file" onChange={(e) => this.showFile(e)} />
                        <DialogContentText>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openConfirm}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("consent.SaveNew?")}
                    </DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                        <Button onClick={handleYes} color="primary">
                            {t("general.Confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openClear}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("consent.ClearChanges?")}
                    </DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                        <Button onClick={handleClear} color="primary">
                            {t("general.Confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withTranslation()(ConsentForm);