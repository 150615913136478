import './SettingsList.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Typography,
    Grid,
} from '@mui/material';
import NotificationSettingsForm from './notification-settings-form/NotificationSettingsForm';
import DateTimeSettingForm from './datetime-setting-form/DateTimeSettingForm';
import { useSnackbar } from 'notistack';
import SettingForm from './setting-form/SettingForm';

export default function SettingsItem({
    globalSetting,
    item,
    itemType,
    setting,
    setSettingItem,
    handleYes,
    clearEnabled,
    onSaveNotification,
    onSaveDate,
    onSaveDefault,
    isAdmin,
    isShow,
    autoMatch,
    setBookingTime
}) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [settingType, setSettingType] = useState(null);

    const initNotificationConfig = () => {
        if (setting && setting.value) {
            return JSON.parse(setting.value);
        }
        return { NotifyEmail: true, NotifyPush: false };
    };

    const [notificationConfig, setNotificationConfig] = useState(
        initNotificationConfig()
    );

    const renderInput = () => {
        switch (itemType) {
            case 'NotificationConfig':
            case 'UserNotificationConfigViolation':
            case 'UserNotificationConfigSilencePeriod':
            case 'UserNotificationConfigBookedSpace':
            case 'UserNotificationConfigReminderNotification':
            case 'UserNotificationConfigAdminViolationReport': {
                return (
                    <NotificationSettingsForm
                        isAdmin={isAdmin}
                        globalSetting={globalSetting}
                        setting={item}
                        onUpdateSettingItem={setSettingItem}
                        onHandleYes={handleYes}
                        onSaveNotification={onSaveNotification}
                    ></NotificationSettingsForm>
                )
            }
            case 'DateTime': {
                return (
                    <DateTimeSettingForm
                        clearEnabledPrp={clearEnabled}
                        setting={item}
                        onUpdateSettingItem={setSettingItem}
                        onSaveDate={onSaveDate}
                        isAdmin={isAdmin}
                        autoMatch={autoMatch}
                    ></DateTimeSettingForm>
                );
            }
            case 'Number': {
                return (item &&
                    <SettingForm
                        setting={item}
                        type={settingType}
                        onUpdateSettingItem={setSettingItem}
                        onSaveDefault={onSaveDefault}
                    ></SettingForm>
                );
            }
            default:
                return null
        }
    };

    return (
        <React.Fragment>
            <Grid container  >
                <Grid item xs >
                    {(item.property === 'DeadlineTime' && setBookingTime) ? (<Typography className='h6 weight600' color='#3A4D8F'>
                        {`${t('SettingsItem.WithdrawalTimeLimit')} `}
                        {/*{`Vietos atsisakymo laiko riba `}*/}
                    </Typography>) :
                        (<Typography className='h6 weight600' color='#3A4D8F'>
                            {`${t('settings.' + item.property)} `}
                        </Typography>)}
                </Grid>
                <Grid item  >
                    {renderInput()}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
