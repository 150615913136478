import { axiosA } from './AuthService';

const getTerritories = async () => {
	const { data } = await axiosA.get('/Territories')
	return data
}

const getRemoteLots = async (territoryId) => {
	try {
		const { data } = await axiosA.get(`/Territories/remoteLots/${territoryId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getLots = async (territoryId) => {
	try {
		const { data } = await axiosA.get(`/Territories/lots/${territoryId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getUsers = async (territoryId) => {
	try {
		const { data } = await axiosA.get(`/Territories/users/${territoryId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getSpacesByLotId = async (lotId) => {
	try {
		const { data } = await axiosA.get(`/Territories/lot/spaces/${lotId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}

const getTerritoryById = async (territoryId) => {
	try {
		const { data } = await axiosA.get(`/Territories/${territoryId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}

}

const postTerritory = async (territory) => {
	try {
		const body = {
			name: territory.name,
			url: territory.url,
			clientName: territory.clientName,
			clientPassword: territory.clientPassword
		}
		const { data } = await axiosA.post('/Territories', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const putTerritory = async (territory) => {
	const body = {
		name: territory.name,
		parkingRateId: territory.parkingRateId,
		url: territory.url,
		clientName: territory.clientName,
		clientPassword: territory.clientPassword
	}
	const { data } = await axiosA.put(`/Territories/${territory.id}`, body)
	return data
}

const getLocalLots = async (territoryId) => {
	try {
		const { data } = await axiosA.get(`/Territories/localLots/${territoryId}`)
		return data
	} catch (e) {
		throw new Error(e);
	}
}


export {
	getTerritories,
	getLots,
	getUsers,
	getSpacesByLotId,
	getTerritoryById,
	postTerritory,
	putTerritory,
	getRemoteLots,
	getLocalLots
}