import React, {Component} from 'react';
import './SpaceDetails.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { Grid, Box, Typography, Dialog, List } from '@mui/material';
import UsersList from '../../users/list/UsersList';
import UsersListItem from '../../users/list/item/UserListItem';

import { getUser, getUsers } from '../../../utils/UsersService';
import { withSnackbar } from 'notistack';
import ExchangesList from '../../exchanges/list/ExchangesList';
import { getSharedSpaces, setParkingOwner, getParkingSpace, deleteShareSpace } from '../../../utils/ParkingService';
import { InputSearch } from 'components/ui/inputs/search/Search';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";

const searchAPIDebounced = AwesomeDebouncePromise(getUsers, Api.SEARCH_DEBOUNCE);

class SpaceDetails extends Component {
  state = {
    openedUsersDialog: false,
    query: '',
    space: {
      name: null,
      parkingLotName: null,
    },
    // sharedSpaces: [],
    owners: [],
    users: [],
    spaceNames: null
  }
  newSpace = () => {
    this.props.history.push({
      pathname: '/spaces/assign',
      state: {
        userId: this.state.user.id
      }
    })
  } 
  fetchOwner = async () => {
    try {
      if(this.state.space.isOwned) {
        const resp = await getUser(this.state.space.ownerId)
        this.setState({owners: [resp]})
      }
    }catch(e) {
      console.error(e);
    }
  }
  goToProfile = (user) => {
    this.props.history.push({
      pathname: '/user/',
      state: {user}
    })
  }

  fetchSharedSpaces = async () => {
    try {
      const {items} = await getSharedSpaces(this.state.space.id, true)
      await this.setState({sharedSpaces: items, sharedDates: items.map(e => {
       return {from: e.sharedFrom, to: e.sharedTo}})}
      )
    }catch(e) {
      console.error(e);
    }
  }
  deleteSharedSpace = async (id) => {
    const {affectedIds} =  await deleteShareSpace(id)
    if(affectedIds && affectedIds.length) {
      const sharedSpaces = this.state.sharedSpaces.filter( e => !affectedIds.includes(e.id))
      await this.setState({sharedSpaces})
      this.props.enqueueSnackbar(this.props.t('notification.success.DeleteSharedSpace'), {
        variant: 'success',
      });
    }
  }

  exchangeSpace = () => {
    this.props.history.push({
      pathname: '/exchanges/new',
      state: {
        space: this.state.space,
        sharedDates: this.state.sharedDates, 
        user: {...this.state.owners[0]}
      }
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }
  handleClose = () => {
    this.setState({openedUsersDialog: false})
  }
  createUser = () => {
    this.props.history.push({
      pathname: '/user/-1/edit',
      state: {
        space: this.state.space,
      }
    })
  }
  changeSpaceOwner = async () => {
    await this.fetchUsers()
    await this.setState({openedUsersDialog: true})
  }
  changeOwner = async (user) => {
    try {
      const resp  =  await setParkingOwner(this.state.space, user.id)
      if(resp) {
        this.props.enqueueSnackbar(this.props.t('notification.success.AssignSpace'), {
          variant: 'success',
        });
      }
      this.setState({user, owners: [user], openedUsersDialog: false})
    }catch(e) {
     console.error(e) 
    }
  }

  fetchUsers = async (query, offset, loadingMore) => {
    try {

      let hasMore = false;

      const resp = await searchAPIDebounced(query, offset, -1);
      if(resp.nextOffset) hasMore = true
      if(!loadingMore) {
        this.setState(prevState => ({users: [...resp.items], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore}))
      }else {
        this.setState(prevState => ({users: [...new Set([...prevState.users, ...resp.items])], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore}))
      }

    }
      catch (e) {
      console.error(e)
    }
  }
  search = (e) => {
    // pakeisti i debounce o state po atsakymo
    this.fetchUsers(e.target.value)
  }
  
  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    const state = this.props.location.state;
    let {space, createUserSucces, owner, spaceNames} = state || {};
    
    if(!space) {
    space =  await getParkingSpace(this.props.match.params.id)
    }
    // console.log(space);
    await this.setState({space: {...space}})
    await this.fetchSharedSpaces()
    if(!owner) await this.fetchOwner()
    if(createUserSucces) {
      await this.setState({owners: [owner]})
      await this.props.enqueueSnackbar(this.props.t('notification.success.AssignSpace'), {
        variant: 'success',
      });
    }
    if(spaceNames) this.setState({spaceNames});
  }

  render() {
    const {
      owners,
      users,
      space, 
      sharedSpaces, 
      openedUsersDialog,
      hasMore,
      spaceNames
    } = this.state;
    const {t, isAdmin} = this.props;
    // console.log(owners);
    return (
      <React.Fragment>
        <Dialog fullScreen open={openedUsersDialog} onClose={this.handleClose} >
        <React.Fragment>
        <Topbar
            title={t("users.Title")}
            cbCreate={false}
            closeDialog={this.handleClose}
          >
          <InputSearch onChange={this.search} placeholder={t("general.Search")} />
          </Topbar>
          <List className="UsersList" >
          <InfiniteScroll
          dataLength={users.length}
          hasMore={(hasMore)}
          next={()=> this.fetchUsers(this.state.query, this.state.nextOffset, true)}
          loader={
          <p>{t('general.loading')}...</p>
          }
          // endMessage={
          //   users.length > 0 &&
          //   <p style={{ textAlign: "center" }}>
          //     <b>{t('general.Listend')}</b>
          //   </p>
          // }
        >
          {users.length > 0 &&
          users.map((e, i) => ( 
            <UsersListItem
              user={e}
              key={(e.id+i)}
              onSelect={this.changeOwner}
              onMainClick={this.changeOwner}
            />
          ))}
          </InfiniteScroll>
        </List>
        </React.Fragment>

      </Dialog>
        <Topbar
          title={t("spaces.Space")}
          cbBack={this.goBack}
          location={this.props.location}
        >
        </Topbar>
          <Box
          className="container-list"
          >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                paddingBottom = "20px">
            <Typography className="SpaceName">
              {space.name}
            </Typography>
            <Typography className="LotName">
              {space.parkingLotName}
            </Typography>
          </Grid>
            <UsersList users={owners} onMainClick={this.changeSpaceOwner}  onCreate={this.changeSpaceOwner} />
          </Box>
          <Box
          className="container-list"
          >
          <ExchangesList 
            exchanges={sharedSpaces}
            canGoBack={true}
            isAdmin={isAdmin}
            onDelete={this.deleteSharedSpace}
            onCreate={space.isOwned && this.exchangeSpace}
            spaceNames={spaceNames}
          />
          </Box>
      </React.Fragment>

    );
  }
}
export default withSnackbar(withTranslation()(SpaceDetails))