import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Grid, Typography, ButtonBase, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getUser } from 'utils/UsersService';
import { ReactComponent as PersonIcon } from '../../../assets/icons/publicApp/icon_person.svg';
import { ReactComponent as ProfileDetailsIcon } from '../../../assets/icons/publicApp/icon_profile_details.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/publicApp/icon_card.svg';
import { ReactComponent as CarIcon } from '../../../assets/icons/publicApp/icon_car.svg';
import { ReactComponent as LanguageIcon } from '../../../assets/icons/publicApp/icon_lang.svg';
import { ReactComponent as PSpacerLogo } from '../../../assets/icons/publicApp/logo_p_spacer.svg';
import { ReactComponent as SignOutIcon } from '../../../assets/icons/publicApp/icon_sign_out.svg';
import { ReactComponent as SwitchIcon } from '../../../assets/icons/publicApp/icon_switch_horizontal.svg';
import { PublicFooter } from 'components/public/PublicFooter';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import '../PublicMap.scss';
import './Settings.scss';

class PublicSettings extends Component {
  state = {
    user: null,
    mounted: false,
    showLogoutDrawer: false,
    privateTenantId: null,
  };

  listItems = [
    {
      id: 'edit-details',
      img: <ProfileDetailsIcon style={{ width: '100%', height: '100%' }} />,
      path: '/accountDetails',
      header: 'publicApp.EditDetails',
      isVisibleForGuest: true,
    },
    {
      id: 'payment',
      img: <CardIcon style={{ width: '100%', height: '100%' }} />,
      path: '/accountPayments',
      header: 'publicApp.AddAPaymentMethod',
      isVisibleForGuest: false,
    },
    {
      id: 'vehicles',
      img: <CarIcon style={{ width: '100%', height: '100%' }} />,
      path: '/accountVehicles',
      header: 'publicApp.Vehicles',
      isVisibleForGuest: true,
    },
    {
      id: 'language',
      img: <LanguageIcon style={{ width: '100%', height: '100%' }} />,
      path: '/accountLanguages',
      header: 'publicApp.Language',
      isVisibleForGuest: true,
    },
  ];

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, false);
    if (this.props.currentUser) {
      const userId = this.props.currentUser.id;
      await getUser(userId)
        .then((user) => {
          if (user) {
            this.setState({ user: user });
          }
        })
        .catch((error) => {});
    }
    this.setState({ mounted: true });
    const privateTenantId = localStorage.getItem('privateTenantId');
    if (privateTenantId) {
      this.setState({ privateTenantId });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goAccountSettings = (pathname) => {
    this.props.history.replace({ ...this.props.history.location, state: null });
    this.props.history.push({
      pathname: pathname,
    });
  };

  goPublic = (pathname) => {
    this.props.history.push({
      pathname: pathname,
    });
  };

  goBusiness = async () => {
    sessionStorage.clear();
    localStorage.setItem('tenantId', this.state.privateTenantId);
    localStorage.setItem('userSite', this.state.privateTenantId);
    this.props.login(this.props.history).then(() => {
      this.props.history.push({
        pathname: '/dashboard',
      });
    });
  };

  handleCancelLogout = () => {
    this.setState(() => ({
      showLogoutDrawer: false,
    }));
  };

  handleLogoutButton = () => {
    this.setState(() => ({ showLogoutDrawer: true }));
  };

  render() {
    const { t, logout, location } = this.props;
    const { showLogoutDrawer, privateTenantId } = this.state;
    const showInitAnimation = location.state ? location.state.showInitAnimation : null;
    return (
      <>
        <Box className={`header-blue ${showInitAnimation ? 'slide-down' : ''}`}>
          <Grid container alignItems="center" justifyContent="center" className="map-header-blue-sparkle" sx={{ height: '200px' }}>
            {
              <>
                {this.props.currentUser ? (
                  <Grid container>
                    <Grid item xs={4.5} sx={{ mr: 3 }}>
                      <Box sx={{ textAlign: 'right', pt: 0.5 }}>
                        <PersonIcon
                          style={{
                            width: 65,
                            filter: 'drop-shadow(0px 4px 8px rgba(35, 49, 68, 0.75))',
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={6.5}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        mb={0.25}
                        variant="string"
                        sx={{
                          letterSpacing: '0.045rem',
                          fontSize: '1.125rem',
                          fontWeight: 500,
                          color: 'white',
                        }}
                      >
                        {this.props.currentUser.name}
                      </Typography>
                      <Typography
                        mb={0.25}
                        variant="string"
                        sx={{
                          letterSpacing: '0.045rem',
                          fontSize: '0.85rem',
                          fontWeight: 300,
                          color: 'white',
                        }}
                      >
                        {this.props.currentUser.email}
                      </Typography>
                      <Typography
                        variant="string"
                        sx={{
                          letterSpacing: '0.045rem',
                          fontSize: '0.85rem',
                          fontWeight: 300,
                          color: 'white',
                        }}
                      >
                        {this.props.currentUser.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="center">
                    <PSpacerLogo />
                  </Grid>
                )}
              </>
            }
          </Grid>
        </Box>

        <Grid m={1} mt={3}>
          {this.listItems.map((item) =>
            this.props.currentUser || item.isVisibleForGuest ? (
              <Box width="100%" key={item.id} className={`${showInitAnimation ? 'slide-fade-in-up' : ''}`}>
                <ButtonBase
                  id={item.id}
                  sx={{
                    p: 1,
                    width: '100%',
                    height: '3rem',
                    borderRadius: '12px',
                  }}
                  onClick={() => this.goAccountSettings(item.path)}
                >
                  <Box width="100%">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item display="flex" justifyContent="center" xs={2}>
                        <Grid color="#455D80" sx={{ width: '1.625rem', height: '1.625rem' }}>
                          {item.img}
                        </Grid>
                      </Grid>
                      <Grid item xs={9} textAlign="left">
                        <Typography variant="h4" className="h4-text double-letter-spacing">
                          {t(item.header)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Box textAlign="left" fontSize="0.85rem">
                          <FontAwesomeIcon icon={faChevronRight} size="1x" color="#111827" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </ButtonBase>
              </Box>
            ) : null
          )}
          {this.props.currentUser && (
            <Grid container justifyContent="center" className="sign-out-card">
              <Grid item xs={10} sx={{ py: 3.5 }}>
                {privateTenantId && (
                  <Button sx={{ marginBottom: '1rem' }} id="btn-business-account" className="button-primary-outlined fade-in" onClick={this.goBusiness}>
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={1.5} sx={{ pl: 1.75, display: 'flex', alignItems: 'center' }}>
                        <SwitchIcon fill="#3268B2" height="22px" width="22px" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="string" className="button-text blue-text double-letter-spacing">
                          {t('publicApp.BusinessAccount')}
                        </Typography>
                      </Grid>
                      <Grid item xs={1.5}></Grid>
                    </Grid>
                  </Button>
                )}
                <Button id="btn-logout" className={`button-primary-outlined ${showInitAnimation ? 'fade-in' : ''}`} onClick={this.handleLogoutButton}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={1.5} sx={{ pl: 1.75, display: 'flex', alignItems: 'center' }}>
                      <SignOutIcon />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="string" className="button-text blue-text double-letter-spacing">
                        {t('publicApp.Logout')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}></Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <PublicFooter />

        <PopUpDrawer showDrawer={showLogoutDrawer} header={t('publicApp.Logout')} text={t('publicApp.AreSureLogout')} onClose={() => this.handleCancelLogout()} onOpen={() => {}}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={5} mt={2} mb={4}>
              <Button id="cancel-logout" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={() => this.handleCancelLogout()} sx={{ height: '48px' }}>
                <Typography variant="string" className="button-text">
                  {t('publicApp.CancelLogout')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={5} mt={2} mb={4}>
              <Button id="confirm-logout" disableElevation type="button" variant="contained" color="secondary" className="button-primary" onClick={logout}>
                <Typography variant="string" className="button-text">
                  {t('general.Confirm')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </PopUpDrawer>
      </>
    );
  }
}
export default withSnackbar(withTranslation()(PublicSettings));
