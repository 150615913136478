import { axiosA } from './AuthService';

const getConsent = async () => {
    const { data } = await axiosA.get('/Consent');
    return data
}

const setConsent = async () => {
    try {
        const { data } = await axiosA.post('/Consent');
        return data
    }
    catch (e) {
        throw new Error(e)
    }
}

const postConsentVersion= async (content) => {
	try{
        const body = {
			content: content,
		}
		const {data} = await axiosA.post('/Consent/content', body)
		return data
	}catch (e) {
		throw new Error(e)
	}
}

const getActualVersion = async () => {
    const { data } = await axiosA.get('/Consent/content');
    return data
}

export { getConsent, setConsent, postConsentVersion, getActualVersion }