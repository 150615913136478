import { axiosA } from '../../AuthService';
import querystring from 'querystring';
import Axios from 'axios';

const tokenize = async (card, token, payment, lot) => {
    try {
        const extraConfig = await getEServiceConfig();

        const params = {
            merchantId: extraConfig.data.merchantId,
            allowOriginUrl: window.location.origin,
            token: token,
            number: card.cardNumber.replace(/\s/g, ""),
            nameOnCard: card.cardName,
            expiryMonth: card.expiryMonth,
            expiryYear: '20' + card.expiryYear,
            cardCode: card.cvv,
        }

        console.log(querystring.stringify(params))

        const tokenResponse = await Axios.post(extraConfig.data.paymentOperationActionUrl, querystring.stringify(params)
        ).
            catch(function (error) {
                console.log(error);
                //
            });

        console.log(tokenResponse);
        if (tokenResponse.data.result === 'success') {
            const redirectResponse = await transactionSession(tokenResponse.data, card.cvv, payment, lot).
                catch(function (error) {
                    console.log(error);
                });

            console.log(redirectResponse);
            return redirectResponse;
        }

    } catch (e) {
        throw new Error(e)
    }
}

const transactionSession = async (cardToken, cvv, payment, lot) => {
    try {
        const browserId = await getIPv4();
        const d = new Date();
        let diff = d.getTimezoneOffset();

        const customerBrowser = {
            browserAcceptHeader: "application/json",
            browserJavaEnabled: false,
            browserJavascriptEnabled: true,
            browserLanguage: getISOLang(),
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserScreenWidth: window.screen.width.toString(),
            browserTZ: diff.toString(),
            challengeWindowSize: "05" // Values accepted:  01 – 250 x 400  02 – 390 x 400  03 – 500 x 600  04 – 600 x 400  05 – Full screen
        };

        const body = {
            redirect: redirect(),
            plateNum: localStorage.getItem('selectedVehicle'),
            notificationUrl: notificationUrl(),
            customerIPAddress: browserId,
            customerBrowser: JSON.stringify(customerBrowser),
            userAgent: window.navigator.userAgent,
            cardToken: cardToken.cardToken,
            customerId: cardToken.customerId,
            cvv: cvv,
            amount: payment.amount,
            currency: lot.currencyCode,
            parkingLogId: payment.parkingLogId,
            territoryId: lot.id,
        };

        const { data } = await axiosA.post('/EService/TransactionSession', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const purchaseToken = async (payment, lot, userId) => {
    try {
        const browserId = await getIPv4();
        const d = new Date();
        let diff = d.getTimezoneOffset();

        const customerBrowser = {
            browserAcceptHeader: "application/json",
            browserJavaEnabled: false,
            browserJavascriptEnabled: true,
            browserLanguage: getISOLang(),
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserScreenWidth: window.screen.width.toString(),
            browserTZ: diff.toString(),
            challengeWindowSize: "05" // Values accepted:  01 – 250 x 400  02 – 390 x 400  03 – 500 x 600  04 – 600 x 400  05 – Full screen
        };

        const body = {
            amount: payment.amount,
            currency: lot.currencyCode,
            parkingLogId: payment.parkingLogId,
            territoryId: lot.id,
            userId: userId,
            plateNum: localStorage.getItem('selectedVehicle'),
            redirect: redirect(),
            notificationUrl: notificationUrl(),
            customerIPAddress: browserId,
            customerBrowser: JSON.stringify(customerBrowser),
            userAgent: window.navigator.userAgent
        };

        const { data } = await axiosA.post('/EService/PurchaseToken', body)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const merchantLanding = async (payment) => {
    try {

        const { data } = await axiosA.post('/EService/MerchantLanding', payment)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

const lastCard = async (userId) => {
    try {
        return await axiosA.get(`EService/LastCard?userId=${userId}`);
    } catch (e) {
        throw new Error(e);
    }
}

const getUserCards = async () => {
    try {
        return await axiosA.get(`EService/UserCards`);
    } catch (e) {
        throw new Error(e);
    }
}

const getEServiceConfig = async () => {
    try {
        return await axiosA.get(`EService/EServiceConfig`);
    } catch (e) {
        throw new Error(e);
    }
}

const deleteCard = async (cardId) => {
    try {
        return await axiosA.get(`EService/deleteCard?cardId=${cardId}`);
    } catch (e) {
        throw new Error(e);
    }
}

const setActive = async (cardId) => {
    try {
        return await axiosA.get(`EService/SetActive?cardId=${cardId}`);
    } catch (e) {
        throw new Error(e);
    }
}

const getIPv4 = async () => {
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    return data.IPv4;
};

const getISOLang = () => {
    let ISOLanguage = 'pl-PL';
    const val = localStorage.getItem('currentLng');
    if (val === 'lt-lt') {
        ISOLanguage = 'lt-LT';
    }
    if (val === 'en-gb') {
        ISOLanguage = 'en-GB';
    }
    return ISOLanguage
}

const redirect = () => {
    return `${window.location.origin}/merchantLandingPage`;
}

const notificationUrl = () => {
    return `${window.location.origin}/v1/EService/TransactionResultCallback`
}

export {
    tokenize,
    purchaseToken,
    merchantLanding,
    lastCard,
    getUserCards,
    deleteCard,
    setActive
}