import React, {Component} from 'react';
import './UserAvatar.scss'

import { Avatar } from '@mui/material';

class UserAvatar extends Component {
  state = {
    initials: ''
  }
  resolveLetters = (name) => {
    if(!name) return;
    const arr = name.split(' ')
    if(arr.length > 1) {
      this.setState({
        initials: (arr[0][0]+arr[1][0]).toUpperCase()
      })
    }else {
      this.setState({
        initials: arr[0][0].toUpperCase()
      })
    }
  }
  componentDidUpdate = (oldProps) => {
    if(oldProps.name !== this.props.name) this.resolveLetters(this.props.name);
  }
  componentDidMount() {
    this.resolveLetters(this.props.name);
  }
  render() {
    const {initials} = this.state
    return (
      <React.Fragment>
            <Avatar>
              {initials}
            </Avatar>
      </React.Fragment>
    )}
}
export default UserAvatar;
