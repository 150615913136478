import PropTypes from 'prop-types';
import {Redirect,} from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();


    useEffect(() => {
        if (!isLoggedIn) {
            <Redirect
                to={{
                    pathname: "/login"
                  //  state: { from: props.location }
                }}
            />

        }
    }, [isLoggedIn]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
