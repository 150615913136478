export const AUTO_MATCH = 'AUTOMATIC_MATCHING';
export const SHOW_IBAN = 'SHOW_IBAN';
export const SHOW_PHONE = 'SHOW_PHONE';
export const PAID_SHARE = 'PAID_SHARE';
export const SET_UNBOOKING_TIME = 'SET_UNBOOKING_TIME';
export const SHOW_CALENDAR = 'SHOW_CALENDAR';
export const PARKING_GROUPS = 'PARKING_GROUPS';
export const DEADLINE_TIME_FEATURE = 'DEADLINE_TIME_FEATURE';
export const HIDE_BOOKING = 'HIDE_BOOKING';
export const SHOW_ANNOUNCEMENTS = 'SHOW_ANNOUNCEMENTS';
