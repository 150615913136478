import React, { useState } from 'react';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import UserAvatar from 'components/users/avatar/UserAvatar';
import DeleteActionButton from 'components/uiactions/actionbuttons/DeleteActionButton';

export default function GroupUserItem(props) {
  const { t } = useTranslation();
  const [clickedStatus, setClickedStatus] = useState(false);

  return (
    <React.Fragment>
      <ListItem
        className={props.shadow ? 'item-shadow' : 'item'}
        button
        onClick={
          props.onMainClick && (() => props.onMainClick({ ...props.user }))
        }
      >
        {props.user.name && (
          <ListItemAvatar>
            {props.user && <UserAvatar name={props.user.name} />}
          </ListItemAvatar>
        )}
        <ListItemText primary={props.user.name} />
        <ListItemSecondaryAction>
          {props.onChange && (
            // <Button className="changeBtn" aria-label="Change" onClick={() => props.onChange(props.user)}>
            //   <Typography className="changeBtn-text">{t("general.Change")}</Typography>
            // </Button>
            <IconButton
              className="add-btn"
              aria-label="Add"
              disabled={clickedStatus}
              onClick={() => {
                setClickedStatus(true);
                props.onChange(props.user);
              }}
            >
              <FontAwesomeIcon icon={faPlus} color="#3A4D8F" size="xs" />
            </IconButton>
          )}
          {props.onDelete && (
            <DeleteActionButton
              openit={false}
              onConfirm={() => props.onDelete(props.user.id)}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
}

// export default UserListItem;
