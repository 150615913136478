import React, {Component} from 'react';
import './SpaceAssign.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { List, Grid } from '@mui/material';
import { InputSearch } from '../../ui/inputs/search/Search';

import SpacesOwnerListItem from '../owner-list/item/SpacesOwnerListItem';
import { getParkingSpaces, resolveSpaceLot, setParkingOwner, getPrimaryParkingLots } from '../../../utils/ParkingService';
import { InputSelect } from '../../ui/inputs/select/Select';
import { withSnackbar } from 'notistack';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../../constants/Api';
const searchAPIDebounced = AwesomeDebouncePromise(getParkingSpaces, Api.SEARCH_DEBOUNCE);


class SpaceAssign extends Component {
  state = {
    parkingLots: [],
    selectedLot: this.props.t('spaces.All'),
    parkingSpaces: []
  }
  
  fetchParkingLots = async () => {
    try {
      // const resp = await getParkingLots();
      const resp = await getPrimaryParkingLots();
      this.setState({parkingLots: [{id: '', name: this.props.t('spaces.All')}, ...resp.items]})
    }catch (e) {
      console.error(e)
    }
  }
  resolveSpaceLot = async (spaces) => {
    const parkingSpaces = await resolveSpaceLot(spaces, this.state.parkingLots)
    await this.setState({parkingSpaces: [...parkingSpaces]})
  }
  fetchParkingSpaces = async () => {
    try {
      const lotId = this.props.t('spaces.All') === this.state.selectedLot ? undefined : this.state.selectedLot.id;
        const {items} = await searchAPIDebounced(lotId)
        await this.resolveSpaceLot(items);

    }catch (e) {
      console.error(e)
    }
  }
  setLot = (selectedLot) => {
    this.setState({selectedLot}, this.fetchParkingSpaces)
  }
  assignSpace = async (space) => {
    try { 
      const resp  =  await setParkingOwner(space, this.state.userId)
      if(resp) {
        this.props.enqueueSnackbar(this.props.t('notification.success.AssignSpace'), {
          variant: 'success',
        });
        this.props.history.goBack();
      }
    }catch(e){
      console.error(e)
    }
  }
  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    const {userId} = this.props.location.state;
    if(userId) {
      await this.setState({userId})
      await this.fetchParkingLots()
      await this.fetchParkingSpaces()
    }

  }

  render() {
    const {parkingSpaces, parkingLots, selectedLot} = this.state;
    const {t} = this.props;
    return (
      <React.Fragment>
        <Topbar title={t('spaces.Assign')} location={this.props.location} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"

        >
        <InputSelect 
          options={parkingLots}
          onChange={this.setLot}
          value={selectedLot}
          getOptionLabel={(option) => option['name']}
          getOptionValue={(option) => option['id']}
          placeholder={t("spaces.All")} />
        <InputSearch onChange={this.search} placeholder={t("general.Search")} />
        </Grid>
        </Topbar>
        <List className="spacesAssingContainer">
          {parkingSpaces && parkingSpaces.map((space, i) => (
            <SpacesOwnerListItem space={space} onSelect={() => this.assignSpace(space)} key={i} />
          ))}
        </List>
      </React.Fragment>

    );
  }
}
export default withSnackbar(withTranslation()(SpaceAssign))