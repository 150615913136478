import React, { useState } from 'react';
import { Box, Dialog, Grid, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import '../PublicParking.scss';
import { ReactComponent as NoDataIcon } from 'assets/icons/publicApp/icon_no_data.svg';
import ModernLoader from 'ui-component/ModernLoader';
import PublicBookingReceipt from './PublicBookingReceipt';
import PublicBookingCard from './PublicBookingCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PublicBooking = ({ bookings }) => {
  const { t } = useTranslation();
  const [showBookingReceipt, setShowBookingReceipt] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState();

  const openBookingReceipt = (item) => {
    setShowBookingReceipt(true);
    setSelectedReceipt(item);
  };

  const closeBookingReceipt = () => {
    setShowBookingReceipt(false);
    setSelectedReceipt();
  };

  return (
    <>
      <Box className='parking-tab-container'>
        {bookings && bookings.length > 0 ? (
          <Box pb={15}>
            {bookings.map((item, index) => (
              <Box key={`booking-card-${index}`}>
                <PublicBookingCard parkingReceipt={item} openBookingReceipt={openBookingReceipt} />
              </Box>
            ))}
          </Box>
        ) : (
          <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            {bookings && bookings.length === 0 ? (
              <Box>
                <Grid container xs={12} pb={3} justifyContent="center">
                  <NoDataIcon />
                </Grid>
                <Grid container justifyContent="center">
                  <Typography variant="h4" className="h4-text-bold" gutterBottom>
                    {t('publicApp.NoPublicBookingData')}
                  </Typography>
                </Grid>
              </Box>
            ) : (
              <ModernLoader />
            )}
          </Grid>
        )}
      </Box>
      <Dialog
        fullScreen
        open={showBookingReceipt}
        TransitionComponent={Transition}
        scroll="body"
        onClose={closeBookingReceipt}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        PaperProps={{
          sx: {
            borderRadius: '0 !important',
            padding: '0',
            overflow: 'hidden',
          },
        }}
      >
        <PublicBookingReceipt receipt={selectedReceipt} onClose={closeBookingReceipt} />
      </Dialog>
    </>
  );
};

export default PublicBooking;
