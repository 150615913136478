import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PublicTopbar from 'components/public/PublicTopbar';
import BookingTimeDataCard from './BookingTimeDataCard';
import { Grid, Box, Button, Card, Divider, Typography } from '@mui/material';
import VehiclesCard from 'components/public/vehicles/VehiclesCard';
import { getUserVehicles, postVehicle, deletePublicVehicle as deleteVehicleFn } from 'utils/VehicleService';
import { NotificationManager } from 'react-notifications';
import { createPublicBooking, getAvailableBookedSpace } from 'utils/PublicBookingService';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';

import icon_parking from 'assets/icons/publicApp/icon_parking.svg';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PublicBookingNotification from 'components/ui/dialogs/PublicBookingNotification';
import ModernLoader from 'ui-component/ModernLoader';

const PublicBookingForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentUserId = props.currentUser ? props.currentUser.id : null;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [payment, setPayment] = useState();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [bookingLicensePlate, setBookingLicensePlate] = useState(localStorage.getItem('selectedVehicle'));
  const [vehicleForDelete, setVehicleForDelete] = useState(null);
  const [showConfirmPopUpDrawer, setShowConfirmPopUpDrawer] = useState(false);
  const [clickedConfirm, setClickedConfirm] = useState(false);
  const [showNoVacantBookings, setShowNoVacantBookings] = useState(false);
  const [controlledSelectedField, setControlledSelectedField] = useState(null);

  const parkingLot = history.location.state?.parkingLot;

  const handleGoBack = () => {
    history.push({
      pathname: 'publicParking',
      state: {
        initialWindowMode: 'booking',
      },
    });
  };

  const onConfirmBooking = async () => {
    setIsReadOnly(true);
    setClickedConfirm(true);
    try {
      const createdBookedSpaceId = await createPublicBooking(payment.subscriptionId, payment.entryDateTime, payment.exitDateTime, bookingLicensePlate, payment.parkingLotId, payment.amount);

      if (createdBookedSpaceId) {
        payment.bookedSpaceId = createdBookedSpaceId;
        redirectToPayment();
      } else {
        console.log('close');
        setIsReadOnly(false);
        setClickedConfirm(false);
        throw new Error('Cannot create booking. Try again');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const redirectToPayment = () => {
    history.push({
      pathname: '/publicBookingPayment',
      state: {
        bookedFrom: startDate.toISOString(),
        bookedTo: endDate.toISOString(),
        parkingLot: parkingLot,
        plateNum: bookingLicensePlate,
        payment: JSON.stringify(payment),
      },
    });
  };

  const onFinishTimeSelection = async (start, end) => {
    setStartDate(start);
    setEndDate(end);

    try {
      const request = await getAvailableBookedSpace(parkingLot.id, start.toISOString(), end.toISOString());
      if (!request || !request.vacant || request.vacant === 0) {
        // show error message if there is no vacant spaces for this period per booking.
        // or there is another error.
        setShowNoVacantBookings(true);
      } else {
        setPayment(request);
        setControlledSelectedField(null);
        setShowConfirmPopUpDrawer(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onModifyAfterFinishedSelection = () => {
    setStartDate();
    setEndDate();
    setPayment();
    setShowNoVacantBookings(false);
  };

  const onCancelConfirmPopUpDrawer = () => {
    setControlledSelectedField('startDate');
    setShowConfirmPopUpDrawer(false);
  };

  const fetchVehiclesData = async () => {
    const plateNum = localStorage.getItem('selectedVehicle');
    await getUserVehicles(currentUserId).then((items) => {
      if (items) {
        let newVehicles = items || [];
        setVehicles(newVehicles);
        if (items.length > 0) {
          let newPlateNum = items[0].plateNum;
          if (plateNum) {
            const vehicle_ = items.find((x) => x.plateNum === plateNum);
            if (vehicle_) {
              newPlateNum = plateNum;
            }
          }
          localStorage.setItem('selectedVehicle', newPlateNum);
        } else {
          localStorage.removeItem('selectedVehicle');
        }
      }
    });
  };

  const selectVehicle = (selectedPlateNumber) => {
    localStorage.setItem('selectedVehicle', selectedPlateNumber);
    setBookingLicensePlate(selectedPlateNumber);
  };

  const addVehicle = async (newVehicle) => {
    if (props.currentUser) {
      try {
        const { affectedIds } = await postVehicle(newVehicle);
        if (affectedIds && affectedIds.length) {
          selectVehicle(newVehicle.plateNum);
          await fetchVehiclesData(props.currentUser.id);
          NotificationManager.success(props.t('notification.success.postVehicle'));
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      localStorage.setItem('selectedVehicle', newVehicle.plateNum);
      setVehicles((prevVehicles) => [...prevVehicles, { plateNum: newVehicle.plateNum }]);
    }
  };

  const deleteVehicle = async (plateNum) => {
    if (props.currentUser) {
      try {
        const forDeleteVehicle = vehicles.find((x) => x.plateNum === plateNum);
        const { affectedIds } = await deleteVehicleFn(forDeleteVehicle.id);
        if (affectedIds && affectedIds.length) {
          const newVehiclesList = vehicles.filter((e) => !affectedIds.includes(e.id));
          setVehicles(newVehiclesList);
          const selectedVehicle = localStorage.getItem('selectedVehicle');
          if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
            localStorage.removeItem('selectedVehicle');
            if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
              localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
            }
          }
          NotificationManager.success(props.t('notification.success.deleteVehicle'));
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      const newVehiclesList = vehicles.filter((x) => x.plateNum !== plateNum);
      setVehicles(newVehiclesList);
      const selectedVehicle = localStorage.getItem('selectedVehicle');
      if (!newVehiclesList.find((x) => x.plateNum === selectedVehicle)) {
        localStorage.removeItem('selectedVehicle');
        if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
          localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
        }
      }
      NotificationManager.success(props.t('notification.success.deleteVehicle'));
    }
    setVehicleForDelete(null);
  };

  const handleCancelDeleteVehicle = () => {
    setVehicleForDelete(null);
  };

  const handleSetDeleteVehicle = (plateNum) => {
    setVehicleForDelete(plateNum);
  };

  const redirectToPublicParking = () => {
    // happens when no data provided, e.g. after page reload
    history.push({
      pathname: 'publicParking',
      state: {
        initialWindowMode: 'booking',
      },
    });
  };

  useEffect(() => {
    if (!parkingLot) {
      redirectToPublicParking();
    }

    fetchVehiclesData();
    const timer = setTimeout(handleGoBack, 15 * 60 * 1000); // after 15 minutes of selection, return user to parkingBooking page.
  
    return () => clearTimeout(timer);
  }, []);

  /* TODO: if needed first scroll to bottom, then await for second click to confirm, so that user would see the whole booking data */
  const scrollToBookingTimeCard = () => {
    const block = document.getElementById('booking-time-card');
    if (block) {
      block.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="public-container" style={{ overflow: 'auto' }}>
      <PublicTopbar enableShadow title={t('publicApp.Booking')} onGoBack={handleGoBack} />

      {/* No vacant booking space found notification */}
      <PublicBookingNotification open={showNoVacantBookings} variant="error" text={t('notification.error.NoVacantBookingsFound')} />

      {/* Vehicles card */}
      {payment && (
        <Grid id="vehicles-card" pt={1}>
          <VehiclesCard t={t} vehicles={vehicles} selectVehicle={selectVehicle} addVehicle={addVehicle} deleteVehicle={handleSetDeleteVehicle} userId={props.currentUser.id} />

          <PopUpDrawer isWarningMessage header={t('publicApp.DeleteVehicle')} text={t('confirm.Delete')} showDrawer={vehicleForDelete} onClose={handleCancelDeleteVehicle}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={5} mt={2} mb={4}>
                <Button id="btn-cancel-delete" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={handleCancelDeleteVehicle} sx={{ height: '48px' }}>
                  <Typography variant="string" className="button-text">
                    {t('general.Cancel')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={5} mt={2} mb={4}>
                <Button id="btn-confirm-delete" disableElevation type="button" variant="contained" color="secondary" className="button-primary" onClick={() => deleteVehicle(vehicleForDelete)}>
                  <Typography variant="string" className="button-text">
                    {t('general.Confirm')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </PopUpDrawer>
        </Grid>
      )}

      {/* Parking lot card */}
      {payment && (
        <Card id="parking-lot-card" className="card-shadow" sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
          <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} />
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h4" className="h4-text">
                {t('general.ParkingLot')}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ px: 1, width: '100%', textAlign: 'center' }}>
            <Divider />
            {parkingLot && (
              <>
                <Grid sx={{ ml: 1, my: 1, textAlign: 'left' }}>
                  <Typography variant="h4" className="h4-text" gutterBottom>
                    {parkingLot.name}
                  </Typography>
                  {parkingLot.address && (
                    <Typography variant="h4" className="body1-text">
                      {parkingLot.address}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </Card>
      )}

      {/* Booking time card */}
      <Grid id="booking-time-card" pt={1}>
        <BookingTimeDataCard
          readOnly={isReadOnly}
          value={startDate && endDate ? { start: startDate, end: endDate } : undefined}
          onModifyAfterFinishedSelection={onModifyAfterFinishedSelection}
          controlledSelectedField={controlledSelectedField}
          onFinishSelection={onFinishTimeSelection}
          payment={payment}
          parkingLot={parkingLot}
          showNoVacantBookings={showNoVacantBookings}
        />
      </Grid>

      {/* Empty space for small screens */}
      {payment && <Box pt={21} />}

      {/* Confirm pop up drawer */}
      <BottomSheet open={showConfirmPopUpDrawer} scrollLocking={false} blocking={false} className="bottom-sheet-without-header">
        <Grid container justifyContent="center">
          <Typography mb={2} mt={3} gutterBottom variant="h4" className="h4-text">
            {t('publicApp.ConfirmBookingTime')}
          </Typography>

          {clickedConfirm ? (
            <Grid container justifyContent='center' pt={1} pb={5}>
              <ModernLoader />
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={5} mt={2} mb={4}>
                <Button id="btn-cancel-booking" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={onCancelConfirmPopUpDrawer} sx={{ height: '48px' }}>
                  <Typography variant="string" className="button-text">
                    {t('general.Cancel')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={5} mt={2} mb={4}>
                <Button id="btn-confirm-booking" disableElevation type="button" variant="contained" color="secondary" className="button-primary" onClick={onConfirmBooking}>
                  <Typography variant="string" className="button-text">
                    {t('general.Confirm')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </BottomSheet>
    </div>
  );
};

export default PublicBookingForm;
