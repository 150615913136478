//import { render } from 'node-sass';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useHistory } from 'react-router-dom';
import './SubscriptionList.scss';
import { useTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import { List, Divider, ListItem } from '@mui/material';
import SubscriptionItem from './item/SubscriptionItem';
import MomentUtils from '@date-io/moment';
import { resolveMinMaxDates } from 'utils/DateTimeResolver';

export default function SubscriptionList({
  onDelete,
  sharedSpaceRequests,
  bookedSpaces,
  needLoad,
  load,
  title,
  user,
  subscribe,
  isAdmin,
  subscribeLimit
}) {
  const currentLng = localStorage.getItem('currentLng');
  const moment = new MomentUtils({ locale: currentLng });
  const history = useHistory();
  const { t } = useTranslation();

  const [isSpaceRequest, setIsSpaceRequest] = useState(subscribe);
  const [spaceRequests, setSpaceRequests] = useState(sharedSpaceRequests || []);

  useEffect(() => {
    setSpaceRequests(sharedSpaceRequests);
  }, [sharedSpaceRequests]);

  useEffect(() => {
    setIsSpaceRequest(subscribe);
  }, [subscribe]);

  const onSpaceRequestCreate = () => {
    let path = '/subscriptions/new';
    if (!isSpaceRequest) {
      path = '/silenceperiods/new';
    }

    history.push(path, {
      subscribe: isSpaceRequest,
      user: user,
      sharedDates: spaceRequests,
      bookedSpaces: bookedSpaces,
      subscribeLimit: subscribeLimit
      // TODO: pass some params about datetime limits
      // initFrom: new Date('2021-02-28T00:00:00Z'),
    });
  };

  const onSpaceRequestEdit = (item) => {
    let path = '/subscriptions/edit';

    let periods = bookedSpaces 
      ? spaceRequests.concat(
        bookedSpaces.map((space) => ({
          from: space.bookedFrom,
          to: space.bookedTo,
          id: space.id,
          userId: space.userId,
        }))
      )
      : spaceRequests;

    const dateRange = resolveMinMaxDates(periods, item.from, item.to, item.id);

    history.push(path, {
      subscribe: isSpaceRequest,
      user: user,
      item: item,
      minDate: dateRange.minDate,
      maxDate: dateRange.maxDate,
      subscribeLimit: subscribeLimit
    });
  }

  const setDeleteVisible = (item) => {
    return item.readonly && !isAdmin ? null : onDelete;
  };
  return (
    <React.Fragment>
      <ListHead title={t(title)} onCreate={onSpaceRequestCreate} />
      <List>
        {spaceRequests &&
          spaceRequests.map((item, i) => (
            <React.Fragment key={item.id}>
              <SubscriptionItem
                shadow={true}
                onDelete={setDeleteVisible(item)}
                sharedSpaceRequest={item}
                onSharedSpaceRequest={onSpaceRequestEdit}
                canEdit={isSpaceRequest}
                changeTitle={t('exchanges.Change')}
              />

            </React.Fragment>
          ))}
        {needLoad && (
          <ListItem key="more" onClick={() => load(true)} className="LoadMore">
            <p style={{ textAlign: 'center' }}>
              <b>{t('general.LoadMore')}</b>
            </p>
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
}
