import React, { useState, useEffect } from 'react';
import { Button, Grid, Dialog, DialogActions, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import information_bg from 'assets/images/information_bg.svg';

const AnnouncementDialog = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { show, title, content, hasNext = false, moveNext, onClose } = props;

  useEffect(() => {
    if (show !== undefined && show !== null) {
      setOpen(show);
    }
  }, [show]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <Grid container pt={2} px={3}>
        <Grid xs={12} pt={2} pb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={information_bg} alt="information_bg" />
        </Grid>
        <Grid xs={12} pb={2.5}>
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6" sx={{ whiteSpace: 'pre-wrap' }}>
            {content.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </Typography>
        </Grid>
      </Grid>

      <DialogActions>
        <Grid pr={1}>{hasNext && moveNext ? <Button onClick={moveNext}>{t('general.Next')}</Button> : <Button onClick={handleClose}>{t('general.Close')}</Button>}</Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementDialog;
