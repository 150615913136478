import React from 'react';
import { Box, Divider, Grid, Typography, SwipeableDrawer } from '@mui/material';
import './PopUpDrawer.scss';
import { ReactComponent as WarningIcon } from '../../assets/icons/publicApp/icon_warning.svg';

const PopUpDrawer = ({ disableSwipeToOpen = true, header, text, isWarningMessage, showDrawer, onClose, children }) => {
  return (
    <>
      <SwipeableDrawer
        className="my-swipeable-drawer"
        PaperProps={{
          style: { borderRadius: '15px 15px 0 0' },
        }}
        open={showDrawer}
        onClose={onClose}
        anchor="bottom"
        disableSwipeToOpen={disableSwipeToOpen}
        disableDiscovery
        disableBackdropTransition
        onOpen={() => {}}
      >
        {header && (
          <Box xs={10} pb={4} sx={{ textAlign: 'center' }}>
            <Typography
              gutterBottom
              variant="h2"
              sx={{
                pl: 1,
                pt: 2.5,
                pb: 0.5,
                fontWeight: 500,
                letterSpacing: 0.25,
              }}
              color="#FF7D6B"
            >
              {header}
            </Typography>
            <Box mt={2} mb={0.5} ml={4} mr={4}>
              <Divider
                sx={{
                  height: '2px',
                  backgroundColor: 'rgba(35, 49, 68, 0.05)',
                }}
              />
            </Box>
            {isWarningMessage && (
              <Box mt={2} sx={{ color: '#FF7D6B' }}>
                <WarningIcon style={{ width: '5rem', height: '5rem' }} />
              </Box>
            )}
            {text && (
              <Grid container justifyContent="center">
                <Grid item xs={11}>
                  <Typography gutterBottom variant="h4" className="h4-text">
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <Box>{children}</Box>
      </SwipeableDrawer>
    </>
  );
};

export default PopUpDrawer;
