import React, { useState } from 'react';
import './SubscriptionItem.scss';
import MomentUtils from '@date-io/moment';

import {
  ListItem,
  IconButton,
  Box,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ListExpanded from '../../../ui/list/expanded/ListExpanded';
import { faPlus, faUserLock } from '@fortawesome/free-solid-svg-icons';
import DeleteActionButton from '../../../uiactions/actionbuttons/DeleteActionButton';

function SubscriptionItem({
  shadow,
  onDelete,
  sharedSpaceRequest,
  canEdit,
  onSharedSpaceRequest,
  changeTitle,
}) {
  const currentLng = localStorage.getItem('currentLng');
  const moment = new MomentUtils({ locale: currentLng });
  const [item, setSharedSpaceRequest] = useState(sharedSpaceRequest || null);
  const [editEnabled, setCanEdit] = useState(canEdit || false);
  const formatDate = (inDate) => {
    return moment.date(inDate).utc(0).format('MMM DD');
  };

  return (
    <React.Fragment>
      {item && (
        <ListItem
          // button={onBookSpace && true}
          // onClick={() => onBookSpace && onBookSpace({...parkingSpace, ...bookedSpace})}
          className="SubscriptionItemContainer"
        >
          {/*Todo prsymas*/}

          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                background: '#ffffff',
                borderRadius: '10px',
                border: canEdit ? '1px solid #3A4D8F' : '1px solid #FF8B79',
                py: 1,
                px: 2,
              }}
              alignItems="center"
              justify="center"
            >
              <Grid xs container justify="center" alignItems="center">
                <Grid item xs>
                  <Grid container sx={{ px: 0.5, py: 0.5 }}>
                    <Grid
                      item
                      xl={2}
                      xs={10}
                      sm={2}
                      justify="center"
                      sx={{
                        background: '#F0F2F6',
                        borderRadius: '5px',
                        border: '0px solid',
                        px: 2,
                        py: 0.5,
                      }}
                    >
                      <Typography className="grey" color="#656D78">
                        {formatDate(item.from)} - {formatDate(item.to)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box>
                    { onSharedSpaceRequest && editEnabled && (
                      <div className="pb-10">
                        {onDelete &&
                          <Button
                            className="actionBtn"
                            aria-label="Delete"
                            onClick={() =>
                              onSharedSpaceRequest(
                                item /*parkingSpace.name, parkingLot.name*/
                              )
                            }
                          >
                            <Typography className="shareBtn-text">
                              {changeTitle}
                            </Typography>
                          </Button>
                        }
                      </div>
                    )}

                    {onDelete && (
                      <div>
                        {onDelete && (
                          <DeleteActionButton
                            openit={false}
                            onConfirm={() => onDelete(item.id)}
                          />
                        )}
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </React.Fragment>
  );
}

export default SubscriptionItem;
