import React, { useState } from 'react';
import { Box, Dialog, Grid, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import '../PublicParking.scss';
import { ReactComponent as NoDataIcon } from 'assets/icons/publicApp/icon_no_data.svg';
import ModernLoader from 'ui-component/ModernLoader';
import ParkingHistoryReceipt from './ParkingHistoryReceipt';
import ParkingHistoryCard from './ParkingHistoryCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PublicParkingHistory = ({ parkingReceipts }) => {
  const { t } = useTranslation();
  const [showHistoryReceipt, setShowHistoryReceipt] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState();

  const openHistoryReceipt = (item) => {
    setShowHistoryReceipt(true);
    setSelectedReceipt(item);
  };

  const closeHistoryReceipt = () => {
    setShowHistoryReceipt(false);
    setSelectedReceipt();
  };

  return (
    <>
      <Box className='parking-tab-container'>
        {parkingReceipts && parkingReceipts.length > 0 ? (
          <Box pb={3}>
            {parkingReceipts.map((item, index) => (
              <Box key={`history-card-${index}`}>
                <ParkingHistoryCard parkingReceipt={item} openHistoryReceipt={openHistoryReceipt} />
              </Box>
            ))}
          </Box>
        ) : (
          <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            {parkingReceipts && parkingReceipts.length === 0 ? (
              <Box>
                <Grid container xs={12} pb={3} justifyContent="center">
                  <NoDataIcon />
                </Grid>
                <Grid container justifyContent="center">
                  <Typography variant="h4" className="h4-text-bold" gutterBottom>
                    {t('publicApp.NoParkingHistoryData')}
                  </Typography>
                </Grid>
              </Box>
            ) : (
              <ModernLoader />
            )}
          </Grid>
        )}
      </Box>
      <Dialog
        fullScreen
        open={showHistoryReceipt}
        TransitionComponent={Transition}
        scroll="body"
        onClose={closeHistoryReceipt}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        PaperProps={{
          sx: {
            borderRadius: '0 !important',
            padding: '0',
            overflow: 'hidden',
          },
        }}
      >
        <ParkingHistoryReceipt receipt={selectedReceipt} onClose={closeHistoryReceipt} />
      </Dialog>
    </>
  );
};

export default PublicParkingHistory;
