import React, { Component } from 'react';
import { Typography } from '@mui/material';
import './BankImg.scss';
import { localBankImages } from './bankImagesList.js';

class BankImg extends Component {
  constructor() {
    super();
    this.state = {
      image: null,
      item: {},
      tinyVariant: false,
    };
  }

  getLocalImage = (url) => {
    const parts = url.split('/');
    const filename = parts[parts.length - 1].split(".")[0]; // This will be e.g. "REVOLUT_GB_b"

    try {
      const image = localBankImages[filename];
      return image;
    } catch (error) {
      console.error(`Local bank image not found: ${error}`);
      return null;
    }
  };

  componentDidMount = async () => {
    const { item, tinyVariant } = this.props;
    if (tinyVariant) {
      this.setState({ tinyVariant: tinyVariant });
    }
    this.setState({ item });
    const localImage = await this.getLocalImage(item.bigImageUri);

    if (localImage) {
      this.setState({ image: localImage });
    } else {
      const requestOptions = {
        method: 'GET',
        headers: { 'Access-Control-Allow-Origin': '*' },
      };
      const result = await fetch(item.bigImageUri, requestOptions).catch(
        (error) => console.error(error)
      );
      if (result && result.ok) {
        this.setState({ image: result });
      }
    }
  };

  render() {
    const { item, image, tinyVariant } = this.state;
    return (
      <div className={tinyVariant ? 'bank-img-tiny' : 'bank-img-regular'}>
        {image ? (
          <img alt="bank-img" src={image} />
        ) : (
          <Typography variant="h4">{item.name}</Typography>
        )}
      </div>
    );
  }
}

export default BankImg;
