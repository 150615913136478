import {axiosA} from './AuthService';


const getUsers = async ( query='', Offset, Limit = 20) => {

	try{
		let link = `/Users?Search=${query}&Limit=${Limit}`
		if(Offset) link += '&Offset='+Offset
		const {data} = await axiosA.get(link)
		return data
	}catch (e) {
		throw new Error(e)
	}
}
const getCurrentUser = async (newLogin) => {
	try{
		
		if(newLogin || !sessionStorage.user) {
			const {data} = await axiosA.get('/Users/Current')
			sessionStorage.user =  JSON.stringify(data)
		}
		return JSON.parse(sessionStorage.user)
	}catch (e) {
		throw new Error(e)
	}

}
const getUser = async (id) => {
	try{
		const {data} = await axiosA.get(`/Users/${id}`)
		return data
	}catch (e) {
		throw new Error(e)
	}

}

const getActiveInfo = async (id) => {
	try{
		const {data} = await axiosA.get(`/Users/ActiveInfo/${id}`)
		return data
	}catch (e) {
		throw new Error(e)
	}

}
const deleteUser = async (id) => {
	const {data} = await axiosA.delete(`/Users/${id}`)
	return data
}


const postUser = async (user) => {
	try{
		const body = {
			name: user.name,
			email: user.email,
			phone: user.phone,
			iban: user.iban
		}
		const {data} = await axiosA.post('/Users', body)
		return data
	}catch (e) {
		throw new Error(e)
	}
}

const putUser = async (user) => {
	const body = {
		name: user.name,
		email: user.email,
		phone: user.phone,
		iban: user.iban
	}
	const {data} = await axiosA.put(`/Users/${user.id}`, body)
	return data
} 

const getDateStatuses = async (id) => {
	try {
		const { data } = await axiosA.get(`/Users/Periods/${id}`)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

export {getUsers,getCurrentUser,  getUser, postUser, putUser, deleteUser, getDateStatuses, getActiveInfo};

