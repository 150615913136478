import ALIOR_PL_b from '../../../../assets/images/bankImages/ALIOR_PL_b.png';
import BANK_NORWEGIAN_NO_b from '../../../../assets/images/bankImages/BANK_NORWEGIAN_NO_b.png';
import BNP_PARIBAS_FR_b from '../../../../assets/images/bankImages/BNP_PARIBAS_FR_b.png';
import BNP_PARIBAS_PL_b from '../../../../assets/images/bankImages/BNP_PARIBAS_PL_b.png';
import CITADELE_LT_b from '../../../../assets/images/bankImages/CITADELE_LT_b.png';
import CITADELE_LV_b from '../../../../assets/images/bankImages/CITADELE_LV_b.png';
import CSAS_CZ_b from '../../../../assets/images/bankImages/CSAS_CZ_b.png';
import CSOB_CZ_b from '../../../../assets/images/bankImages/CSOB_CZ_b.png';
import HANDELSBANKEN_SE_b from '../../../../assets/images/bankImages/HANDELSBANKEN_SE_b.png';
import ING_ES_b from '../../../../assets/images/bankImages/ING_ES_b.png';
import ING_NL_b from '../../../../assets/images/bankImages/ING_NL_b.png';
import ING_PL_b from '../../../../assets/images/bankImages/ING_PL_b.png';
import ING_RO_b from '../../../../assets/images/bankImages/ING_RO_b.png';
import JYSKEBANK_DK_b from '../../../../assets/images/bankImages/JYSKEBANK_DK_b.png';
import KBC_BE_b from '../../../../assets/images/bankImages/KBC_BE_b.png';
import KB_CZ_b from '../../../../assets/images/bankImages/KB_CZ_b.png';
import LHV_EE_b from '../../../../assets/images/bankImages/LHV_EE_b.png';
import LUMINOR_EE_b from '../../../../assets/images/bankImages/LUMINOR_EE_b.png';
import LUMINOR_LT_b from '../../../../assets/images/bankImages/LUMINOR_LT_b.png';
import LUMINOR_LV_b from '../../../../assets/images/bankImages/LUMINOR_LV_b.png';
import MBANK_CZ_b from '../../../../assets/images/bankImages/MBANK_CZ_b.png';
import MBANK_PL_b from '../../../../assets/images/bankImages/MBANK_PL_b.png';
import MEDBANK_LT_b from '../../../../assets/images/bankImages/MEDBANK_LT_b.png';
import MILLENIUM_PL_b from '../../../../assets/images/bankImages/MILLENIUM_PL_b.png';
import NORDEA_DK_b from '../../../../assets/images/bankImages/NORDEA_DK_b.png';
import NORDEA_FI_b from '../../../../assets/images/bankImages/NORDEA_FI_b.png';
import NORDEA_NO_b from '../../../../assets/images/bankImages/NORDEA_NO_b.png';
import NORDEA_SE_b from '../../../../assets/images/bankImages/NORDEA_SE_b.png';
import NYKREDIT_DK_b from '../../../../assets/images/bankImages/NYKREDIT_DK_b.png';
import OMA_SAASTOPANKKI_FI_b from '../../../../assets/images/bankImages/OMA_SAASTOPANKKI_FI_b.png';
import OP_FINANCIAL_FI_b from '../../../../assets/images/bankImages/OP_FINANCIAL_FI_b.png';
import PEKAO_PL_b from '../../../../assets/images/bankImages/PEKAO_PL_b.png';
import PKO_PL_b from '../../../../assets/images/bankImages/PKO_PL_b.png';
import POP_PANKKI_FI_b from '../../../../assets/images/bankImages/POP_PANKKI_FI_b.png';
import RABOBANK_NL_b from '../../../../assets/images/bankImages/RABOBANK_NL_b.png';
import REVOLUT_AT_b from '../../../../assets/images/bankImages/REVOLUT_AT_b.png';
import REVOLUT_BE_b from '../../../../assets/images/bankImages/REVOLUT_BE_b.png';
import REVOLUT_BG_b from '../../../../assets/images/bankImages/REVOLUT_BG_b.png';
import REVOLUT_CZ_b from '../../../../assets/images/bankImages/REVOLUT_CZ_b.png';
import REVOLUT_DK_b from '../../../../assets/images/bankImages/REVOLUT_DK_b.png';
import REVOLUT_EE_b from '../../../../assets/images/bankImages/REVOLUT_EE_b.png';
import REVOLUT_ES_b from '../../../../assets/images/bankImages/REVOLUT_ES_b.png';
import REVOLUT_FI_b from '../../../../assets/images/bankImages/REVOLUT_FI_b.png';
import REVOLUT_FR_b from '../../../../assets/images/bankImages/REVOLUT_FR_b.png';
import REVOLUT_GB_b from '../../../../assets/images/bankImages/REVOLUT_GB_b.png';
import REVOLUT_HU_b from '../../../../assets/images/bankImages/REVOLUT_HU_b.png';
import REVOLUT_IT_b from '../../../../assets/images/bankImages/REVOLUT_IT_b.png';
import REVOLUT_LT_b from '../../../../assets/images/bankImages/REVOLUT_LT_b.png';
import REVOLUT_LV_b from '../../../../assets/images/bankImages/REVOLUT_LV_b.png';
import REVOLUT_NL_b from '../../../../assets/images/bankImages/REVOLUT_NL_b.png';
import REVOLUT_NO_b from '../../../../assets/images/bankImages/REVOLUT_NO_b.png';
import REVOLUT_PL_b from '../../../../assets/images/bankImages/REVOLUT_PL_b.png';
import REVOLUT_PT_b from '../../../../assets/images/bankImages/REVOLUT_PT_b.png';
import REVOLUT_RO_b from '../../../../assets/images/bankImages/REVOLUT_RO_b.png';
import REVOLUT_SE_b from '../../../../assets/images/bankImages/REVOLUT_SE_b.png';
import REVOLUT_SK_b from '../../../../assets/images/bankImages/REVOLUT_SK_b.png';
import SAASTOPANKKI_FI_b from '../../../../assets/images/bankImages/SAASTOPANKKI_FI_b.png';
import SANTANDER_DE_b from '../../../../assets/images/bankImages/SANTANDER_DE_b.png';
import SANTANDER_ES_b from '../../../../assets/images/bankImages/SANTANDER_ES_b.png';
import SANTANDER_PL_b from '../../../../assets/images/bankImages/SANTANDER_PL_b.png';
import SB_LT_b from '../../../../assets/images/bankImages/SB_LT_b.png';
import SEB_EE_b from '../../../../assets/images/bankImages/SEB_EE_b.png';
import SEB_LT_b from '../../../../assets/images/bankImages/SEB_LT_b.png';
import SEB_LV_b from '../../../../assets/images/bankImages/SEB_LV_b.png';
import SEB_SE_b from '../../../../assets/images/bankImages/SEB_SE_b.png';
import SLSP_SK_b from '../../../../assets/images/bankImages/SLSP_SK_b.png';
import SOCIETE_GENERALE_FR_b from '../../../../assets/images/bankImages/SOCIETE_GENERALE_FR_b.png';
import SWEDBANK_EE_b from '../../../../assets/images/bankImages/SWEDBANK_EE_b.png';
import SWEDBANK_LT_b from '../../../../assets/images/bankImages/SWEDBANK_LT_b.png';
import SWEDBANK_LV_b from '../../../../assets/images/bankImages/SWEDBANK_LV_b.png';
import SWEDBANK_SE_b from '../../../../assets/images/bankImages/SWEDBANK_SE_b.png';
import SYDBANK_DK_b from '../../../../assets/images/bankImages/SYDBANK_DK_b.png';
import UNICREDIT_SK_b from '../../../../assets/images/bankImages/UNICREDIT_SK_b.png';

export const localBankImages = {
  ALIOR_PL_b,
  BANK_NORWEGIAN_NO_b,
  BNP_PARIBAS_FR_b,
  BNP_PARIBAS_PL_b,
  CITADELE_LT_b,
  CITADELE_LV_b,
  CSAS_CZ_b,
  CSOB_CZ_b,
  HANDELSBANKEN_SE_b,
  ING_ES_b,
  ING_NL_b,
  ING_PL_b,
  ING_RO_b,
  JYSKEBANK_DK_b,
  KBC_BE_b,
  KB_CZ_b,
  LHV_EE_b,
  LUMINOR_EE_b,
  LUMINOR_LT_b,
  LUMINOR_LV_b,
  MBANK_CZ_b,
  MBANK_PL_b,
  MEDBANK_LT_b,
  MILLENIUM_PL_b,
  NORDEA_DK_b,
  NORDEA_FI_b,
  NORDEA_NO_b,
  NORDEA_SE_b,
  NYKREDIT_DK_b,
  OMA_SAASTOPANKKI_FI_b,
  OP_FINANCIAL_FI_b,
  PEKAO_PL_b,
  PKO_PL_b,
  POP_PANKKI_FI_b,
  RABOBANK_NL_b,
  REVOLUT_AT_b,
  REVOLUT_BE_b,
  REVOLUT_BG_b,
  REVOLUT_CZ_b,
  REVOLUT_DK_b,
  REVOLUT_EE_b,
  REVOLUT_ES_b,
  REVOLUT_FI_b,
  REVOLUT_FR_b,
  REVOLUT_GB_b,
  REVOLUT_HU_b,
  REVOLUT_IT_b,
  REVOLUT_LT_b,
  REVOLUT_LV_b,
  REVOLUT_NL_b,
  REVOLUT_NO_b,
  REVOLUT_PL_b,
  REVOLUT_PT_b,
  REVOLUT_RO_b,
  REVOLUT_SE_b,
  REVOLUT_SK_b,
  SAASTOPANKKI_FI_b,
  SANTANDER_DE_b,
  SANTANDER_ES_b,
  SANTANDER_PL_b,
  SB_LT_b,
  SEB_EE_b,
  SEB_LT_b,
  SEB_LV_b,
  SEB_SE_b,
  SLSP_SK_b,
  SOCIETE_GENERALE_FR_b,
  SWEDBANK_EE_b,
  SWEDBANK_LT_b,
  SWEDBANK_LV_b,
  SWEDBANK_SE_b,
  SYDBANK_DK_b,
  UNICREDIT_SK_b,
};
