import { AuthConsumer } from 'providers/AccountProvider';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom';
import AuthGuard from 'utils/route-guard/AuthGuard';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <AuthGuard>
      <AuthConsumer>
        {({
          isAuth,
          currentUser,
          deleteAccount,
          logout,
          logoutGuest,
          isAdmin,
          toggleAdmin,
          settings,
          enabledFeatures,
          haveConsent,
          setConsent,
          login,
          checkPushSubscription,
          fetchSettings,
          fetchCurrentUser,
          checkAccount,
        }) => (
          <Route
            {...rest}
            render={(props) =>
              isAuth ? (
                <Component
                  {...props}
                  timestamp={new Date().toString()}
                  settings={settings}
                  enabledFeatures={enabledFeatures}
                  currentUser={currentUser}
                  deleteAccount={deleteAccount}
                  logout={logout}
                  logoutGuest={logoutGuest}
                  isAdmin={isAdmin}
                  toggleAdmin={toggleAdmin}
                  haveConsent={haveConsent}
                  setConsent={setConsent}
                  login={login}
                  checkPushSubscription={checkPushSubscription}
                  fetchSettings={fetchSettings}
                  fetchCurrentUser={fetchCurrentUser}
                  checkAccount={checkAccount}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        )}
      </AuthConsumer>
    </AuthGuard>
  );
}

export default PrivateRoute;
