import { useEffect, useState } from 'react';
import { Topbar } from '../ui/topbar/Topbar';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {
  AppBar,
  Box,
  Container,
  Fab,
  Grid,
  Toolbar,
  Typography,
  OutlinedInput,
} from '@mui/material';
import { InputText } from '../ui/inputs/text/Text';
import React from 'react';
import {
  postParkingRates,
  putParkingRate,
} from '../../utils/ParkingRatesService';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ParkingRatesForm = (props) => {
  const { rates, history } = props.location.state;
  //console.log('arrate', rates);
  const [newrate, setRate] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const title = isEdit
    ? i18next.t('rates.editRate')
    : i18next.t('ParkingRatesForm.CreateRate');

  //console.log('arrate  newrate', newrate);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const { rates, isChange } = props.location.state;
    setRate(rates);
    setIsEdit(isChange);
    console.log('parking set ratest');
  }, []);

  const goBack = () => {
    props.history.goBack();
  };

  const handleChange = (event) => {
    setRate({ ...newrate, [event.target.id]: event.target.value });
    console.log('handleChange', newrate);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('onSubmit', newrate);
    if (isEdit) {
      const data = await putParkingRate(newrate);
      console.log('oneditdata', data);
      enqueueSnackbar(i18next.t('ParkingRatesForm.RateChangedSuccessfully'), {
        variant: 'success',
      });
      goBack();
    }

    if (!isEdit) {
      const data = await postParkingRates(newrate);
      console.log('onSubmitdata', data);
      enqueueSnackbar(i18next.t('ParkingRatesForm.RateSuccessfullyCreated'), {
        variant: 'success',
      });
      goBack();
    }
  };

  return (
    <div>
      <Topbar title={title} cbBack={goBack} />
      <div className="territoryNewContainer">
        <Grid
          xs
          container
          direction="row"
          sx={{ p: 2, py: 1.5, background: '#3A4D8F', border: '1px solid' }}
        >
          <Grid item xs>
            <Typography variant="h3" color="#ffffff">
              {!isEdit && i18next.t('parkinger.NewParkingRate')}
              {isEdit && i18next.t('rates.parkingRateEditing')}
            </Typography>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit} noValidate>
          <SnackbarProvider>
            <Container fixed className="adm_form-container">
              <InputText
                type="text"
                id="title"
                onChange={handleChange}
                value={newrate.title}
                label={i18next.t('ParkingRatesForm.RateName')}
                placeholder={newrate.title}
              />

              {/*TODO vertimai*/}
              <InputText
                type="number"
                id="rate"
                onChange={handleChange}
                value={newrate.rate}
                label={i18next.t('parkinger.DailyParkingRateInEuros')}
                placeholder={i18next.t('parkinger.DailyParkingRateInEuros')}
              />
            </Container>
          </SnackbarProvider>
          <AppBar
            color="inherit"
            position="fixed"
            className="bottom-bar"
            elevation={0}
          >
            <Toolbar>
              <Grid
                spacing={3}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {newrate.title && newrate.rate && (
                  <Grid item xs={10}>
                    <Fab
                      className="full-width secondary-btn"
                      variant="extended"
                      type="submit"
                      value="Submit"
                      onClick={handleSubmit}
                      aria-label="Save"
                    >
                      <Box fontSize={14} textAlign="center">
                        {i18next.t('general.Save')}
                      </Box>
                    </Fab>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </form>
      </div>
    </div>
  );
};
export default ParkingRatesForm;
