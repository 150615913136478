import React, { Component } from 'react';
import './ExchangesListItem.scss'
import MomentUtils from "@date-io/moment";

import { ListItem, IconButton, Box, Typography, Button, Divider, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { getParkingSpace, getParkingLot, getParkingLotNames } from '../../../../utils/ParkingService';
import { faUserLock, faPlus, faUser, faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import ListExpanded from '../../../ui/list/expanded/ListExpanded';
import "moment/locale/lt";
import "moment/locale/en-gb";
import DeleteActionButton from "../../../uiactions/actionbuttons/DeleteActionButton";

class ExchangesListItem extends Component {
  state = {

    parkingSpace: {

    },
    owner: {
    },
    parkingLotName: null,
    parkingLotNames: [],
    parkingSpaceName: null

  }
  fetchInfo = async ({ parkingSpaceId, parkingLotId, bookingLevel, parkingSpaceName }, parkingSpaces, parkingLots) => {
    try {
      let parkingSpace;
      if (parkingSpaces && parkingSpaces.length > 0) {
        parkingSpace = parkingSpaces.find(x => x.id == parkingSpaceId);
      } else {
        if (parkingSpaceId) {
          parkingSpace = await getParkingSpace(parkingSpaceId);
        }
      }
      let parkingLotName;
      let parkingLotNames;
      if ((parkingSpace && parkingSpace.bookingLevel === 2) || (parkingSpaceName && bookingLevel == 2)) {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotNames = parkingLots.filter(x => x.territoryId == parkingLotId).map(x => x.name);
        } else {
          parkingLotNames = await getParkingLotNames(parkingLotId);
        }

        this.setState({ parkingLotNames: parkingLotNames });
      } else {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotName = parkingLots.find(x => x.id == parkingLotId).name;
        } else {
          const parkingLot = await getParkingLot(parkingLotId);
          parkingLotName = parkingLot.name;
        }
      }
      const dates = await this.resolveDate();
      await this.setState({ parkingSpace: { ...parkingSpace, parkingLotName: parkingLotName }, parkingLotName, exchange: { ...this.props.exchange, ...dates }, parkingSpaceName })
    } catch (e) {
      console.error(e)
    }
  }

  resolveDate = () => {
    const currentLng = localStorage.getItem('currentLng');
    const moment = new MomentUtils({ locale: currentLng });
    const dates = {
      sharedFromFormated: moment.date(this.props.exchange.sharedFrom).utc(0).format('MMM DD'),
      sharedToFormated: moment.date(this.props.exchange.sharedTo).utc(0).format('MMM DD')

    }
    return dates
  }

  componentDidMount = () => {
    this.fetchInfo(this.props.exchange, this.props.parkingSpaces, this.props.parkingLots);
  }
  render() {
    const { onDelete, onBookSpace, locked, canEdit, changeTitle, isNew, spaceName, vacantFrom, vacantTo } = this.props;
    const { exchange, parkingSpace, parkingLotName, parkingLotNames, parkingSpaceName } = this.state;
    return (
      <React.Fragment>
        {exchange &&
          <ListItem
            // button={onBookSpace && isNew}
            // onClick={() => onBookSpace && isNew && onBookSpace({ ...parkingSpace, ...exchange })}
            className="ExchangeItemContainer"
          >
            <Grid container>
              <Grid item xs={12} sx={{ background: '#3A4D8F', borderRadius: '10px', border: '2px solid #3A4D8F' }}>
                <Grid container >
                  <Grid item xs={2} sx={{ py: 1.5 }} >
                    <Grid container direction='column' alignItems='center'>
                      <Grid item xs alignContent='center'>
                        <Typography className='h4' color="#ffffff">
                          {exchange.sharedFromFormated}
                        </Typography>
                      </Grid>
                      <Grid item xs margin='0px'>
                        <Typography className='h4' color="#ffffff">
                          -
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className='h4' color="#ffffff">
                          {exchange.sharedToFormated}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sx={{ background: '#ffffff', py: 1.5, px: 2, borderRadius: '10px ' }}>
                    <Grid xs container  >
                      <Grid item xs>
                        {parkingSpaceName ?
                          <Typography className='h2' color='#3A4D8F'>
                            {parkingSpaceName}
                          </Typography>
                          :
                          <Typography className='h2' color='#3A4D8F'>
                            {parkingSpace.name}
                          </Typography>
                        }
                        <Typography className='h2'>{spaceName && ` (${spaceName.name}) `} </Typography>
                        <div className='ExchangeSpaceSubtitle'>
                          {/* {parkingLot.name } */}
                          {<Typography className='body'>  (parkingLotNames || parkingLotName) </Typography>
                        && <ListExpanded names={parkingLotNames} name={parkingLotName}></ListExpanded>}
                        </div>
                      </Grid>

                      <Grid item >
                        <Box>
                          {onBookSpace && canEdit && <div className='pb-10'>
                            {onBookSpace && canEdit &&
                              <Button className="actionBtn" aria-label="Delete" onClick={() => onBookSpace(exchange, parkingSpace.name, parkingLotName)}>
                                <Typography className="shareBtn-text">{changeTitle}</Typography>
                              </Button>
                            }
                          </div>
                          }
                          {onBookSpace && isNew && <div className='pb-10'>
                            {onBookSpace && isNew &&
                              <Button className="actionBtn" aria-label="Delete" onClick={() => onBookSpace({ ...parkingSpace, ...exchange }, { vacantTo })} style={{ backgroundColor: '#27348A' }}>
                                <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: 'white' }} />
                              </Button>
                            }
                          </div>
                          }
                          {onDelete && <div className='pb-10'>
                            {onDelete && !locked &&
                              <DeleteActionButton
                                openit={false}
                                onConfirm={() => onDelete(exchange.id)}
                              />
                              // <Button className="actionBtn" aria-label="Delete" onClick={() => onDelete(exchange.id)}>
                              //   <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                              // </Button>
                            }
                            {onDelete && locked &&
                              <IconButton className="delete-btn" aria-label="Delete">
                                <FontAwesomeIcon icon={faUserLock} size="xs" />
                              </IconButton>
                            }
                          </div>
                          }
                        </Box>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        }
        <Divider />
      </React.Fragment>
    )
  }
}


export default ExchangesListItem;
