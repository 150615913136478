import { Grid, Typography } from '@mui/material';
import i18next from 'i18next';

const ActiveInfo = (props) => {
  const {activeInfo, spaces} = props;
  let usedDays = 'n/a';
  let sharedDays = 'n/a';
  let showShared = false;
  let amount = 'n/a' ;

  if (activeInfo){
   usedDays = activeInfo.usedDays + activeInfo.notUsedDays;
   amount = activeInfo.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g);
   if (spaces.length >=1 || activeInfo.sharedDays >= 1 ){
     showShared = true;
     sharedDays = activeInfo.sharedDays;
   }
  }

 return(
   <>
     <Grid container padding={2}>
       <Grid item xs={12} sx={{ background: '#F0F2F6', py: 1.5, px: 2 , borderRadius: '10px', border: '2px solid #F0F2F6'}}>
         <Grid item container alignContent='center' justifyContent='center' direction = 'row' >
           <Grid container xs = {4} alignContent='center' justifyContent='center' direction ='column' sx={{borderRight : '2px solid #E7EAEF'}}  >
             <Grid item alignContent='center' justifyContent='center'>
               <Typography className= 'titleInfo'> {i18next.t('ActiveInfo.StoodFor')} </Typography>
             </Grid>
             <Grid item >
               <Grid container  justifyContent='center'>
                 <Typography className='titleInfoBig'> {usedDays}  </Typography>
                 <Typography className = 'titleInfoSmall'>d.</Typography>
               </Grid>
             </Grid>
           </Grid>

           {(showShared ) && (<Grid container xs={4} alignContent='center' justifyContent='center' direction='column'
                  sx={{ borderRight: '2px solid #E7EAEF' }}>
             <Grid item alignContent='center' justifyContent='center'>
               <Typography className='titleInfo'> {i18next.t('ActiveInfo.Shared')} </Typography>
             </Grid>
             <Grid item>
               <Grid container justifyContent='center'>
                 <Typography className='titleInfoBig'> {sharedDays} </Typography>
                 <Typography className='titleInfoSmall'>d.</Typography>
               </Grid>
             </Grid>
           </Grid>
           )}
       <Grid container xs ={4} alignContent='center' justifyContent='center' direction ='column'>
         <Grid item alignContent='center' justifyContent='center'>
           <Typography className= 'titleInfo' paddingLeft={2}> {i18next.t('ActiveInfo.Pay')} </Typography>
         </Grid>
         <Grid item >
           <Grid container  justifyContent='center'>
             <Typography className='titleInfoBig'>{amount}</Typography>
             <Typography className = 'titleInfoSmall'>EUR</Typography>
           </Grid>
         </Grid>
       </Grid>
         </Grid>
       </Grid>
     </Grid>

   </>
 ) ;
};
export default ActiveInfo;