import { Slider, SliderThumb, styled } from '@mui/material';
import { ReactComponent as SliderIcon } from '../../../assets/icons/publicApp/Slide_btn_content.svg';

const StyledSlider = styled(Slider)(() => ({
  position: 'absolute',
  bottom: '111px',
  width: '70%',
  left: '15%',
  pointerEvents: 'none !important',
  animation: 'bounce 5s ease-in-out infinite 1.5s',
  '@keyframes bounce': {
    '10%, 35%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(10px)' },
  },
  '&:active': {
    animation: 'none',
  },
  '& .MuiSlider-rail': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    display: 'none',
  },
  '& .MuiSlider-thumb': {
    pointerEvents: 'all !important',
    backgroundColor: 'transparent',
    width: 60,
    height: 60,
    borderRadius: '0.75rem',
    background: '#3EC98E',
    '& .p-letter': {
      height: '100%',
      width: '100%',
    },
    '&.Mui-active': {
      boxShadow: 'none !important',
    },
    '&:active': {
      pointerEvents: 'none !important',
    },
    '&:before': {
      boxShadow: 'none'
    },
  },
}));

const PaySliderComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other} sx={{ boxShadow: 'none !important' }}>
      {children}
      <div style={{ padding: '2px 0 0 2px' }}>
        <SliderIcon />
      </div>
    </SliderThumb>
  );
};

const PaySlider = (props) => {
  const { children, ...other } = props;
  return (
    <StyledSlider slots={{ thumb: PaySliderComponent }} {...other}>
      {children}
    </StyledSlider>
  );
};

export default PaySlider;
