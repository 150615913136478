import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import  './Text.scss';


import {InputBase, Box, FormControl, InputLabel, Typography} from '@mui/material';

export class InputText extends Component {

  render() {
    const {
      placeholder, onChange, onClick, id, label, type, value, onBlur, validator
      ,disabled
    } = this.props;
    return (
    <Box className='form-controll'>  
    <FormControl  fullWidth>
       <InputLabel 
        htmlFor={id}
        shrink={true}
        className={`input-label ${validator && 'error'}`}
      >{label}</InputLabel>
      <Box className="input">
        <InputBase
          onClick={onClick}
          id={id}
          disabled={disabled}
          autoComplete="off"
          value={value}
          error={validator ? true : false}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          inputlabelprops={{
            shrink: true,
          }}
          className="input__text"  
        />
      </Box>
      <Typography className="input-error" component={'span'}>{validator}</Typography>
      
    </FormControl>
    </Box>


 
    );
  }
}