import React, { Component } from 'react';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { getCustomers, setCustomer } from 'utils/AdministrationService';
import { FormControl, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import './Customers.scss'; 

class Customers extends Component {
    constructor() {
        super()
        this.state = {
            customers: [],
            active: null,
            saving: false
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        getCustomers().then((customers) => {
            this.setState({customers});
            const active = customers.find(x => x.isActive).customerId;
            this.setState({active});
        })
    }

    onValueChange = async (item) => {
        this.setState({ active: item.target.value, saving: true })
        setCustomer(item.target.value, this.props.currentUser.id).then(() => {
            sessionStorage.clear();
            this.props.fetchSettings();
            this.props.fetchCurrentUser();
            this.setState({ saving: false });
        })
    }

    render() {
        const { t } = this.props;
        const { customers, active, saving } = this.state;
        return (
            <div>
                <Topbar title={t("administration.Customers")} />
                <div className="customers-container">
                    {active && (
                        <FormControl>
                            <RadioGroup
                                value={active}
                                onChange={this.onValueChange}
                            >
                                {customers.map((item) => (
                                    <FormControlLabel key={item.customerId} disabled={saving} value={item.customerId} control={<Radio />} label={item.customerName} />
                                ))}
                            </RadioGroup>
                        </FormControl>)}
                </div>
            </div>
        )
    }
}
export default withTranslation()(Customers)
