import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import  './Search.scss';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons';


import {InputBase, Box, IconButton} from '@mui/material';

export class InputSearch extends Component {

  render() {
    const {placeholder, onChange} = this.props;
    return (      
      <Box className="input">
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          className="input__text"  
        />
        <IconButton
          className="input__icon"  
          aria-label="Search">
        <FontAwesomeIcon icon={faSearch} />
        </IconButton>
 
      </Box>        
    );
  }
}