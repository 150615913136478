import React, { Component } from 'react';
import { Box, CircularProgress, Grid, Typography, Button } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { HubConnectionBuilder } from '@microsoft/signalr'
import { getHubPath } from 'utils/ProxyPathService';
import { confirmPayment, postError } from 'utils/PaymentsService';
import x_circle from '../../../../assets/icons/publicApp/x-circle.svg';
import check_circle from '../../../../assets/icons/publicApp/check-circle.svg';
import { getPaymentStatus } from 'utils/KevinService';

class PaymentConfirm extends Component {
    constructor() {
        super();
        this.state = {
            success: false,
            loading: true,
            paymentStatus: null,
            hasConfirm: false,
            statusTitle: 'publicApp.StatusTitleLoading',
            resultTitle: 'publicApp.PaymentResultLoading',
            exitTimeM: 15,
            payment: null
        }
        this.timer = {}
    }

    componentDidMount = async () => {
        const url = new URL(window.location.href);
        const exitTimeM = url.searchParams.get("exitTimeM");
        const paymentId = url.searchParams.get("paymentId");
        const statusGroup = url.searchParams.get("statusGroup");
        const cardStatus = url.searchParams.get("cardStatus");
        const bankStatus = url.searchParams.get("bankStatus");

        if(exitTimeM){
            this.setState({exitTimeM});
        }

        const hubConnection = new HubConnectionBuilder()
            .withUrl(getHubPath()).withAutomaticReconnect()
            .build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection.start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection.on("ReceiveMessage", data => {
                if (data === paymentId) {
                    this.setState({ loading: false });
                    this.setState({ success: true });
                    this.setState({ statusTitle: 'publicApp.StatusTitleSuccess', resultTitle: 'publicApp.PaymentResultSuccess' });
                }
            });
        });

        if (paymentId) {
            const payment = {
                id: paymentId,
                bankStatus: bankStatus,
                cardStatus: cardStatus,
                statusGroup: statusGroup
            }
            const success = payment.statusGroup === 'completed';
            confirmPayment(payment).then(() => {
                if (success) {
                    this.setState({ statusTitle: 'publicApp.StatusTitleSuccess', resultTitle: 'publicApp.PaymentResultSuccess' })
                    this.setState({ success: success, loading: false });
                }
                else {
                    if (statusGroup === 'failed') {
                        this.setState({ loading: false, statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
                    } else {
                        this.timer = setInterval(
                            () => this.checkPaymentStatus(paymentId),
                            5000
                        );
                    }
                }
            });
        }else{
            this.goMap();
        }
    }

    checkPaymentStatus = async (paymentId) => {
        if (!this.state.loading) {
            getPaymentStatus(paymentId).then((payment) => {
                if (payment) {
                    console.log(payment)
                    const success = payment.statusGroup === 'completed' || payment.group === 'completed';
                    if (success) {
                        const payment = {
                            id: paymentId,
                            bankStatus: payment.bankStatus,
                            cardStatus: payment.cardStatus,
                            statusGroup: 'completed'
                        }
                        confirmPayment(payment).then(() => {
                            this.setState({ statusTitle: 'publicApp.StatusTitleSuccess', resultTitle: 'publicApp.PaymentResultSuccess' })
                            this.setState({ success: success, loading: false });
                        })
                    }
                } else {
                    this.setState({ loading: false, statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
                }
            }).catch((error) => {
                postError(paymentId, error).then(() => {
                    this.setState({ loading: false, statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
                });
            });
        }
    }

    startTimer = () => {
        this.timer = setInterval(this.checkPaymentStatus, 5000);
    };

    goMap = () => {
        localStorage.removeItem('currentPath');
        this.setState({loading : false});
        this.props.history.push({
            pathname: '/publicMap'
        });
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        if (this.state.hubConnection) {
            this.state.hubConnection.stop();
        }
    }

    render() {
        const { t } = this.props;
        const { success, loading, statusTitle, resultTitle, exitTimeM } = this.state;
        return (
            <div className='public-form'>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '75vh' }}
                >
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading && (
                                <CircularProgress
                                    size={80}
                                    thickness={2}
                                    color='primary'
                                />
                            )}
                            {!loading && !success &&
                                (<Box >
                                    <img src={x_circle} />
                                </Box>)}
                            {!loading && success &&
                                (<Box >
                                    <img src={check_circle} />
                                </Box>)}
                        </Box>
                    </Grid>
                    <Box sx={{ textAlign: 'center', pl: 1, pr: 1, pt: 15 }}>
                        <Typography sx={{ pb: 1 }} variant='h4' className='h4-default' gutterBottom>
                            {t(statusTitle)}
                        </Typography>
                        <Typography sx={{ pb: 1 }} variant='h4' className='h4-default' gutterBottom>
                            {t(resultTitle, { exit_time: exitTimeM })}
                        </Typography>
                    </Box>
                </Grid>
                {/* {!loading && ( */}
                <Box className='bottom-button' sx={{ pr: 3, pl: 3, pb: 3 }}>
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        className='auth-button'
                        onClick={() => this.goMap()}
                    >
                        {t('publicApp.Return')}
                    </Button>
                </Box>
                {/* )} */}
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(PaymentConfirm));