import React from 'react';
import './VehiclesOwnerListItem.scss';

import {
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Box,
  Grid,
} from '@mui/material';
import DeleteActionButton from '../../../uiactions/actionbuttons/DeleteActionButton';

const VehiclesOwnerListItem = (props) => (
  <React.Fragment>
    <ListItem
      className="itemContainer"
      button
      // onClick={() => props.onClick({...props.vehicle})}
    >
      <Grid item container>
        <Grid item paddingLeft="1rem">
          <Box className="plate-container">
            <Typography className="h2">{props.vehicle.plateNum}</Typography>
          </Box>
          {/* <ListItemText
        className="vehicle-name"
        primary={`${props.vehicle.make.name} ${props.vehicle.model.name}`} /> */}
        </Grid>
        <Grid>
          <ListItemSecondaryAction>
            <DeleteActionButton
              openit={false}
              onConfirm={() => props.onDelete(props.vehicle.id)}
              text={props.vehiclesAmount === 1 && (props.activeSubscriptionsAmount || props.activeBookingsAmount) ? props.activeSubscriptionsAmount ? 'confirm.deleteVehicleAndBookingsAndSubscriptions' : 'confirm.deleteVehicleAndBookings' : undefined}
            />
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    </ListItem>
  </React.Fragment>
);

export default VehiclesOwnerListItem;
