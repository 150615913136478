import React, {Component} from 'react';
import './ExchangesBookingList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import { List, Divider, ListItem } from '@mui/material';
import ExchangesBookingItem from './item/ExchangesBookingItem';
class ExchangesBookingList extends Component {

  render() {
    const {
      t, 
      onCreate,
      onDelete, 
      bookedSpaces, 
      history,
      needLoad,
      load,
      title,
      shared,
      canEdit,
      onBookSpace,
      parkingSpaces,
      parkingLots,
      readOnly
    } = this.props;

    let listHeadTitle = shared ? t('exchanges.ShareBookings') : t('exchanges.Bookings');
    let changeTitle = t('exchanges.Change');

    if( title ){
      listHeadTitle = title;
    }
    

    return (
      <React.Fragment>
      { !history && <ListHead title={listHeadTitle} />
       } <List>
          {bookedSpaces && bookedSpaces.map( (bookedSpace, i) => (
            <React.Fragment
            key={bookedSpace.id}
            >
              <ExchangesBookingItem
                shadow={true}
                onDelete={onDelete}
                bookedSpace={bookedSpace}
                onBookSpace={onBookSpace}
                canEdit={!bookedSpace.autoCreatedDt && canEdit}
                changeTitle={changeTitle}
                parkingSpaces={parkingSpaces}
                parkingLots={parkingLots}
                readOnly={readOnly}
                />
                <Divider />
            </React.Fragment>
          ))}
          {needLoad &&
            <ListItem
            key="more"
            onClick={() => load(true)}
            className="LoadMore"
            >
            <p style={{ textAlign: "center" }}>
              <b>{t('general.LoadMore')}</b>
            </p>
            </ListItem>
          }
        </List>
      </React.Fragment>
      );

  }
}
export default withTranslation()(ExchangesBookingList);
