import React, { Component } from 'react';
import './ListHead.scss';
import { IconButton, Grid, Container, Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus , faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

class ListHead extends Component {

  render() {
    return (
      <Container className={this.props.smallTitle ? "ListHead-small" : "ListHead"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
            <Grid item xs>
            {this.props.smallTitle ? <Typography className="Title-small">{this.props.title}</Typography> : <h3>{this.props.title}</h3>}
            </Grid>

            <Grid item sx={{py: 1.5 , px: 1  }}>
           {this.props.onReassign &&
           <IconButton className="add-btn" aria-label="Add" onClick={this.props.onReassign}>
               <FontAwesomeIcon icon={faExchangeAlt} color = "#3A4D8F" size="xs" />
           </IconButton>
            }
            </Grid>

            {this.props.onCreate &&
            <Grid item sx={{py: 1.5 , px: 1  }}>
            <IconButton className="add-btn" aria-label="Add" onClick={this.props.onCreate}>
              <FontAwesomeIcon icon={faPlus} color = "#3A4D8F" size="xs" />
            </IconButton>
            </Grid>}

        </Grid >
      </Container>
    );
  }
}
export default ListHead;
