import React from 'react';
import './Buttons.scss';
import { Grid } from '@mui/material';

const RadioButton = ({ ...rest }) => {
  return (
    <Grid container justifyContent='center' alignItems="center">
      <label className="radio-control">
        <input {...rest} type="radio" name="radio" />
      </label>
    </Grid>
  );
};

export default RadioButton;