import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HubConnectionBuilder } from '@microsoft/signalr';

import AnnouncementDialog from 'components/ui/dialogs/AnnouncementDialog';
import { checkForAnnouncement, postUserReadAnnouncements } from 'utils/AnnouncementsService';
import { getHubPath } from 'utils/ProxyPathService';
import { getCurrentUser } from 'utils/UsersService';

const DisplayAnnouncement = (props) => {
  const { needCheckForAnnouncement } = props;
  const { i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [displayedAnnouncementPosition, setDisplayedAnnouncementPosition] = useState(0);
  const [announcements, setAnnouncements] = useState(null);

  useEffect(() => {
    if (needCheckForAnnouncement) {
      checkForAnnouncementFn();
    }

    const hubConnection = new HubConnectionBuilder().withUrl(getHubPath()).withAutomaticReconnect().build();

    hubConnection
      .start()
      .then(() => console.log('Announcement Connection started!'))
      .catch((err) => console.log('Error while establishing connection :('));

    hubConnection.on('ReceiveMessage', (data) => {
      if (data === 'checkAnnouncement') {
        checkForAnnouncementFn();
      }
    });

    return () => {
      if (hubConnection) {
        hubConnection
          .stop()
          .then(() => console.log('connection closed'))
          .catch(() => console.log('failed to close'));
      }
    };
  }, []);

  const checkForAnnouncementFn = async () => {
    const currentUser = await getCurrentUser();
    setDisplayedAnnouncementPosition(0);
    const userId = currentUser.id;

    const announcementsFromDb = await checkForAnnouncement(userId);

    if (announcementsFromDb && announcementsFromDb.length > 0) {
      let _announcements = [];
      announcementsFromDb.forEach((item) => {
        if (item.translations.length > 0) {
          const currentLanguage = i18n.language;

          const selectedTranslation = item.translations.find((translation) => translation.locale.toLowerCase() === currentLanguage);
          if (selectedTranslation) {
            const newAnnouncement = {
              id: item.id,
              title: selectedTranslation.title ?? '',
              content: selectedTranslation.content ?? '',
            };
            _announcements.push(newAnnouncement);
          }
        }
      });

      setAnnouncements(_announcements);
      setShow(true);
    }
  };

  const moveNext = () => {
    setDisplayedAnnouncementPosition(displayedAnnouncementPosition + 1);
  };

  const handleClose = async () => {
    const readAnnouncementIds = announcements.map((item) => item.id);
    const currentUser = await getCurrentUser();
    await postUserReadAnnouncements(readAnnouncementIds, currentUser.id);
    setShow(false);
  };

  return (
    <>
      {announcements && announcements[displayedAnnouncementPosition] && (
        <AnnouncementDialog
          show={show}
          title={announcements[displayedAnnouncementPosition].title}
          content={announcements[displayedAnnouncementPosition].content}
          hasNext={displayedAnnouncementPosition < announcements.length - 1}
          moveNext={moveNext}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default DisplayAnnouncement;
