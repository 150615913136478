import React from 'react';
import { ListItem, Typography, Divider, Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';

import './Announcements.scss';
import DeleteActionButton from '../uiactions/actionbuttons/DeleteActionButton';
import { getAnnouncementById } from 'utils/AnnouncementsService';


const AnnouncementItem = ({ item, deleteAnnouncement }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onEdit = async () => {
    const fullItem = await getAnnouncementById(item.id);
    history.push({
      pathname: '/announcements/form',
      state: { announcement: fullItem, isEditType: true },
    });
  };

  return (
    <>
      <ListItem button>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10} md={11} pl={1}>
            <Typography className="h2">{item.name}</Typography>
          </Grid>

          <Grid item container xs={2} md={1} direction='column' alignItems="flex-end">
            <Grid pb={1}>
              <Button id="btn-delete" className="action-btn" aria-label="Delete" onClick={() => onEdit(item)}>
                <Typography className="share-btn-text">{t('exchanges.Change')}</Typography>
              </Button>
            </Grid>
            <Grid>
            <DeleteActionButton openit={false} onConfirm={() => deleteAnnouncement(item.id)} />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

export default AnnouncementItem;
