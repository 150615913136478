import { axiosA } from './AuthService';

const postReassignSpaces = async (body) => {
    // console.log(body);
    try {
        const { data } = await axiosA.post('/ParkingSpaces/ReassignSpaces', body);
        return data
    } catch (e) {
        new Error(e);
    }
    // return 'zz';
}

const postDeleteSpaces = async (body) => {

    try {
        const { data } = await axiosA.post('/ParkingSpaces/DeleteSpaces', body);
        return data
    } catch (e) {
        new Error(e);
    }
}

export {
    postReassignSpaces,
    postDeleteSpaces
}
