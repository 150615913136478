import React, { useEffect, useState } from 'react';
import { Grid, Typography } from "@mui/material";
import MomentUtils from '@date-io/moment';
import i18next from 'i18next';

import { currencyFormat, countWorkDays, getVacantPeriods } from "../../../utils/PricingHelpers";
import info from "../../../assets/icons/info.svg";
import './PriceInfoCard.scss';

const moment = new MomentUtils({ locale: 'lt' });

const PriceInfoCard = (props) => {
    const { type, dateFrom, dateTo, bookedSpace, price, freedays, inlcudeWeekendsInSharing, excludesList } = props;
    const [dayPrice, setDayPrice] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [listOfVacantPeriods, setListOfVacantPeriods] = useState([]);

    const formatFrom = moment.date(dateFrom).format('YYYY-MM-DD');
    const formatTo = moment.date(dateTo).format('YYYY-MM-DD');
    let price_ = 0;

    useEffect(() => {
        fetchInfo();
    }, [price,
        dateFrom,
        dateTo,
        listOfVacantPeriods
    ]);

    useEffect(() => {
        if (type === 'booking' && excludesList && excludesList.length > 0) {
            const list = getVacantPeriods(dateFrom, dateTo, excludesList, inlcudeWeekendsInSharing);
            setListOfVacantPeriods(list);
        }
    }, [excludesList, dateFrom, dateTo])


    const fetchInfo = () => {
        if (price === null) {
            if (bookedSpace && bookedSpace.price != null) {
                price_ = bookedSpace.price
            }
        } else {
            price_ = (price > 0 || price === 0) ? price : 0;
        }

        const formatRate = currencyFormat(price_);
        setDayPrice(formatRate);

        let days = 0;

        if (listOfVacantPeriods.length > 1) {
            listOfVacantPeriods.forEach((period) => {
                const daysPerPeriod = countWorkDays(period.from, period.to, freedays, inlcudeWeekendsInSharing)
                days += daysPerPeriod;
            });
        } else {
            days = countWorkDays(dateFrom, dateTo, freedays, inlcudeWeekendsInSharing);
        }

        let countdays = days;
        const total = price_ * (countdays);
        const formatPrice = currencyFormat(total);
        setTotalPrice(formatPrice);
    }

    switch (type) {

        case 'booking': {
            return (
                <React.Fragment>
                    <Grid item xs={10}>
                        <Grid item alignItems="center" sx={{
                            background: '#ffffff',
                            borderRadius: '10px',
                            borderColor: '#E6E9EE',
                            border: '1px solid #E6E9EE'
                        }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                py={2}
                            >
                                <Grid container direction="column" justifyContent="center"
                                    alignItems="center">
                                    <img src={info} alt="background" />
                                    <Typography py={1} variant='inherit' className='local-h5'>
                                        {i18next.t('PriceInfoCard.DailyPrice')} {`${dayPrice}`}
                                    </Typography>
                                    <Typography variant='inherit' className='local-h5'>
                                        {i18next.t('PriceInfoCard.ForPeriod')}
                                    </Typography>
                                    <Grid py={1}>
                                        {listOfVacantPeriods.length > 1 ? listOfVacantPeriods.map((period, index) => (
                                            <Typography key={`period-${index}`} variant='inherit' className='local-h5'>
                                                {`${moment.date(period.from).utc(0).format('YYYY-MM-DD')} `} - {`${moment.date(period.to).utc(0).format('YYYY-MM-DD')} `}
                                            </Typography>
                                        )) : (
                                            <Typography variant='inherit' className='local-h5'>
                                                {`${formatFrom} `} - {`${formatTo} `}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Typography variant='inherit' className='local-h5 bold-text'>
                                        {i18next.t('PriceInfoCard.AmountToBePaid')}
                                    </Typography>
                                    <Typography variant='inherit' className='local-h5 bold-text'>
                                        {`${totalPrice}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        case 'exchange': {
            return (
                <React.Fragment>
                    <Grid item xs={10}>
                        <Grid item alignItems="center" sx={{
                            background: '#ffffff',
                            borderRadius: '10px',
                            borderColor: '#E6E9EE',
                            border: '1px solid #E6E9EE'
                        }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                py={2}
                            >
                                <img src={info} alt="background" />
                                <Typography py={1} variant='inherit' className='local-h5'>
                                    {i18next.t('PriceInfoCard.DailyPrice')} {`${dayPrice}`}
                                </Typography>
                                <Typography variant='inherit' className='local-h5'>
                                    {i18next.t('PriceInfoCard.ForSharedPeriod')}
                                </Typography>
                                <Typography py={1} variant='inherit' className='local-h5'>
                                    {`${formatFrom} `} - {`${formatTo} `}
                                </Typography>
                                <Typography variant='inherit' className='local-h5 bold-text'>
                                    {i18next.t('PriceInfoCard.WillBeRefunded')}
                                </Typography>
                                <Typography variant='inherit' className='local-h5 bold-text'>
                                    {`${totalPrice}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        default:
            return null
    }
};
export default PriceInfoCard;

