import React, {Component} from 'react';
import './Exchanges.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import ExchangesListItem from './list/item/ExchangesListItem';
import { List } from '@mui/material';
import { historySharedSpacesList } from '../../utils/ParkingService';
import { withSnackbar } from 'notistack';
import MomentUtils from "@date-io/moment";


import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";

const fetchSharedSpacesDebounced = AwesomeDebouncePromise(historySharedSpacesList, Api.SEARCH_DEBOUNCE);

const moment = new MomentUtils({locale: 'lt'})

class ExchangesHistory extends Component {
  constructor(){
    super()
    this.state = {
      sharedSpaces: [],
    }
  }

  fetchSharedSpaces = async (offset, loadingMore) => {   

    let hasMore = false;
    const resp = await fetchSharedSpacesDebounced(null, offset, -1);

    if(resp.nextOffset) hasMore = true;

    if(!loadingMore) {
      this.setState(prevState => ({sharedSpaces: [...resp.items], nextOffset: resp.nextOffset, count: resp.items, hasMore}));
    }else {
      this.setState(prevState => ({sharedSpaces: [...new Set([...prevState.sharedSpaces, ...resp.items])], nextOffset: resp.nextOffset, count: resp.items, hasMore}))
    }
    
  }

  fetchInfo = async  () => {
    await this.fetchSharedSpaces(0, false);
  }
  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    await this.fetchInfo()
  }


  render() {
    const { t, isAdmin} = this.props;
    const { 
      sharedSpaces } = this.state;


    return (
      <React.Fragment>
        <Topbar title={t('exchanges.History')} location={this.props.location} />
        <div className="ExchangesHistoryContainer">
 


          <List className="ExchangesList" >
            <InfiniteScroll
            dataLength={sharedSpaces.length}
            hasMore={(this.state.hasMore)}
            next={()=> this.fetchSharedSpaces(this.state.nextOffset, true)}
            loader={
            <p>{t('general.loading')}...</p>
            }> { sharedSpaces.length > 0 && sharedSpaces.map((e, i) =>  (
              
              <ExchangesListItem 
              key={e.id}
              exchange={e}
              locked={!isAdmin && e.isReadOnly}
              onDelete={false}
              onBookSpace={false}
              />
              
              )
              )
              
            }
          </InfiniteScroll>
          </List>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(ExchangesHistory))