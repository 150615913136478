import { axiosA } from './AuthService';
import { NotificationManager } from 'react-notifications';
import i18next from 'i18next';

const sendReport = async (from, to, parkingLotId, email) => {
	let url = '?1=1'
	if (from) url += `&From=${from}`
	if (to) url += `&To=${to}`
	if (parkingLotId) url += `&ParkingLotId=${parkingLotId}`
	if (email) url += `&Email=${email}`

	try {
		const { data } = await axiosA.get(`/SharedSpaces/Stats/Export/${url}`)
		NotificationManager.success(i18next.t('notification.success.reportSended'));
		return data
	} catch (e) {
		new Error(e);
	}
}

const sendMonthlyReport = async (sendTo, month, userId, email, type) => {
	let url = `?sendTo=${sendTo}`
	if (month) url += `&month=${month}`
	if (userId) url += `&userId=${userId}`
	if (email) url += `&email=${email}`

	try {
		const { data } = await axiosA.get(`/Report/${type}/${url}`)
		NotificationManager.success(i18next.t('notification.success.reportSended'));
		return data
	} catch (e) {
		new Error(e);
	}
}

const getActionReportUsers = async () => {
	try {
		const { data } = await axiosA.get(`/Report/ActionReportUsers`);
		return data;
	} catch (e) {
		new Error(e);
	}
}

const getActionReportSpaces = async () => {
	try {
		const { data } = await axiosA.get(`/Report/ActionReportSpaces`);
		return data;
	} catch (e) {
		new Error(e);
	}
}

const sendActionReport = async (body) => {
	try {
		const { data } = await axiosA.post('/Report/ActionReport', body);
		NotificationManager.success(i18next.t('notification.success.reportSended'));
		return data
	} catch (e) {
		new Error(e);
	}
}

export {
	sendReport,
	sendMonthlyReport,
	getActionReportUsers,
	getActionReportSpaces,
	sendActionReport
}