import React from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Box,
    Button,
    Grid
} from '@mui/material';
import i18next from 'i18next';

import DeleteActionButton from 'components/uiactions/actionbuttons/DeleteActionButton';

const ParkingGroupItem = (props) => {
    const { group, deleteGroup, t } = props;
    const changeTitle = i18next.t('exchanges.Change');

    const onChange = (item) => {
        props.history.push({
            pathname: '/parkingGroup/form',
            state: { group: item, isChange: true }
        });
    };

    return (

        <React.Fragment>
            <ListItem
                className="itemContainer"
                button
            >
                <Grid item container sx={{ py: 1.5, px: 2 }}>
                    <Grid item paddingLeft='1rem'>
                        <Grid item container>
                            <Typography className='h2'>
                                {group.title}
                            </Typography>
                            {/*<h3>{props.vehicle.plateNum}</h3>*/}
                        </Grid>
                    </Grid>

                    <Grid sx={{ py: 1.5, px: 2 }}>
                        <ListItemSecondaryAction>
                            <div className="pb-10">
                                <Button className="actionBtn" aria-label="Delete" onClick={() => onChange(group)} >
                                    <Typography className="shareBtn-text">{changeTitle}</Typography>
                                </Button>
                            </div>
                            <div>
                                {<DeleteActionButton
                                    openit={false}
                                    onConfirm={() => deleteGroup(group.id)}
                                />}
                            </div>
                        </ListItemSecondaryAction>
                    </Grid>

                </Grid>

            </ListItem>
            <Divider />

        </React.Fragment>

    );
}
export default ParkingGroupItem;