import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MonthInformation from 'components/ui/calendar/calendar/MonthInformation';
import { getDateStatuses } from 'utils/UsersService';
import { List, Typography, Grid } from '@mui/material';
import "./PeriodInfo.scss";
import { HubConnectionBuilder } from '@microsoft/signalr'
import TerritoryStatus from "../calendar/status/TerritoryStatus";
import { featureEnabled } from 'utils/FeatureTogglerService';
import { AUTO_MATCH, SHOW_CALENDAR } from 'constants/Features';
import { getHubPath } from 'utils/ProxyPathService';
import i18next from 'i18next';
import BusinessParking from './payment/BusinessParking';

class PeriodInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      hubConnection: null,
      defaultDate: null,
      index: 0,
      periods: [],
      showCalendar: [],
      autoMatchFeatureEnabled: false,
      show_calendarEnabled: true,
      paymentResponse: null,
      paymentCompleted: false,
      paymentError: false,
      paymentData: null,
      businessPaymentData: null
    };
    this.timer = {};
  }

  componentDidMount = async () => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(getHubPath()).withAutomaticReconnect()
      .build();

    this.setState({ hubConnection }, () => {
      this.state.hubConnection.start()
        .then(() => console.log('Connection started!'))
        .catch(err => console.log('Error while establishing connection :('));

      this.state.hubConnection.on("ReceiveMessage", data => {
        if (data === "dashboard") {
          this.fetchDateStatuses(this.props.currentUserId);
        }
      });
    });

    const autoMatch = await featureEnabled(AUTO_MATCH);
    this.setState({ autoMatchFeatureEnabled: autoMatch });

    const showCalendar = await featureEnabled(SHOW_CALENDAR);
    this.setState({ show_calendarEnabled: showCalendar });

    await this.fetchDateStatuses(this.props.currentUserId);
  }

  componentWillUnmount = async () => {

    if (this.state.hubConnection) {
      this.state.hubConnection.stop();
    }
  }

  fetchDateStatuses = async (userId) => {
    try {
      const resp = await getDateStatuses(userId);
      if (resp) {
        const showCalendar = resp.map((x) => { return false });
        this.setState({ info: resp, showCalendar });
        if (this.state.info.length > 0) {
          if (this.state.info[0].ownerSpaces && this.state.info[0].ownerSpaces.length > 0) {
            const periods = this.state.info[0].periods.filter(x => x.parkingSpaceId === this.state.info[0].ownerSpaces[0].ownerSpaceId);
            this.setState({ periods: periods })
          } else {
            this.setState({ periods: this.state.info[0].periods })
          }
        }
      }

    } catch (e) {
      console.error(e)
    }
  }

  getMonth = async (date) => {

    this.setState({ defaultDate: date });
  }

  changeIndex = async (rise) => {
    let newIndex = 0;
    let stateIndex = this.state.index;
    if (rise === true) {

      newIndex = stateIndex + 1;
      if (this.state.info[0].ownerSpaces && this.state.info[0].ownerSpaces.length > newIndex) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    } else {
      newIndex = stateIndex - 1;
      if (newIndex > -1) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    }
    const periods = this.state.info[0].periods.filter(x => x.parkingSpaceId === this.state.info[0].ownerSpaces[stateIndex].ownerSpaceId);
    this.setState({ periods: periods })
  }

  showCalendarF = (item) => {
    let showCalendar_ = this.state.showCalendar;
    const show = showCalendar_[item];
    showCalendar_[item] = !show;
    this.setState({ showCalendar: showCalendar_ });
  }

  render() {

    const currentLng = localStorage.getItem('currentLng');
    const { navigate, clicked, hideBookingFeatureEnabled, inlcudeWeekendsInSharing, subscriptionPeriodLimit, businessPaymentsEnable, currentUserId } = this.props
    const { info, showCalendar, autoMatchFeatureEnabled, show_calendarEnabled } = this.state;

    const defaultDate = this.state.defaultDate;

    return (

      <div>
        {businessPaymentsEnable && <BusinessParking
          navigate={navigate}
          currentUserId={currentUserId}
        />}
        <div>
          <Grid item xs padding='20px'>
            <Typography className='h4 darkGrey' color='#656D78'>
              {i18next.t('parkinger.CurrentVacancies')}
            </Typography>
          </Grid>
        </div>
        {info.map((item, ix) => (
          <div key={ix}>
            {item ?
              <List style={{ padding: "0" }}>
                {item.lots.map((el, i) => (
                  <div key={i} >
                    <TerritoryStatus
                      item={el}
                      index={ix}
                      showCalendar={this.showCalendarF}
                      show_calendarEnabled={show_calendarEnabled}
                      inlcudeWeekendsInSharing={inlcudeWeekendsInSharing} />
                  </div>
                ))}
              </List>
              : null}
            {item && showCalendar[ix] &&
              <MonthInformation
                item={item}
                navigate={navigate}
                clicked={clicked}
                currentLng={currentLng}
                getMonth={this.getMonth}
                defaultDate={defaultDate}
                // index={index}
                changeIndex={this.changeIndex}
                periods={item.periods}
                autoMatchFeatureEnabled={autoMatchFeatureEnabled}
                hideBookingFeatureEnabled={hideBookingFeatureEnabled}
                inlcudeWeekendsInSharing={inlcudeWeekendsInSharing}
                subscriptionPeriodLimit={subscriptionPeriodLimit}
              ></MonthInformation>
            }
          </div>
        ))}
      </div>
    )
  }
}
export default withTranslation()(PeriodInfo);