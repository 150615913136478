import React from 'react';
import './ParkingRates.scss'
import {
    ListItem,
    ListItemText,
    Typography,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Box,
    Button,
    Grid
} from '@mui/material';
import i18next from 'i18next';

import DeleteActionButton from "../uiactions/actionbuttons/DeleteActionButton";

const ParkingRatesItem = (props) => {

    const { rates, deleteRate } = props;
    const changeTitle = i18next.t('exchanges.Change');

    function currencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' EUR'
    }
    
    const onChange = (item) => {
        props.history.push({
            pathname: '/parkingRates/form',
            state: { rates: item, isChange: true }
        });
    };

    return (

        <React.Fragment>
            <ListItem
                className="itemContainer"
                button
            >
                <Grid item container sx={{ py: 1.5, px: 2 }}>
                    <Grid item paddingLeft='1rem'>
                        <Grid item container>
                            <Typography className='h2'>
                                {rates.title}
                            </Typography>
                            {/*<h3>{props.vehicle.plateNum}</h3>*/}
                        </Grid>
                        <Grid >
                            <Typography className='body'>
                                {currencyFormat(rates.rate)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ py: 1.5, px: 2 }}>
                        <ListItemSecondaryAction>

                            <div className="pb-10">
                                <Button className="actionBtn" aria-label="Delete" onClick={() => onChange(rates)} >
                                    <Typography className="shareBtn-text">{changeTitle}</Typography>
                                </Button>
                            </div>
                            <DeleteActionButton
                                openit={false}
                                onConfirm={() => deleteRate(rates.id)}
                            // parkingLotNames = {parkingLotName}
                            />
                            {/*<Button className="actionBtn" aria-label="Delete"  onClick={() => deleteRate(rates.id)} >*/}
                            {/*    /!*<Button className="actionBtn" aria-label="Delete" >*!/*/}
                            {/*    <FontAwesomeIcon icon={faTrashAlt} color='#3A4D8F' size="xs"/>*/}
                            {/*</Button>*/}
                        </ListItemSecondaryAction>
                    </Grid>

                </Grid>

            </ListItem>
            <Divider />

        </React.Fragment>

    );

}

export default ParkingRatesItem;
