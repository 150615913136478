import React, {Component} from 'react';
import './Reports.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { Grid, Fab, CircularProgress, Box } from '@mui/material';
import InputDate from 'components/ui/inputs/date/InputDate';
import { getParkingLots } from 'utils/ParkingService';
import { InputSelect } from 'components/ui/inputs/select/Select';
import { InputText } from 'components/ui/inputs/text/Text';
import FormValidation from '../../utils/FormValidation';
import { sendReport } from 'utils/ReportsService';

class Reports extends Component {
  constructor() {
    super()
    this.state = {
      from: null,
      to: null,
      email: JSON.parse(sessionStorage.user).email,
      parkingLots: [],
      clicked: false
    }
    this.validator = new FormValidation({
      locale: (localStorage.currentLng.toLowerCase() === 'lt-lt') ? 'lt-LT' : 'en-BG',
      className: 'input-errormsg'
    });

  }

  fetchParkingLots = async () => {
    try {
      const resp = await getParkingLots()
      this.setState({parkingLots: [{id: '', name: this.props.t('spaces.All')}, ...resp.items]})
    }catch (e) {
      console.error(e)
    }
  }
  report = async () => {
    const lotId = this.state.selectedLot ? this.state.selectedLot.id : undefined;

    let fromDate = this.state.from;
    if(fromDate){
      fromDate = fromDate.format('YYYY-MM-DDT00:00:00.000').toString();
    }

    let toDate = this.state.to;
    if(toDate){
      toDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
    }
    this.setState({ clicked: true });
    const resp = await sendReport(fromDate, toDate, lotId, this.state.email)
    this.setState({ clicked: false });
    // dasibaigti
  }
  setLot = (selectedLot) => {
    this.setState({selectedLot})
  }
  handleChange = (event) => {
    this.setState({[event.target.id]: event.target.value});
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.report()
    }
  }
  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    await this.fetchParkingLots()
  }

  applyMinDate(){
    // In case from date is not availble
    return (this.state.from ? this.state.from : new Date(2000, 1, 1) );
  }

  setFromDate(momentDate){
    this.setState({from: momentDate});
    if(this.state.to){
      if(momentDate.isAfter(this.state.to)){
        this.setToDate(momentDate);
      }
    }
  }

  setToDate(momentDate){
    this.setState({to: momentDate});
    if(this.state.from){
      if(momentDate.isBefore(this.state.from)){
        this.setFromDate(momentDate);
      }
    }
  }

  render() {
    const currentLng = localStorage.getItem('currentLng');
    const {t} = this.props;
    const {from, to, parkingLots, selectedLot, email} = this.state;

    return (
      <div>
        <Topbar title={t("reports.Statistic")} location={this.props.location} />
        <form 
          className="ReportsFormContainer"
          onSubmit={this.handleSubmit}
          noValidate
        >
          <Grid container>
            <Grid item xs={12}>
              <InputSelect 
              options={parkingLots}
              label={t('general.ParkingLot')}
              onChange={this.setLot}
              value={selectedLot}
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['id']}
              placeholder={t("spaces.All")} />
            </Grid>
            <Grid item xs={12}>
              <InputDate 
                value={from}
                allowPast={true}
                label={t('exchanges.Begining')}
                id="from"
                onChange={(momentDate) => this.setFromDate(  momentDate )}
                currentLng={currentLng}
              />
            </Grid>
            <Grid item xs={12}>
              <InputDate
                value={to}
                allowPast={true}
                label={t('exchanges.End')}
                id="to"
                onChange={(momentDate) => this.setToDate( momentDate )}
                currentLng={currentLng}
                />
            </Grid>
            <Grid item xs={12}>
              <InputText
                type="email"
                id="email"
                validator={
                  this.validator.message(t('general.Email'), email, 'required|email')}
                onBlur={() => this.validator.showMessageFor(t('general.Email')) }
                value={email}
                onChange={this.handleChange}
                label={t('general.Email')}
                placeholder={t('general.Email')}
              />
            </Grid>
            <Fab 
              className="full-width success-btn" variant="extended" 
              type="submit"
              value="Submit" 
              aria-label="Save"
              disabled={this.state.clicked}
            >
              {this.state.clicked ?
                <Box position="relative" display="inline-flex">
                  <CircularProgress size={30} color={'secondary'} />
                </Box> :
                <Box fontSize={14} textAlign="center">
                  {t('reports.sendMail')}
                </Box>
              }
            </Fab>
          </Grid>
        </form>
      </div>
    );
  }
}
export default withTranslation()(Reports);
