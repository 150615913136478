import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Card, Button, Grid, IconButton, Typography } from '@mui/material';
import { kevin_banks } from 'utils/KevinService';
import BankImg from 'components/public/providers/kevin/BankImg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/publicApp/icon_trash.svg';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import { LINKED_BANK } from 'constants/PaymentTypes';

class LinkBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banks: [],
            bankForDelete: null,
        };
    }

    selectBank = () => {
        this.props.navigate('bankSelection/account');
    };

    componentDidMount = async () => {
        const kevinTokens_ = localStorage.getItem('kevinTokens');
        const kevinTokens = kevinTokens_ ? JSON.parse(kevinTokens_) : [];
        kevin_banks().then((kevinBanks) => {
            if (kevinBanks) {
                const reducedKevinBanks = kevinBanks.reduce((acc, bank) => {
                    acc[bank.id] = bank;
                    return acc;
                }, {});

                const banks = kevinTokens.map((bank) => {
                    const matchingBank = reducedKevinBanks[bank.id];

                    if (matchingBank) {
                        return { ...bank, ...matchingBank };
                    } else {
                        return bank;
                    }
                });
                this.setState({ banks });
            }
        });
    };

    removeKevinToken = (bank) => {
        const newBankList = this.state.banks.filter((item) => item.refreshToken !== bank.refreshToken);
        this.setState({ banks: newBankList });
        localStorage.setItem('kevinTokens', JSON.stringify(newBankList));
    };

    handleSetBankForDelete = (bank) => {
        this.setState({ bankForDelete: bank });
    };

    handleRemoveBankForDelete = () => {
        this.setState({ bankForDelete: null });
    };

    handleDeleteBankPayment = async () => {
        await this.removeKevinToken(this.state.bankForDelete);
        this.deleteSelectedPaymentMethod();
        this.handleRemoveBankForDelete();
    };

    deleteSelectedPaymentMethod = () => {
        const selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
        if (selectedPaymentMethod && selectedPaymentMethod.paymentType === LINKED_BANK && this.state.bankForDelete.id === selectedPaymentMethod.bank.id) {
            localStorage.removeItem('selectedPaymentMethod');
        }
    };

    render() {
        const { t } = this.props;
        const { banks, bankForDelete } = this.state;

        return (
            <div>
                <Card sx={{ mx: 1, p: 2, boxShadow: '0 9px 18px rgba(0,0,0,0.10)' }}>
                    <Typography variant="h4" className="h4-text" sx={{ pl: 1, pb: 1 }}>
                        {t('publicApp.AddYourPaymentType')}
                    </Typography>
                    <Typography className="body2-text" sx={{ pl: 1, pb: 3 }}>
                        {t('publicApp.AddYourPaymentTypeInfo')}
                    </Typography>

                    {banks &&
                        banks.map((bank) => {
                            return (
                                <Card className="selected-card" sx={{ boxShadow: 0, mb: 2 }}>
                                    <Grid sx={{ mx: 1 }} key={bank.id}>
                                        <Grid container alignItems="center" justifyContent="center">
                                            {bank && <BankImg item={bank} />}
                                            <IconButton onClick={() => this.handleSetBankForDelete(bank)} sx={{ position: 'absolute', right: '2.1rem' }}>
                                                <TrashIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            );
                        })}
                    <Button id="btn-link-bank" className="bank-button" onClick={() => this.selectBank()} sx={{ boxShadow: 0, p: 2, px: 2.75, mb: 2 }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Typography variant="h4" className="h4-text-light">
                                {t('publicApp.LinkBank')}
                            </Typography>
                            <FontAwesomeIcon icon={faLink} />
                        </Grid>
                    </Button>
                </Card>

                <PopUpDrawer isWarningMessage header={t('publicApp.DeleteBankPayment')} text={t('confirm.Delete')} showDrawer={bankForDelete !== null} onClose={this.handleCancelDeleteVehicle}>
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item xs={5} mt={2} mb={4}>
                            <Button id="cancel-delete" disableElevation type="button" variant="contained" className="button-secondary-outlined" onClick={this.handleRemoveBankForDelete} sx={{ height: '48px' }}>
                                <Typography variant="string" className="button-text">
                                    {t('publicApp.CancelLogout')}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={5} mt={2} mb={4}>
                            <Button id="confirm-delete" disableElevation type="button" variant="contained" color="secondary" className="button-primary" onClick={() => this.handleDeleteBankPayment()}>
                                <Typography variant="string" className="button-text">
                                    {t('general.Confirm')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </PopUpDrawer>
            </div>
        );
    }
}

export default withSnackbar(withTranslation()(LinkBank));