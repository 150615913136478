import React, { Component } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { kevin_content, receiveToken } from 'utils/KevinService';
import x_circle from '../../../../assets/icons/publicApp/x-circle.svg';
import check_circle from '../../../../assets/icons/publicApp/check-circle.svg';

class AuthorizationConfirm extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            success: null,
            statusTitle: '',
            resultTitle: '',
        }
    }

    componentDidMount = async () => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");

        receiveToken(code).then((tokenData) => {
            if (tokenData) {
                kevin_content(tokenData.refreshToken).then((data) => {
                    if (data) {
                        const result = localStorage.getItem('kevinTokens');
                        if (result) {
                            this.setState({ success: true, statusTitle: 'publicApp.LinkingWasSuccessful', resultTitle: 'publicApp.AfterSuccessfulLinkingManualText' })
                        } else {
                            this.setState({ success: false });
                        }
                        this.setState({ loading: false });
                    }
                }).catch((error) => {
                    this.setState({ loading: false, success: false, statusTitle: 'publicApp.LinkingFailed' });
                    console.log(error.message);
                })
            }
        }).catch((error) => {
            this.setState({ loading: false, success: false, statusTitle: 'publicApp.LinkingFailed' });
            console.error(error);
        })
    }

    goMap = () => {
        this.props.history.push({
            pathname: '/publicMap'
        });
    }

    render() {
        const { t } = this.props;
        const { success, loading, statusTitle, resultTitle } = this.state;
        return (
            <div className='public-form'>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={3} sx={{ pt: 10 }}>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading && (
                                <CircularProgress
                                    size={80}
                                    thickness={2}
                                    color='primary'
                                />
                            )}
                            {!loading && !success &&
                                (<Box >
                                    <img src={x_circle} />
                                </Box>)}
                            {!loading && success &&
                                (<Box >
                                    <img src={check_circle} />
                                </Box>)}
                        </Box>
                    </Grid>
                    <Box sx={{ textAlign: 'center', px: 3, pt: 5 }}>
                        <Typography sx={{ pb: 1 }} variant='h4' className='h4-text' gutterBottom>
                            {t(statusTitle)}
                        </Typography>
                        <Typography sx={{ pb: 1 }} className='body2-text' >
                            {t(resultTitle)}
                        </Typography>
                    </Box>
                </Grid>
                <Box className='bottom-button' sx={{ px: 3, pb: 3 }}>
                    <Button
                        id='btn-return'
                        disableElevation
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        className='auth-button'
                        onClick={() => this.goMap()}
                    >
                        {t('publicApp.Return')}
                    </Button>
                </Box>
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(AuthorizationConfirm));