import React from 'react';
import { Container, IconButton, Grid } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { ReactComponent as MapIcon } from '../../assets/icons/publicApp/footer/icon_map.svg';
import { ReactComponent as MapActiveIcon } from '../../assets/icons/publicApp/footer/icon_map_active.svg';
import { ReactComponent as ParkingIcon } from '../../assets/icons/publicApp/footer/icon_menu_parking.svg';
import { ReactComponent as ParkingActiveIcon } from '../../assets/icons/publicApp/footer/icon_menu_parking_active.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/publicApp/footer/icon_profile.svg';
import { ReactComponent as ProfileActiveIcon } from '../../assets/icons/publicApp/footer/icon_profile_active.svg';
import './PublicFooter.scss';

export function PublicFooter({ isVisible }) {
  const location = useLocation();
  const history = useHistory();
  const animationVariants = {
    hidden: { transform: 'translateY(5rem)' },
    visible: { transform: 'translateY(0rem)' },
  };

  const showAnimation =
    isVisible === undefined
      ? animationVariants.visible
      : isVisible
      ? animationVariants.visible
      : animationVariants.hidden;

  const handleNavigation = (path) => {
    history.push({
      pathname: path,
      state: { showInitAnimation: true },
    });
  };

  return (
    <motion.div
      initial={animationVariants.visible}
      animate={showAnimation}
      variants={animationVariants}
      transition={{ ease: 'easeOut', duration: 0.5 }}
      className="footer-container"
    >
      <Container className="map-footer-blue" sx={{ touchAction: 'none' }}>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={4}>
            <IconButton
              id="nav-public-map"
              onClick={() => handleNavigation('/publicMap')}
            >
              {location.pathname === '/publicMap' ? (
                <MapActiveIcon />
              ) : (
                <MapIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              id="nav-public-parking"
              onClick={() => handleNavigation('/publicParking')}
            >
              {location.pathname === '/publicParking' ? (
                <ParkingActiveIcon />
              ) : (
                <ParkingIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              id="nav-public-profile"
              onClick={() => handleNavigation('/publicSettings')}
            >
              {location.pathname === '/publicSettings' ? (
                <ProfileActiveIcon />
              ) : (
                <ProfileIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}
