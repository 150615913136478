import React, {Component, Fragment} from 'react';
import './ExchangesList.scss';
import { withTranslation } from 'react-i18next';
import ListHead from '../../ui/list/head/ListHead';
import { List, Divider, ListItem } from '@mui/material';
import ExchangesListItem from './item/ExchangesListItem';

class ExchangesList extends Component {

  render() {
    const {
      t, 
      exchanges,
      onDelete,
      smallTitle,
      onBookSpace,
      onCreate,
      history,
      isAdmin,
      needLoad,
      title,
      load,
      canEdit,
      parkingSpaces,
      parkingLots,
      spaceNames
    } = this.props;

    let listHeadTitle = history ? t('exchanges.SharingHistory') : t('exchanges.Sharing');
     let changeTitle = t('exchanges.Change');


    if( title ){
      listHeadTitle = title;
    }
    

    return (
      <Fragment>
      {!history &&
      <ListHead title={listHeadTitle} onCreate={onCreate} smallTitle={smallTitle} />
       } <List>
          
          {exchanges && exchanges.map( (exchange, i) => (
            <React.Fragment
              key={(exchange.id + i)}
            >
              <ExchangesListItem 
                exchange={exchange}
                locked={!isAdmin && exchange.isReadOnly}
                onDelete={onDelete && onDelete}
                onBookSpace={onBookSpace}
                canEdit={canEdit}
                changeTitle={changeTitle}
                parkingSpaces={parkingSpaces}
                parkingLots={parkingLots}
                spaceName={spaceNames ? spaceNames.find(x => x.id === exchange.parkingSpaceId) : null}
                />
       
            </React.Fragment>
          ))}
          {needLoad &&
            <ListItem 
            onClick={() => load(true)}
            className="LoadMore"
            >
            <p style={{ textAlign: "center" }}>
              <b>{t('general.LoadMore')}</b>
            </p>
            </ListItem>
          }
        </List>
      </Fragment>
      );

  }
}
export default withTranslation()(ExchangesList);
