import { axiosA } from './AuthService';

const controllerLink = '/PublicBooking';

const getAvailableBookedSpace = async (parkingLotId, from, to) => {
  let link = controllerLink + `/check?parkingLotId=${parkingLotId}&from=${from}&to=${to}`;

  try {
    const { data } = await axiosA.get(link);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const getUserActiveBookings = async () => {
  let link = controllerLink + '/active';

  try {
    const { data } = await axiosA.get(link);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const createPublicBooking = async (subscriptionId, from, to, plateNum, parkingLotId, amount) => {
  const body = {
    subscriptionId: subscriptionId,
    bookedFrom: from,
    bookedTo: to,
    plateNum: plateNum,
    parkingLotId: parkingLotId,
    amount: amount,
  };

  try {
    const { data } = await axiosA.post(controllerLink, body);
    return data;
  } catch (e) {
    console.error(e);
  }
};

const cancelPublicBooking = async (id) => {
  try {
    const { data } = await axiosA.delete(controllerLink + `/${id}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export { getAvailableBookedSpace, getUserActiveBookings, createPublicBooking, cancelPublicBooking };
