import React, { useRef, useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Buttons.scss';

const NavSwitchButtonGroup = ({ hasActiveParking = false, showOnlyDefaultOption = false, selectedItem, setSelectedItem }) => {
  const { t } = useTranslation();
  const activeButtonRef = useRef(null);
  const bookingButtonRef = useRef(null);
  const historyButtonRef = useRef(null);
  const [shiftWidth, setShiftWidth] = useState(0);
  const [overlayWidth, setOverlayWidth] = useState(96);
  const [mounted, setMounted] = useState(false);

  const tabList = [
    { name: 'active', ref: activeButtonRef },
    { name: 'booking', ref: bookingButtonRef },
    { name: 'history', ref: historyButtonRef },
  ];

  const handleSelection = (event, type) => {
    //event is needed in the prop!
    if (!type) {
      return;
    }
    setSelectedItem(type);
    moveSelector(type);
  };

  const moveSelector = (type) => {
    const selectedTabFromListIndex = tabList.findIndex((item) => item.name === type);
    let newShiftWidth = 0; // shiftWidth - to shift selector and align to selected tab.

    for (var i = 0; i < selectedTabFromListIndex; i++) {
      newShiftWidth += tabList[i].ref.current.offsetWidth;
    }

    if (tabList[selectedTabFromListIndex].ref.current) {
      const newOverlayWidth = tabList[selectedTabFromListIndex].ref.current.offsetWidth;

      setShiftWidth(newShiftWidth);
      setOverlayWidth(newOverlayWidth);
    }
  };

  useEffect(() => {
    if (historyButtonRef.current) {
      setMounted(true);
      const selectedTab = tabList.find((x) => x.name === selectedItem);
      setOverlayWidth(selectedTab.ref.current.offsetWidth);
    }
  }, [historyButtonRef.current]);

  useEffect(() => {
    if (selectedItem) {
      moveSelector(selectedItem);
    }
  }, [selectedItem, mounted, hasActiveParking]);

  return (
    <Grid px={0.15} className="toggle-button-group-container">
      <div
        id="toggle-btn-overlay"
        className="toggle-overlay"
        style={{
          transform: `translate3d(${shiftWidth}px,0,0)`,
          width: overlayWidth,
        }}
      ></div>
      <ToggleButtonGroup value={selectedItem} exclusive onChange={handleSelection} className="toggle-button-group">
        <ToggleButton id={`toggle-btn-${tabList[0].name}`} value={tabList[0].name} ref={tabList[0].ref} className="toggle-button" disableRipple>
          {hasActiveParking ? (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                <circle cx="4" cy="4" r="4" fill="#67D889" />
              </svg>
              <div style={{ paddingRight: '10px' }}></div>
              {t('publicApp.Active')}
              <div style={{ paddingRight: '15px' }}></div>
            </>
          ) : (
            <>{t('publicApp.Active')}</>
          )}
        </ToggleButton>
        {!showOnlyDefaultOption && (
          <ToggleButton id={`toggle-btn-${tabList[1].name}`} value={tabList[1].name} ref={tabList[1].ref} className="toggle-button" disableRipple>
            {t('publicApp.Booking')}
          </ToggleButton>
        )}
        {!showOnlyDefaultOption && (
          <ToggleButton id={`toggle-btn-${tabList[2].name}`} value={tabList[2].name} ref={tabList[2].ref} className="toggle-button" disableRipple>
            {t('publicApp.History')}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default NavSwitchButtonGroup;
