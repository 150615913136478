import { axiosA } from './AuthService';

const getParkingGroups = async () => {
    const { data } = await axiosA.get('/ParkingGroups');
    return data;
}

const getParkingGroupsForSharing = async () => {
    const { data } = await axiosA.get(`/ParkingGroups/ForSharings`);
    return data;
}

const postParkingGroup = async (group) => {
    try {
        const body = {
            title: group.title,
            id: '',
            isClosed: group.isClosed
        };
        const { data } = await axiosA.post('/ParkingGroups', body);
        return data;
    } catch (e) {
        throw new Error(e);
    }
}

const putParkingGroup = async (group) => {
    const body = {
        title: group.title,
        id: group.id,
        isClosed: group.isClosed
    };
    const { data } = await axiosA.put(`/ParkingGroups/${group.id}`, body);
    return data;
}

const deleteParkingGroup = async (id) => {
    const { data } = await axiosA.delete(`/ParkingGroups/${id}`);
    return data;
}

export {
    getParkingGroups,
    postParkingGroup,
    putParkingGroup,
    getParkingGroupsForSharing,
    deleteParkingGroup
}