import React, { Component } from 'react';
import PublicTopbar from '../../PublicTopbar';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Button, FormControl, Container, TextField, InputAdornment, Grid, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import './CardForm.scss';
import background from '../../../../assets/images/kortele.png';
import { tokenize } from 'utils/payments/eService/EServiceService';
import InputMask from 'react-input-mask';

class CardForm extends Component {
    constructor() {
        super();
        this.state = {
            card: {
                cardName: '',
                cardNumber: '',
                expiryYear: '',
                expiryMonth: '',
                cvv: '',
                saveCard: false
            },
            payment: null,
            lot: null,
            action: null,
            userId: null,
            validCardName: true,
            validCardNumber: true,
            validExpiryYear: true,
            validExpiryMonth: true,
            validCvv: true,
            token: null,
            clicked: false
        }
    }

    componentDidMount = async () => {
        const userId = this.props.currentUser ? this.props.currentUser.id : null;
        if (userId) {
            this.setState({ userId: userId });
        }

        if (this.props.location.state) {
            const { payment, lot, action, token } = this.props.location.state;
            this.setState({ payment, lot, action, token });
        }
        else {
            this.goBack();
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleSubmit = async (event) => {
        if (this.validate()) {
            this.state.clicked = true;
            tokenize(this.state.card, this.state.token, this.state.payment, this.state.lot).then((response) => {
                if (response && response.confirmLink) {
                    window.location.href = response.confirmLink;
                } else {
                    console.log(response)
                    this.goBack();
                }
            });
        }
    };

    handleCard = (event) => {
        this.setState(prevState => ({ card: { ...prevState.card, [event.target.id]: event.target.value } }))
    }

    handleCheckBox = (event) => {
        this.setState(prevState => ({ card: { ...prevState.card, [event.target.id]: event.target.checked } }))
    }

    handleAdd = async () => {
        if (this.validate()) {
            tokenize(this.state.card).then(() => {
                this.goBack();
            })
        }
    }

    validate = () => {
        const validCardName = this.state.card.cardName && this.state.card.cardName !== '';
        const regCardNumber = new RegExp("^[0-9]{16}$");
        const validCardNumber = regCardNumber.test(this.state.card.cardNumber.replace(/\s/g, ""));
        const regExpiry = new RegExp("^[0-9]{2}$");
        const validExpiryMonth = regExpiry.test(this.state.card.expiryMonth);
        const validExpiryYear = regExpiry.test(this.state.card.expiryYear);
        const regCvv = new RegExp("^[0-9]{3}$");
        const validCvv = regCvv.test(this.state.card.cvv);
        this.setState({ validCardName, validCardNumber, validExpiryMonth, validExpiryYear, validCvv });

        return validCardName && validCardNumber && validExpiryMonth && validExpiryYear && validCvv
    }

    render() {
        const { card, payment, action, userId, validCardName, validCardNumber, validCvv, validExpiryMonth, validExpiryYear } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <PublicTopbar title={t('publicApp.AddPaymentCard')} />
                <form >
                    <Container fixed className="card-form-container">
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={background}
                                className="CreditCardBackround"
                                alt="background" />

                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth >

                                <TextField
                                    id="cardName"
                                    value={card.cardName}
                                    onChange={this.handleCard}
                                    placeholder={t('publicApp.CardName')}
                                    className={validCardName ? 'inputShadow' : ''}
                                    error={!validCardName}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 18 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth >
                                <InputMask
                                    onChange={this.handleCard}
                                    value={card.cardNumber}
                                    mask="9999 9999 9999 9999" maskChar=" "

                                >
                                    {() =>
                                        <TextField
                                            id="cardNumber"
                                            error={!validCardNumber}
                                            className={validCardNumber ? 'inputShadow' : ''}
                                            placeholder={t('publicApp.CardNumber')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 18 }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    }
                                </InputMask>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "-webkit-inline-box", mt: 2, width: "100%" }}>
                            <Box sx={{ width: "30%" }}>
                                <FormControl fullWidth sx={{ pr: 1 }}>
                                    <InputMask
                                        onChange={this.handleCard}
                                        value={card.expiryMonth}
                                        mask="99" maskChar=" "
                                    >
                                        {() =>
                                            <TextField
                                                id="expiryMonth"
                                                error={!validExpiryMonth}
                                                className={validExpiryMonth ? 'inputShadow' : ''}
                                                placeholder='MM'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 18 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        }
                                    </InputMask>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "30%" }}>
                                <FormControl fullWidth sx={{ pr: 1 }}>
                                    <InputMask
                                        onChange={this.handleCard}
                                        value={card.expiryYear}
                                        mask="99" maskChar=" "
                                    >
                                        {() =>

                                            <TextField
                                                id="expiryYear"
                                                error={!validExpiryYear}
                                                className={validExpiryYear ? 'inputShadow' : ''}
                                                placeholder='YY'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 18 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    </InputMask>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "30%" }}>
                                <FormControl fullWidth sx={{ pl: 1 }}>
                                    <InputMask
                                        onChange={this.handleCard}
                                        value={card.cvv}
                                        mask="999" maskChar=" "
                                    >
                                        {() =>
                                            <TextField
                                                id="cvv"
                                                error={!validCvv}
                                                className={validCvv ? 'inputShadow' : ''}
                                                placeholder="CVV"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" >
                                                            <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 18 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />}
                                    </InputMask>
                                </FormControl>
                            </Box>
                        </Box>
                        {/* {userId && action === 'payment' && <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth sx={{ pl: 1 }}>
                                <FormControlLabel control={
                                    <Switch
                                        value={card.saveCard}
                                        onChange={this.handleCheckBox}
                                        id="saveCard"
                                    />}
                                    label={t('publicApp.SaveCard')} />
                            </FormControl>
                        </Box>} */}

                        {payment && action === 'payment' && <Box sx={{ mt: 4.5 }}>
                            <Button
                                disabled={this.state.clicked}
                                disableElevation
                                fullWidth
                                variant="contained"
                                color="secondary"
                                type="button"
                                className='buttonStyle'
                                onClick={this.handleSubmit}
                                sx={{ textTransform: 'none' }}
                            >
                                {this.state.clicked ?
                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress size={30} color={'secondary'} />
                                    </Box> :
                                    <Box fontSize={14} textAlign="center">
                                        {t('publicApp.ConfirmPayment')}
                                    </Box>
                                }
                            </Button>
                        </Box>}

                        {/* {action === 'new' && <Box sx={{ mt: 4.5 }}>
                            <Button
                                disableElevation
                                fullWidth
                                variant="contained"
                                color="secondary"
                                type="button"
                                className='buttonStyle'
                                onClick={this.handleAdd}
                                sx={{ textTransform: 'none' }}
                            >
                                {t('publicApp.SaveCard')}
                            </Button>
                        </Box>} */}
                    </Container>
                </form>
            </React.Fragment>
        )
    }
}

export default withSnackbar(withTranslation()(CardForm));