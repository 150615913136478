import React, { Component } from 'react';

class BusinessPaymentConfirm extends Component {

    componentDidMount() {
        const url = new URL(window.location.href);

        const paymentId = url.searchParams.get("paymentId");
        const statusGroup = url.searchParams.get("statusGroup");
        const cardStatus = url.searchParams.get("cardStatus");
        const bankStatus = url.searchParams.get("bankStatus");

        const businessPayment = {
            paymentId: paymentId,
            statusGroup: statusGroup,
            cardStatus: cardStatus,
            bankStatus: bankStatus
        }

        localStorage.setItem('businessPayment', JSON.stringify(businessPayment));
        localStorage.removeItem('currentPath');

        this.props.history.push({
            pathname: '/dashboard',
        });
    }
    render() {
        return (<></>)
    }
}
export default BusinessPaymentConfirm;