import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MomentUtils from '@date-io/moment';
import 'moment/min/locales';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/react-flicking/dist/flicking-inline.css';
import './BookingTimePicker.scss';

const currentLng = localStorage.getItem('currentLng');
const momentUtils = new MomentUtils({ locale: currentLng });

const BookingTimePicker = ({ defaultValue, value, minTime, maxTime = momentUtils.date().add(5, 'year'), minutesStep, includePresentTime = true, onChangeValue }) => {
  const { t } = useTranslation();
  //TODO: finish 12/24 hour formating by momentUtils
  const is12HourFormat = currentLng === 'en-us';
  const hoursFlickingRef = useRef(null);
  const minutesFlickingRef = useRef(null);
  const [availableHours, setAvailableHours] = useState();
  const [availableMinutes, setAvailableMinutes] = useState();

  const animationDuration = 200;

  const flickingOptions = {
    align: 'center',
    bounce: 0,
    defaultIndex: 1,
    deceleration: 0.001,
    threshold: 10,
    iOSEdgeSwipeThreshold: 0,

    easing: (x) => 1 - Math.pow(1 - x, 3),
    horizontal: false,
  };

  const handleHourChange = (newHour) => {
    const newTime = momentUtils.date(value).startOf('minute').hours(newHour);

    if (value.isSame(newTime, 'hour')) {
      return;
    }

    if (isTimeDisabled(newTime)) {
      newTime.hours(minTime.hours()).minutes(minTime.minutes());
      onChangeValue(newTime);
      if (hoursFlickingRef.current) {
        const targetIndex = availableHours.indexOf(minTime.hours());
        if (targetIndex >= 0) {
          hoursFlickingRef.current.moveTo(targetIndex, animationDuration); // animationDuration is the duration, for an instant move
        }
      }
    } else {
      onChangeValue(newTime);
    }
  };

  const handleMinuteChange = (newMinute) => {
    const newTime = momentUtils.date(value).startOf('minute').minutes(newMinute);

    if (isTimeDisabled(newTime)) {
      newTime.minutes(value.minutes());
      if (minutesFlickingRef.current) {
        const targetIndex = availableMinutes.indexOf(value.minutes());
        if (targetIndex >= 0) {
          minutesFlickingRef.current.moveTo(targetIndex, animationDuration); // animationDuration is the duration, for an instant move
        }
      }
    } else {
      onChangeValue(newTime);
    }
  };

  const updateTransform = (e) => {
    e.currentTarget.panels.forEach((panel) => {
      const rotateVal = -panel.progress * 35;
      let translateYVal = 0;
      if (panel.progress > -2.35 && panel.progress < 2.35) {
        translateYVal = -panel.progress * panel.progress * panel.progress;
      }
      panel.element.style.transform = `translateY(${translateYVal}px) rotateX(${rotateVal}deg)`;

      if (panel.progress >= -0.5 && panel.progress < 0.5) {
        panel.element.classList.add('selected');
      } else {
        panel.element.classList.remove('selected');
      }
    });
  };

  const getMinutesList = () => {
    let availableMinutesInMinutesStep = [];
    if (minutesStep < 60) {
      availableMinutesInMinutesStep = [...Array(60 / minutesStep).keys()].map((i) => i * minutesStep);
    } else {
      availableMinutesInMinutesStep = [minTime.minutes()];
      onChangeValue(value.minutes(minTime.minutes()));
      return availableMinutesInMinutesStep;
    }

    if (includePresentTime) {
      const currentTime = momentUtils.date();
      const currentMinutes = currentTime.minutes();
      const compareDate = value;

      // If currentMinutes do not already exist in the list and if it is current hour - include current minutes.
      if (!availableMinutesInMinutesStep.includes(currentMinutes) && momentUtils.date(compareDate).isSame(currentTime, 'hour')) {
        availableMinutesInMinutesStep.push(currentMinutes);
        availableMinutesInMinutesStep.sort((a, b) => a - b);
      }
    }

    return availableMinutesInMinutesStep;
  };

  const setMinutesList = () => {
    const availableMinutesList = getMinutesList();
    setAvailableMinutes(availableMinutesList);
  };

  const navigateHours = (hours) => {
    if (hoursFlickingRef.current) {
      const targetIndex = availableHours.indexOf(hours);
      if (targetIndex >= 0) {
        hoursFlickingRef.current.moveTo(targetIndex, animationDuration); // animationDuration is the duration, for an instant move
      }
    }
  };

  const navigateMinutes = (minutes) => {
    if (minutesFlickingRef.current) {
      let targetIndex = 0;

      if (minutes !== undefined) {
        targetIndex = availableMinutes.indexOf(minutes);
      }

      if (!minutes || targetIndex === -1) {
        // Find and set first valid minutes value.
        targetIndex = availableMinutes.findIndex((item) => {
          return !isTimeDisabled(momentUtils.date(value).minutes(item).startOf('minute'));
        });
      }

      if (targetIndex >= 0) {
        minutesFlickingRef.current.moveTo(targetIndex, animationDuration); // animationDuration is the duration, for an instant move
        return availableMinutes[targetIndex];
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    setAvailableHours(is12HourFormat ? [12, ...Array.from(Array(11).keys()).map((n) => n + 1)] : [...Array(24).keys()]);
  }, []);

  useEffect(() => {
    if (availableMinutes && value) {
      const validMinutesValue = navigateMinutes(value.minutes());
      if (validMinutesValue !== undefined) {
        onChangeValue(value.minutes(validMinutesValue));
      } else {
        onChangeValue(value);
      }
    }
  }, [availableMinutes]);

  useEffect(() => {
    if (defaultValue && (!value || value.isSame(defaultValue))) {
      navigateHours(defaultValue.hours());
      setMinutesList();
    }
  }, [defaultValue]);

  useEffect(() => {
    setMinutesList();
  }, [value]);

  const isTimeDisabled = (time) => {
    return time.isBefore(minTime) || time.isAfter(maxTime);
  };

  return (
    <Box className="time-picker">
      <Box className="selector-top-border">{/* <Divider /> */}</Box>

      {availableHours && availableMinutes && (
        <>
          <Flicking
            {...flickingOptions}
            key={availableHours.length}
            ref={hoursFlickingRef}
            defaultIndex={availableHours.indexOf(defaultValue.hours())}
            onReady={updateTransform}
            onMove={updateTransform}
            onChanged={(e) => {
              const currentIndex = e.index;
              if (e.prevIndex < 0 || e.prevIndex === currentIndex) {
                // avoid additional handleHourChange call
                return;
              }
              handleHourChange(availableHours[currentIndex]);
            }}
            className="time-column"
          >
            {availableHours.map((hour) => (
              <Box key={hour} className={`time-value ${isTimeDisabled(momentUtils.date(value).hours(hour).endOf('hour')) ? 'disabled' : ''}`}>
                {hour < 10 ? `0${hour}` : hour}
              </Box>
            ))}
          </Flicking>
          <Typography variant="inherit" className="time-text blue-text" pr={1}>
            {t('publicApp.HoursShort')}
          </Typography>

          <Flicking
            {...flickingOptions}
            key={availableMinutes.length}
            ref={minutesFlickingRef}
            defaultIndex={availableMinutes.indexOf(defaultValue.minutes())}
            onReady={e => updateTransform(e, 'minutes')}
            onMove={e => updateTransform(e, 'minutes')}
            onChanged={(e) => {
              const currentIndex = e.index;
              if (e.prevIndex < 0 || (value && value.minutes() === availableMinutes[currentIndex])) {
                // avoid additional handleMinuteChange call
                return;
              }
              handleMinuteChange(availableMinutes[currentIndex]);
            }}
            className="time-column"
          >
            {availableMinutes.map((minute) => (
              <Box key={`${minute}-ms-${minutesStep}`} className={`time-value ${isTimeDisabled(momentUtils.date(value).minutes(minute).endOf('minute')) ? 'disabled' : ''}`}>
                {minute < 10 ? `0${minute}` : minute}
              </Box>
            ))}
          </Flicking>

          <Typography variant="inherit" className="time-text blue-text">
            min
          </Typography>

          {/*           {is12HourFormat && (
            <Flicking {...flickingOptions} onReady={updateTransform} onMove={updateTransform} className="time-column">
              <Box key="AM" className={`time-value`}>
                AM
              </Box>
              <Box key="PM" className={`time-value`}>
                PM
              </Box>
            </Flicking>
          )} */}
        </>
      )}

      <Box className="selector-bottom-border">{/* <Divider /> */}</Box>
    </Box>
  );
};

export default BookingTimePicker;
