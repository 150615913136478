import React, { createContext, useContext, useState } from 'react';

export const AppLoaderContext = createContext();

export const useAppLoader = () => useContext(AppLoaderContext);

export const AppLoaderProvider = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(false);

  return (
    <AppLoaderContext.Provider value={{ isAppLoading, setIsAppLoading }}>
      {children}
    </AppLoaderContext.Provider>
  );
};