import PropTypes from 'prop-types';
import React, { useState } from 'react';
import i18next from 'i18next';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Divider,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useAuth from '../../../../hooks/useAuth';
import LoaderCircle from '../../../../ui-component/LoaderCircle';

// assets
import okey from '../../../../assets/icons/ok-1976099.svg';
import EmailIcon from '@mui/icons-material/Email';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ loginProp, ...others }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const { firebaseEmailLink, firebaseGoogleSignIn, joinAsGuest } = useAuth();

  const handleFormSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const email = values.email.toLowerCase();

    setLoad(true);
    await firebaseEmailLink(email)
      .then(() => {
        localStorage.setItem('emailForSignIn', email);
        setLoad(false);
        setOpen(true);
        // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
        // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        // github issue: https://github.com/formium/formik/issues/2430
      })
      .catch((error) => {
        console.error(error);
        setLoad(false);
        setStatus({ success: false });
        //TODO: change invalid email, connect with administrator
        setErrors({ submit: i18next.t(error.message) });
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const googleHandler = async () => {
    try {
      await firebaseGoogleSignIn();
    } catch (err) {
      console.error(err);
    }
  };

  const fnJoinAsGuest = async () => {
    await joinAsGuest();
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18next.t('error.EmailInvalid'))
            .max(255)
            .required(i18next.t('Login3.EnterEmailToGetLink')),
        })}
        onSubmit={handleFormSubmit}
        setEmail={(value, values) => {
          values.email = value;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setEmail,
        }) => (
          <Box sx={{ mt: 3 }}>
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Box sx={{ mt: 3 }}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <TextField
                    id="outlined-adornment-email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="authInput"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon sx={{ color: 'rgba(35, 49, 68, 0.25)' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box sx={{ mt: 3 }}>
                <Button
                  id="btn-login"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="auth-button"
                  disabled={isSubmitting}
                >
                  {i18next.t('auth.Login')}
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                }}
              >
                {isSubmitting && <LoaderCircle></LoaderCircle>}
              </Box>
            </form>
          </Box>
        )}
      </Formik>

      <Grid>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid alignItems="center" justifyContent="center" sx={{ width: '100%'}}>
        <Button
          id="btn-sign-up-guest"
          disableElevation
          type="button"
          className="button-primary-outlined-blue"
          onClick={() => fnJoinAsGuest()}
        >
          {i18next.t('publicApp.JoinAsGuest')}
        </Button>
      </Grid>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Grid
          item
          xs={12}
          container
          paddingLeft={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          sx={{ pr: 2.5 }}
        >
          <Grid item xs={4}>
            <img src={okey} width="70vh" alt="background" />
          </Grid>
          <Grid item xs={8} justifyContent="center" alignContent="center">
            <Typography variant="h3">
              {i18next.t('AuthLogin.LinkSentSuccessfully')}
            </Typography>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body">
              {i18next.t('AuthLogin.CheckYourEmail')}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 2.5 }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FirebaseLogin.propTypes = {
  loginProp: PropTypes.number,
};

export default FirebaseLogin;
