import { axiosA } from './AuthService';

const assignSharedSpace = async (params) => {

    try {
        const { data } = await axiosA.post('/AssignSharedSpace', params)
        return data
    } catch (e) {
        throw new Error(e)
    }
}

export {
    assignSharedSpace
}