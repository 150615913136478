import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { getTenantPrefix } from './ProxyPathService';

const currentLang = () => {
  console.log('local', localStorage.getItem('currentLng'));

  if (!localStorage.getItem('currentLng')) {
    const lang = window.navigator.language || window.navigator.languages;
    console.log('navigatotr lang', lang);
    if (lang.toUpperCase() === 'LT-LT' || lang.toUpperCase() === 'LT') {
      console.log('chek lang3');
      localStorage.setItem('currentLng', 'lt-LT');
    } else if (lang.toUpperCase().includes('PL')) {
      localStorage.setItem('currentLng', 'pl-PL');
    } else {
      localStorage.setItem('currentLng', 'en-GB');
      console.log('chek langelsee');
    }
  }
  let curentLang = localStorage.getItem('currentLng');
  console.log('chek', curentLang);

  return curentLang;
};

const getTenantId = () => {
  let tenantId = getTenantPrefix();
  console.log('tenantId', tenantId);
  if (tenantId) {
    let result = tenantId + '/';
    return result;
  } else {
    return '';
  }
};

i18n
  .use(initReactI18next)
  .use(Backend)
  // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: currentLang(),
    fallbackLng: currentLang(),
    load: 'currentOnly',
    react: {
      wait: true,
    },
    backend: {
      loadPath: '/api/' + getTenantId() + 'v1/Translations?Locale={{lng}}',
      addPath: '/api/' + getTenantId() + 'v1/Translations?Locale={{lng}}',
      allowMultiLoading: false,
    },
  });
export default i18n;
