import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';

import LicensePlate from '../../vehicles/LicensePlate';
import PublicTopbar from '../../PublicTopbar';
import icon_car from 'assets/icons/publicApp/icon_car.svg';
import icon_parking from 'assets/icons/publicApp/icon_parking.svg';

import '../PublicParking.scss';
import PaymentDataCard from '../PaymentDataCard';

const PublicBookingReceipt = ({ receipt, onClose }) => {
  const { t } = useTranslation();
  const [payment, setPayment] = React.useState(null);

  const definePayment = () => {
    if (receipt) {
      const _payment = {
        ...receipt,
        entryDate: receipt.bookedFrom,
        exitDate: receipt.bookedTo,
      };

      setPayment(_payment);
    }
  };

  React.useEffect(() => {
    definePayment();
  }, []);

  const handleGoBack = () => {
    onClose();
  };

  return (
    <Box className="public-container" py={1}>
      <PublicTopbar enableShadow title={t('publicApp.BookedSpace')} onGoBack={handleGoBack} />
      {payment && (
        <>
          {/* Vehicle card */}
          <Card className="card-shadow" sx={{ p: 1, mx: 1, boxShadow: 0 }}>
            <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1.25 }}>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img alt="icon_car" src={icon_car} style={{ width: 42 }} />
              </Grid>
              <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" className="h4-text">
                  {t('publicApp.YourVehicle')}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ px: 1 }} style={{ textAlign: 'center' }}>
              <Divider />
              <Grid container justifyContent="center" sx={{ mt: 0.75 }}>
                <LicensePlate selected={true} licenseNumber={payment.plateNum} />
              </Grid>
            </Box>
          </Card>

          {/* Parking lot card */}
          <Card className="card-shadow" sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
            <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img alt="icon-parking" src={icon_parking} style={{ width: 48 }} />
              </Grid>
              <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" className="h4-text">
                  {t('general.ParkingLot')}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ px: 1, width: '100%', textAlign: 'center' }}>
              <Divider />
              <Grid sx={{ ml: 1, my: 1, textAlign: 'left' }}>
                <Typography variant="h4" className="h4-text" gutterBottom>
                  {payment.territoryName}
                </Typography>
                <Typography variant="h4" className="body1-text">
                  {payment.territoryAddress}
                </Typography>
              </Grid>
            </Box>
          </Card>

          {/* Payment data card */}
          <PaymentDataCard payment={payment} vat={payment.vat} currencyShort={payment.currencyShort} hasEnded isBookingType />
        </>
      )}
    </Box>
  );
};

export default PublicBookingReceipt;
