import React, { useState, useEffect, useRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Grid, Typography, Button, Divider, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import MomentUtils from '@date-io/moment';
import 'moment/min/locales';
import './BookingDateTimePicker.scss';
import BookingTimePicker from './BookingTimePicker';

const BookingDateTimePicker = ({ readOnly = false, value = undefined, onModifyAfterFinishedSelection, controlledSelectedField, onFinishSelection }) => {
  const { t } = useTranslation();
  const currentLng = localStorage.getItem('currentLng');
  const momentUtils = new MomentUtils({ locale: currentLng });
  const minutesStep = 15;

  // TODO: change from 60 to parking rate
  const minutesDurationIntervalUnit = 60; // An interval unit between start and end.

  const formatEndTimeToFirstValidTime = (rawTime = momentUtils.date()) => {
    const time = momentUtils.date(rawTime).add(1, 'hour');
    const alignmentMinutes = -time.minutes() % minutesStep;
    time.add(alignmentMinutes, 'minute');
    return time;
  };

  const now = momentUtils.date().startOf('minute');
  const [minDate, setMinDate] = useState(readOnly ? undefined : now);
  const [maxDate, setMaxDate] = useState();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(value ?? { start: now, end: formatEndTimeToFirstValidTime(now) });
  const [defaultInitTimeValue, setDefaultInitTimeValue] = useState();
  const [selectedField, setSelectedField] = useState(null);
  const [hoveredDay, setHoveredDay] = useState(null);

  const [currentTime, setCurrentTime] = useState(now);
  const initCurrentMinutesTimeoutRef = useRef(null);
  const trackCurrentMinutesIntervalRef = useRef(null);

  const clearCurrentTimeTrack = () => {
    clearTimeout(initCurrentMinutesTimeoutRef.current);
    clearInterval(trackCurrentMinutesIntervalRef.current);
    initCurrentMinutesTimeoutRef.current = null;
    trackCurrentMinutesIntervalRef.current = null;
  };

  const startCurrentTimeTrack = () => {
    clearCurrentTimeTrack();
    updateCurrentTime(momentUtils.date().startOf('minute'));
    const initTime = (60 - momentUtils.date().second()) * 1000;
    initCurrentMinutesTimeoutRef.current = setTimeout(() => {
      updateCurrentTime(momentUtils.date().startOf('minute'));
      trackCurrentMinutesIntervalRef.current = setInterval(() => updateCurrentTime(momentUtils.date().startOf('minute')), 60000);
    }, initTime);
  };

  const updateCurrentTime = (newTime) => {
    setCurrentTime(newTime);
  };

  // Bind updating time values with current time.
  useEffect(() => {
    if (minDate && minDate.isBefore(currentTime)) {
      setMinDate(currentTime);
      if (dateRange.start && dateRange.start.isBefore(currentTime)) {
        const newDateRange = { start: currentTime, end: dateRange.end };

        // Align dateRange.end value with minimum interval between start and end values.
        if (dateRange.end && dateRange.end.isSameOrBefore(momentUtils.date(currentTime).add(60 - minutesStep, 'minutes'))) {
          newDateRange.end = getEndFirstValidTime(currentTime);
        }
        setDateRange(newDateRange);
      }

      if (!defaultInitTimeValue || defaultInitTimeValue.isBefore(currentTime)) {
        setDefaultInitTimeValue(currentTime);
      }
    }
  }, [currentTime]);

  useEffect(() => {
    if (!readOnly) {
      setOpen(true);
      setSelectedField('startDate');
      startCurrentTimeTrack();
    }

    return () => {
      clearCurrentTimeTrack();
    };
  }, []);

  const getEndFirstValidTime = (time) => {
    // EndFirstValidTime is a time, which calculated by
    // Example: start - 12:03, then end would be - 13:00
    const minutesToAdd = 60 - (time.minutes() % minutesStep);
    return momentUtils.date(time).add(minutesToAdd, 'minutes');
  };

  const handleDaySelect = (rawValue) => {
    const currentTime = momentUtils.date();
    const isToday = rawValue.isSame(currentTime, 'day');
    const newValue = momentUtils
      .date(rawValue)
      .hours(currentTime.hours())
      .minutes(isToday ? currentTime.minutes() : 0)
      .seconds(0);
    // Logic for setting start and end dates
    if (!dateRange.start || (dateRange.start && ((dateRange.end && selectedField === 'startDate') || newValue.isBefore(dateRange.start, 'day'))) || selectedField === 'startDate') {
      setDateRange({ start: newValue, end: null });
      setSelectedField('endDate');
    } else if (dateRange.start && !newValue.isBefore(dateRange.start, 'day')) {
      setDateRange({ ...dateRange, end: formatEndTimeToFirstValidTime(newValue) });
      setSelectedField('startDate');
    }
  };

  const handleTimeSelect = (newValue) => {
    let newDateRange = {};
    if (selectedField === 'startTime') {
      const newEndDate = !newValue.isBefore(momentUtils.date(dateRange.end).subtract(1, 'hour')) ? formatEndTimeToFirstValidTime(newValue) : dateRange.end;
      newDateRange = { start: newValue, end: newEndDate };
    } else {
      newDateRange = { start: dateRange.start, end: newValue };
    }
    setDateRange(newDateRange);
  };

  const handleDateFieldSelect = (type) => {
    if (readOnly) {
      return;
    }

    if (onModifyAfterFinishedSelection) {
      onModifyAfterFinishedSelection();
    }
    // Continue tracking current time
    if (initCurrentMinutesTimeoutRef.current === null && trackCurrentMinutesIntervalRef.current === null) {
      startCurrentTimeTrack();
    }

    setOpen(true);
    setSelectedField(type);
  };

  const handleStartTimeFieldSelect = () => {
    if (readOnly) {
      return;
    }

    if (!dateRange.end) {
      setDateRange({ ...dateRange, end: formatEndTimeToFirstValidTime(dateRange.start) });
    }

    if (onModifyAfterFinishedSelection) {
      onModifyAfterFinishedSelection();
    }
    // Continue tracking current time
    if (initCurrentMinutesTimeoutRef.current === null && trackCurrentMinutesIntervalRef.current === null) {
      startCurrentTimeTrack();
    }

    setOpen(true);
    setDefaultInitTimeValue(dateRange.start);
    setSelectedField('startTime');
    setMinDate(currentTime);
  };

  const handleEndTimeFieldSelect = () => {
    if (readOnly || !dateRange.end) {
      return;
    }

    if (onModifyAfterFinishedSelection) {
      onModifyAfterFinishedSelection();
    }

    setOpen(true);
    setDefaultInitTimeValue(dateRange.end);
    setSelectedField('endTime');
    const startDateWithMinimalPeriod = getEndFirstValidTime(dateRange.start);
    setMinDate(startDateWithMinimalPeriod);
  };

  useEffect(() => {
    if (controlledSelectedField === 'startDate') {
      handleDateFieldSelect('startDate');
    }
  }, [controlledSelectedField]);

  const handleContinueSwitch = () => {
    switch (selectedField) {
      case 'startDate': {
        if (!dateRange.end) {
          return setSelectedField('endDate');
        }
        return handleStartTimeFieldSelect();
      }
      case 'endDate': {
        return handleStartTimeFieldSelect();
      }
      case 'startTime': {
        if (!dateRange.end) {
          return setSelectedField('endDate');
        }
        return handleEndTimeFieldSelect();
      }
      case 'endTime': {
        clearCurrentTimeTrack();
        setSelectedField(null);
        setOpen(false);
        if (onFinishSelection) {
          onFinishSelection(dateRange.start, dateRange.end);
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  const formatDate = (date) => {
    return momentUtils.date(date).format('MMM DD');
  };

  const formatTime = (time) => {
    return momentUtils.date(time).format('HH:mm');
  };

  const DateCalendarTheme = (theme) => {
    return createTheme({
      ...theme,
      components: {
        MuiPickersDay: {
          styleOverrides: {
            today: {
              '&&': {
                fontFamily: 'Roboto-Bold',
                color: '#3268B2',
                border: 'none',
              },
            },
          },
          defaultProps: {
            disableRipple: true,
          },
        },
      },
    });
  };

  const RenderedDay = (props) => {
    const { day, selectedDay, hoveredDay, ...other } = props;

    const isSelected = day.isSame(dateRange.start, 'day') || (dateRange.end && day.isSame(dateRange.end, 'day'));
    const isBetween =
      day.isAfter(dateRange.start, 'day') &&
      ((dateRange.end && day.isBefore(dateRange.end, 'day')) || (!dateRange.end && day.isAfter(selectedDay, 'day') && day.isBefore(hoveredDay, 'day') && selectedField === 'endDate'));
    const isHovered = hoveredDay && day.isSame(hoveredDay, 'day');

    const className = ['custom-pickers-day', isSelected && 'isSelected', isBetween && 'isBetween', isHovered && 'isHovered'].filter(Boolean).join(' ');

    return <PickersDay {...other} day={day} sx={{ p: 2.5 }} disableMargin selected={false} className={className} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLng}>
      {/* Picker's fields */}
      <Grid py={2}>
        <Grid container className="date-time-field-container" mb={2}>
          <Grid item xs={4} className="date-time-label">
            <Typography variant="h4" className="h4-text">
              {t('publicApp.Arrival')}
            </Typography>
          </Grid>
          <Grid item xs={3.5} className="date-time-btn-container">
            <Button
              id="btn-start-date"
              disabled={readOnly}
              className={`date-time-btn blue-text ${selectedField === 'startDate' ? 'active' : ''}`}
              disableRipple
              onClick={() => handleDateFieldSelect('startDate')}
            >
              {dateRange.start ? formatDate(dateRange.start) : '---'}
            </Button>
          </Grid>
          <Grid item xs={1} className="date-time-btn-container">
            <Divider orientation="vertical" sx={{ borderColor: '#CFD5E2' }} />
          </Grid>
          <Grid item xs={3.5} className="date-time-btn-container">
            <Button id="btn-start-time" disabled={readOnly} className={`date-time-btn blue-text ${selectedField === 'startTime' ? 'active' : ''}`} disableRipple onClick={handleStartTimeFieldSelect}>
              {formatTime(dateRange.start) || '--:-- --'}
            </Button>
          </Grid>
        </Grid>

        <Grid container className="date-time-field-container">
          <Grid item xs={4} className="date-time-label">
            <Typography variant="h4" className="h4-text">
              {t('publicApp.Departure')}
            </Typography>
          </Grid>
          <Grid item xs={3.5} className="date-time-btn-container">
            <Button
              id="btn-end-date"
              disabled={readOnly}
              className={`date-time-btn blue-text ${selectedField === 'endDate' ? 'active' : ''}`}
              disableRipple
              onClick={() => handleDateFieldSelect('endDate')}
            >
              {dateRange.end ? formatDate(dateRange.end) : '---'}
            </Button>
          </Grid>
          <Grid item xs={1} className="date-time-btn-container">
            <Divider orientation="vertical" sx={{ borderColor: '#CFD5E2' }} />
          </Grid>
          <Grid item xs={3.5} className="date-time-btn-container">
            <Button
              id="btn-end-time"
              disabled={!dateRange.end || readOnly}
              className={`date-time-btn blue-text ${selectedField === 'endTime' ? 'active' : ''}`}
              disableRipple
              onClick={handleEndTimeFieldSelect}
            >
              {dateRange.end ? formatTime(dateRange.end) : '--:--'}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Bottom sheet picker */}
      {open && (
        <BottomSheet open={open} scrollLocking={false} blocking={false} className="bottom-sheet-without-header">
          <Box pt={2}>
            {(selectedField === 'startDate' || selectedField === 'endDate') && (
              <Grid pt={2} sx={{ overflow: 'hidden' }}>
                <ThemeProvider theme={DateCalendarTheme}>
                  <DateCalendar
                    disablePast
                    defaultValue={dateRange.start}
                    onChange={(newValue) => {
                      handleDaySelect(newValue);
                    }}
                    slots={{ day: RenderedDay }}
                    slotProps={{
                      day: (ownerState) => ({
                        selectedDay: dateRange.start,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                      }),
                    }}
                    views={['day']}
                  />
                </ThemeProvider>
              </Grid>
            )}

            {(selectedField === 'startTime' || selectedField === 'endTime') && (
              <Grid mx={4}>
                <Grid container mb={1} justifyContent="space-between" alignItems="center">
                  <Typography variant="inherit" className="type-label blue-text">
                    {selectedField === 'startTime' ? t('publicApp.Arrival') : t('publicApp.Departure')}
                  </Typography>

                  <Typography variant="inherit" className="date-label">
                    {selectedField === 'startTime' ? dateRange.start.format('ddd, MMM DD, YYYY') : dateRange.end.format('ddd, MMM DD, YYYY')}
                  </Typography>
                </Grid>
                <Divider />
                <Grid mb={4} mt={2}>
                  <BookingTimePicker
                    defaultValue={defaultInitTimeValue}
                    value={selectedField === 'startTime' ? dateRange.start : dateRange.end}
                    minTime={minDate}
                    minutesStep={selectedField === 'endTime' ? minutesDurationIntervalUnit : minutesStep}
                    onChangeValue={handleTimeSelect}
                  />
                </Grid>
              </Grid>
            )}
            <Grid className="continue-btn-container">
              <Button id="btn-continue" disableElevation fullWidth size="large" type="button" variant="contained" color="secondary" className="book-space-btn" onClick={handleContinueSwitch}>
                {t('publicApp.Continue')}
              </Button>
            </Grid>
          </Box>
        </BottomSheet>
      )}
    </LocalizationProvider>
  );
};

export default BookingDateTimePicker;
