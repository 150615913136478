import React, { Component } from 'react';
import './Consent.scss';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../ui/topbar/Topbar';
import { AppBar, Fab, Grid, Toolbar, Box, Checkbox, FormControlLabel } from '@mui/material';
import { getActualVersion } from '../../utils/ConsentService';
import renderHTML from 'react-render-html';

class Consent extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            htmlContent: ''
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        const version = await getActualVersion();
        if (version) {
            await this.setState({ htmlContent: version.content });
        }
    }

    handleSubmit = async () => {
        await this.props.setConsent();
        const redirectToAfterConsent = localStorage.getItem(
          'redirectToAfterConsent'
        );
        if (redirectToAfterConsent) {
          localStorage.removeItem('redirectToAfterConsent');
          this.props.history.push(redirectToAfterConsent);
        } else {
          this.props.history.push('/dashboard');
        }

        if (this.props.currentUser) {
            this.props.checkPushSubscription(this.props.currentUser);
        }
    }

    setChecked = async (checked) => {
        this.setState({ checked: checked });
    }

    render() {

        const { t, haveConsent } = this.props;
        const { htmlContent } = this.state;

        const handleChange = (event) => {
            this.setChecked(event.target.checked);
        };

        return (
            <div>
                <Topbar title={t("administration.Consent")} cbBack={false} hideSidebar={!haveConsent}></Topbar>
                <div>
                    <div className="consent_container">
                        {renderHTML(htmlContent)}
                    </div>
                    <div
                        // onSubmit={this.handleSubmit}
                        // noValidate
                    >
                        {haveConsent ? null :
                        <AppBar color='inherit' position="fixed" className="bottom-bar">
                            <Toolbar>
                                <Grid
                                    spacing={3}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={6}
                                        className="textcenter">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={t("consent.Read")}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Fab
                                            className={`full-width ${
                                                this.state.checked ? 'secondary-btn' : 'text-white'
                                              }`}
                                             variant="extended"
                                            type="button"
                                            value="Submit"
                                            onClick={this.handleSubmit}
                                            disabled={!this.state.checked}
                                            aria-label="Save">
                                            <Box fontSize={14} textAlign="center">
                                                {t('consent.Agree')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Consent);