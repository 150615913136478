import React, { Component, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import config from '../../config';
import { AuthProvider, AuthConsumer } from '../../providers/AccountProvider';
import GuestGuard from '../../utils/route-guard/GuestGuard';
import PrivateRoute from 'routes/PrivateRoute';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import NavigationScroll from '../../layout/NavigationScroll';
import Locales from '../../ui-component/Locales';
import themes from '../../themes';
import routes from 'routes/routes';
import './App.scss';
import AppLoader from 'ui-component/AppLoader';
import { AppLoaderContext, AppLoaderProvider } from 'contexts/AppLoaderContext';

class App extends Component {
  constructor() {
    super();
    if (window.performance) {
      if (performance.navigation.type === 1) {
        const exList = ['/', '/login'];
        if (!exList.includes(window.location.pathname)) localStorage.setItem('currentPath', window.location.pathname);
      } else {
        const exList = ['/', '/login'];
        if (!exList.includes(window.location.pathname)) localStorage.setItem('currentPath', window.location.pathname);
      }
    }
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <Suspense fallback={<AppLoader />}>
            <AppLoaderProvider>
              <AppLoaderContext.Consumer>
                {({ isAppLoading }) => (<AppLoader showLoader={isAppLoading} />)}
              </AppLoaderContext.Consumer>
              <Router>
                <NotificationContainer />
                <Helmet>
                  <title>{window.title}</title>
                </Helmet>
                <Locales>
                  <NavigationScroll>
                    <FirebaseProvider>
                      <AuthProvider>
                        <SnackbarProvider maxSnack={3}>
                          {routes.map((route, index) =>
                            route.public ? (
                              <GuestGuard key={index}>
                                <AuthConsumer key={index}>
                                  {({ login }) => <Route key={index} path={route.path} exact={route.exact} render={(props) => <route.main {...props} login={login} />} />}
                                </AuthConsumer>
                              </GuestGuard>
                            ) : (
                              <AuthConsumer key={index}>
                                {({ isAuth, isAdmin }) =>
                                  (route.onlyAdmin && isAdmin) || !route.onlyAdmin ? <PrivateRoute key={index} path={route.path} exact={route.exact} isAuth={isAuth} component={route.main} /> : null
                                }
                              </AuthConsumer>
                            )
                            )}
                          {routes.map((route, index) => !route.public && <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.sidebar} />)}
                        </SnackbarProvider>
                      </AuthProvider>
                    </FirebaseProvider>
                  </NavigationScroll>
                </Locales>
              </Router>
            </AppLoaderProvider>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const customization = {
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  outlinedFilled: config.outlinedFilled,
  navType: config.theme,
  presetColor: config.presetColor,
  rtlLayout: config.rtlLayout,
};

export default App;
