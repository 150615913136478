import { axiosA } from './AuthService';

const featureEnabled = async (feature) => {
  const { data } = await axiosA.get(`/FeatureToggler/${feature}`);
  return data;
};

const getEnabledFeatures = async () => {
  const { data } = await axiosA.get('/FeatureToggler');

  const enabledFeatures = data.reduce((acc, obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];

    if (typeof value === 'string') {
      acc[key] = value.toLowerCase() === 'true';
    } else {
      acc[key] = !!value;
    }

    return acc;
  }, {});

  return enabledFeatures;
};

export { featureEnabled, getEnabledFeatures };
