import React, { } from 'react';
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/styles";
import { Typography, ListItem, IconButton, Grid } from '@mui/material';
import './TerritoryStatus.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCar } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

const moment = new MomentUtils();

const useStyles = makeStyles({
    green: {
        color: '#229954',
        borderColor: '#229954'
    },
    red: {
        color: '#CB4335',
        borderColor: '#CB4335'
    },
    blue: {
        color: '#2874A6',
        borderColor: '#2874A6'
    },
    orange: {
        color: '#F39C12',
        borderColor: '#F8C471'
    },
    card: {
        border: '3px solid',
        borderRadius: '12px'
    },
    label: {
        fontSize: '1.7em !important',
        fontFamily: 'Roboto-Bold !important',

        fontWeight: 600,
        minWidth: '15%',
        textAlign: 'center'
    }
})

export default function TerritoryStatus(props) {

    const { item, index, showCalendar, show_calendarEnabled, inlcudeWeekendsInSharing } = props;
    const classes = useStyles();
    const dateClone = moment.date();
    let messageText = null;
    let statusColor = '';
    let labelText = item.freeSlots;
    let showIcon = null;

    if (item.inLot) {
        statusColor = 'blue';
        messageText = 'dashborsd.YouCanFindYourCarIn';
        showIcon = faCar;
    }
    else if (item.freeSlots === 0) {
        statusColor = 'red';
        messageText = 'dashboard.SorryNoParkingPlacesAvailable';
    }
    else if ((!inlcudeWeekendsInSharing || (dateClone.day() === 0 || dateClone.day() === 6)) || item.freeEntrance) {
        statusColor = 'green';
        messageText = 'dashboard.YouCanParkIn'
    }
    else {
        if (item.userRole === 'user') {
            if (item.lotStatus === 'booked') {
                statusColor = 'green';
                messageText = 'dashboard.YouCanParkIn';
            }
            if (statusColor === '') {
                if (item.lotStatus === 'shared') {
                    statusColor = 'orange';
                    messageText = 'dashboard.AvailableToAssignParkingPlaces'
                }
            }
            if (statusColor === '') {
                statusColor = 'red';
                messageText = 'dashboard.SorryNoParkingPlacesAvailable';
                labelText = '0';
            }
        } else {
            if (item.lotStatus === 'booked') {
                statusColor = 'red';
                messageText = 'dashboard.SorryYourPlaceIsOccupied';
                showIcon = faBan;
            }
            if (statusColor === '') {
                if (item.lotStatus === 'shared') {
                    statusColor = 'orange';
                    messageText = 'dashboard.ClickToCancelThisShare';
                    showIcon = faBan;
                }
            }
            if (statusColor === '') {
                statusColor = 'green';
                messageText = 'dashboard.YouCanParkIn';
            }
        }
    }

    const labelStyle = clsx(classes.label, {
        [classes.green]: statusColor === "green",
        [classes.red]: statusColor === "red",
        [classes.blue]: statusColor === 'blue',
        [classes.orange]: statusColor === 'orange'
    })

    return (
        <ListItem className="DashboardItemContainer">
            <Grid container>
                <Grid item xs={12} sx={{ background: '#F2F2F2', py: 1, px: 2, borderRadius: '10px', borderColor: '#3A4D8F', border: '2px solid #3A4D8F' }}>
                    <Grid container direction="row" alignItems='center' >
                        {show_calendarEnabled &&
                            (<Grid item xs={4} paddingRight={1.5}>
                                <IconButton onClick={() => showCalendar(index)} style={{ padding: "0", paddingBottom: "5px" }} >
                                    <FontAwesomeIcon icon={faCalendarAlt} color="#2874A6" />
                                </IconButton>
                            </Grid>)
                        }
                        <Grid item xs paddingLeft={1.5}>
                            <Typography className="h2" color='#3A4D8F'  >
                                {item.lotTitle}
                            </Typography>
                        </Grid>

                        <Grid item paddingRight={1.5} >
                            {showIcon != null ?
                                <Typography className={labelStyle}>
                                    <FontAwesomeIcon icon={showIcon} />
                                </Typography>
                                :
                                <Typography className={labelStyle}>{labelText}</Typography>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    )
}