import { useEffect, useState } from 'react';
import { Topbar } from '../ui/topbar/Topbar';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {
  AppBar,
  Box,
  Container,
  Fab,
  Grid,
  Toolbar,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { InputText } from '../ui/inputs/text/Text';
import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { putParkingGroup, postParkingGroup } from 'utils/ParkingGroupsService';

const ParkingGroupForm = (props) => {
  const { group } = props.location.state;
  const isEdit = group.id ? true : false;
  const title = i18next.t(!isEdit ? 'groups.createGroup' : 'groups.editGroup');
  const [value, setValue] = useState({
    id: null,
    title: '',
    isClosed: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { group } = props.location.state;
    setValue(group);
  }, []);

  const goBack = () => {
    props.history.goBack();
  };

  const handleChange = (event) => {
    setValue({ ...value, [event.target.id]: event.target.value });
  };

  const handleChangeCh = (event) => {
    setValue({ ...value, isClosed: event.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (value.id) {
      const data = await putParkingGroup(value);
      enqueueSnackbar(i18next.t('notification.success.changeGroup'), {
        variant: 'success',
      });
      goBack();
    } else {
      const data = await postParkingGroup(value);
      enqueueSnackbar(i18next.t('notification.success.addGroup'), {
        variant: 'success',
      });
      goBack();
    }
  };

  const groupUsers = async () => {
    props.history.push({
      pathname: '/groupUsers',
      state: {
        group: group,
      },
    });
  };

  return (
    <div>
      <Topbar title={title} cbBack={goBack} />

      <div className="territoryNewContainer">
        <Grid
          xs
          container
          direction="row"
          sx={{ p: 2, py: 1.5, background: '#3A4D8F', border: '1px solid' }}
        >
          <Grid item xs>
            <Typography variant="h3" color="#ffffff">
              {!isEdit && i18next.t('groups.newParkingGroup')}
              {isEdit && i18next.t('groups.parkingGroupEditing')}
            </Typography>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit} noValidate>
          <SnackbarProvider>
            <Container fixed className="adm_form-container">
              <InputText
                type="text"
                id="title"
                onChange={handleChange}
                value={value.title}
                label={i18next.t('groups.groupTitle')}
                placeholder={value.title}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.isClosed}
                    onChange={handleChangeCh}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={i18next.t('groups.closedGroup')}
              />
            </Container>
          </SnackbarProvider>
          <AppBar
            color="inherit"
            position="fixed"
            className="bottom-bar"
            elevation={0}
          >
            <Toolbar>
              <Grid
                spacing={3}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {value.title && (
                  <Grid item xs={10}>
                    <Fab
                      className="full-width secondary-btn"
                      variant="extended"
                      type="submit"
                      value="Submit"
                      onClick={handleSubmit}
                      aria-label="Save"
                    >
                      <Box fontSize={14} textAlign="center">
                        {i18next.t('general.Save')}
                      </Box>
                    </Fab>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </form>

        {isEdit && (
          <Grid
            spacing={3}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Fab
                className="full-width secondary-btn"
                variant="extended"
                type="submit"
                value="Submit"
                onClick={groupUsers}
                aria-label="Save"
              >
                <Box fontSize={14} textAlign="center">
                  {i18next.t('users.Title')}
                </Box>
              </Fab>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default ParkingGroupForm;
