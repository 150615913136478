import React from 'react';
import { getCurrentUser, deleteUser } from '../utils/UsersService';
import { login, logout, isValidToken, authenticate, invalidateUserCache, getIsActive, checkPublicAccount } from '../utils/AuthService';
import { getSettings, requestNotificationPermissionAndUpdate, unsubscribePush, getCurrentLng } from '../utils/SettingsService';
import { resolveTimeSpan } from 'utils/DateTimeResolver';
import { getConsent, setConsent } from '../utils/ConsentService';
import { clearNativeTermsAndConditions } from '../utils/bridge';
import firebase from 'firebase';
import i18n from 'utils/i18n';
import { getTenantPrefix } from '../utils/ProxyPathService';
import { getCurrentLot } from 'utils/PublicLotService';
import { featureEnabled, getEnabledFeatures } from 'utils/FeatureTogglerService';
import { AUTO_MATCH, HIDE_BOOKING, SHOW_ANNOUNCEMENTS } from 'constants/Features';
import DisplayAnnouncement from 'components/announcements/DisplayAnnouncement';
import { AppLoaderContext } from 'contexts/AppLoaderContext';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  static contextType = AppLoaderContext;
  state = {
    isAuth: false,
    isAdmin: false,
    currentUser: null,
    haveConsent: false,
    isActive: false,
  };

  toggleAdmin = async (history) => {
    await this.setState({ isAdmin: !this.state.isAdmin });
    this.loginAppLoaderWrapper(history, true);
  };

  loginAppLoaderWrapper = async (history, email, token) => {
    const { setIsAppLoading } = this.context;
    setIsAppLoading(true);
    const result = await this.login(history, email, token);
    setIsAppLoading(false);
    return result;
  }

  login = async (history, email, token) => {
    try {
      let logined = false;
      let isNew = false;
      const isGuest = localStorage.getItem('isGuest');
      const currentPath = localStorage.getItem('currentPath');

      if (isGuest) {
        this.setState({ isAuth: true });
        history.push(currentPath === '/publicParking' ? '/publicParking' : '/publicMap');
        return true;
      }

      if (localStorage.getItem('userSite') === 'switchToPublic') {
        const emailToPublic = this.state.currentUser?.email;
        if (emailToPublic) {
          await checkPublicAccount(emailToPublic);
        }
      }

      logined = await isValidToken();
      if (email && !logined) {
        try {
          await authenticate(email);
          return true;
        } catch (e) {
          console.error(e)
          return false;
        }
      }

      // when user logs in with external idp he does not follow
      // existing login procedure, thus user cache requires update
      if (invalidateUserCache()) {
        isNew = true;
      }

      if (token) {
        logined = await login(token);
        isNew = true;
      }
      if (logined) {
        await this.fetchInfo(isNew);
        if (!this.state.isActive) {
          return false;
        }
        let currentLang = localStorage.getItem('currentLng');

        let tenantId = getTenantPrefix();

        if (currentLang && tenantId) {
          tenantId += '/';
          i18n.init({
            backend: {
              loadPath: '/api/' + tenantId + 'v1/Translations?Locale={{lng}}',
              addPath: '/api/' + tenantId + 'v1/Translations?Locale={{lng}}',
            },
          });
          await i18n.reloadResources(currentLang);
        }

        if (this.state.haveConsent) {
          let redirectPath = '/publicMap';
          
          if (currentPath && currentPath !== '/consent') {
            redirectPath = currentPath;
          } else if (tenantId !== null) {
            redirectPath = '/dashboard';
          }

          if (redirectPath === '/publicMap') {
            const selectedLicensePlate = localStorage.getItem('selectedVehicle');
            if (selectedLicensePlate) {
              const hasActiveParking = await getCurrentLot(selectedLicensePlate);
              if (hasActiveParking) {
                redirectPath = '/publicParking';
              }
            }
          }
          history.push(redirectPath);
        } else {
          history.push('/consent');
        }
        localStorage.removeItem('currentPath');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    // pasikeist pagal normalu api
  };

  //Perkelti logout
  logout = async () => {
    // cleans up subscriptions from from BE
    try {
      await unsubscribePush();
      await firebase.auth().signOut();
      await clearNativeTermsAndConditions();
    } catch (error) {
      console.error(error);
    }

    // dasideti req
    logout();
    this.setState({ isAuth: false });

    await firebase.auth().signOut();
    await clearNativeTermsAndConditions();
    await localStorage.clear();
    await sessionStorage.clear();
    console.log('try logout');
  };

  logoutGuest = () => {
    clearNativeTermsAndConditions();
    localStorage.clear();
    sessionStorage.clear();
  };

  deleteAccount = async (id) => {
    //deletes account (main purpose for public users)
    try {
      await unsubscribePush();
    } catch (error) {
      console.error(error);
    }
    await deleteUser(id);
    await firebase.auth().signOut();
    clearNativeTermsAndConditions();
    localStorage.clear();
    sessionStorage.clear();
    this.setState({ isAuth: false });
  };

  fetchSettings = async () => {
    let settings = {
      Customer: {},
      User: {},
    };
    const data = await getSettings();
    data.items.filter((x) => !x.ownerId).forEach((e) => (settings.Customer = { ...settings.Customer, [e.property]: e }));
    data.items.filter((x) => x.ownerId).forEach((e) => (settings.User = { ...settings.User, [e.property]: e }));
    this.setState({ settings });
    this.resolveBookingSettings();
  };

  fetchEnabledFeatures = async () => {
    //TODO: cure all requests for enabled features to use this approach
    let enabledFeatures = [];

    try {
      enabledFeatures = await getEnabledFeatures();
    } catch (error) {
      console.error()
      const showAnnouncements = await featureEnabled(SHOW_ANNOUNCEMENTS);
      const hideBooking = await featureEnabled(HIDE_BOOKING);
      const autoMatch = await featureEnabled(AUTO_MATCH);
      enabledFeatures = { SHOW_ANNOUNCEMENTS: showAnnouncements, HIDE_BOOKING: hideBooking, AUTOMATIC_MATCHING: autoMatch };
    }
    
    this.setState({ enabledFeatures });
  };

  resolveBookingSettings = async () => {
    const bookingPeriodAlignment = await resolveTimeSpan(this.state.settings.Customer.BookingPeriodAlignment?.value ?? '1.00:00:00');
    const bookingPeriodUnit = await resolveTimeSpan(this.state.settings.Customer.BookingPeriodUnit?.value ?? '1.00:00:00');
    await this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        booking: {
          bookingPeriodUnit,
          bookingPeriodAlignment,
        },
      },
    }));
  };

  fetchInfo = async (isNew) => {
    await this.fetchIsActive();
    await this.fetchSettings();
    await this.fetchEnabledFeatures();
    await this.fetchHaveConsent();
    await this.setCurrentLng();
    return await this.fetchCurrentUser(isNew);
  };

  setCurrentLng = async () => {
    let tenantId = localStorage.getItem('tenantId');
    if (!tenantId) {
      try {
        const localeSetting = await getCurrentLng();
        if (localeSetting) {
          localStorage.setItem('currentLng', localeSetting);
          await i18n.changeLanguage(localeSetting);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  fetchIsActive = async () => {
    const isActive = await getIsActive();
    if (isActive) {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
      await this.logout();
    }
  };

  fetchCurrentUser = async (isNew) => {
    const currentUser = await getCurrentUser(isNew);
    if (currentUser) {
      await this.setState({ currentUser, isAuth: true, isAdmin: currentUser.role === 'Admin' });
      this.checkPushSubscription(currentUser);
    } else {
      this.setState({ isAuth: false });
    }
  };

  fetchHaveConsent = async () => {
    const haveConsent = await getConsent();
    if (haveConsent && haveConsent === true) {
      this.setState({ haveConsent: true });
    } else {
      this.setState({ haveConsent: false });
    }
  };

  setConsent = async (consented) => {
    if (consented === false) {
      this.setState({ haveConsent: consented });
      return;
    }

    const data = await setConsent();
    if (data) {
      await this.setState({ haveConsent: true });
    }
  };

  checkAccount = async (history, isPrivate) => {
    const isRedirect = (localStorage.getItem('tenantId') === null) === isPrivate;
    const path = isPrivate ? '/publicMap' : '/dashboard';
    if (isRedirect) {
      history.push(path);
    }
  };

  checkPushSubscription = async (user) => {
    try {
      // if global push notification enabled
      // or user push notification enabled subscribe
      let notificationSetting = undefined;

      // check if user enabled push notifications in custom settings
      let notificationSettingKeys = undefined;
      let userSettings = this.state.settings?.User;
      if (userSettings) {
        notificationSettingKeys = Object.keys(userSettings).filter((item) => item.startsWith('UserNotificationConfig'));
        notificationSettingKeys.some((key) => {
          notificationSetting = JSON.parse(this.state.settings.User[key].value);
          return notificationSetting.NotifyPush;
        });
      }

      if (!notificationSetting && !notificationSetting?.NotifyPush) {
        // check if default settings enables push notifications
        let customerSettings = this.state.settings.Customer;
        notificationSettingKeys = Object.keys(customerSettings).filter((item) => item.startsWith('UserNotificationConfig'));
        notificationSettingKeys.some((key) => {
          notificationSetting = JSON.parse(this.state.settings.Customer[key].value);
          return notificationSetting.NotifyPush;
        });
      }

      await requestNotificationPermissionAndUpdate(user, null, null, notificationSetting);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          currentUser: this.state.currentUser,
          isAuth: this.state.isAuth,
          login: this.loginAppLoaderWrapper,
          logout: this.logout,
          logoutGuest: this.logoutGuest,
          deleteAccount: this.deleteAccount,
          settings: this.state.settings,
          enabledFeatures: this.state.enabledFeatures ?? [],
          isAdmin: this.state.isAdmin,
          toggleAdmin: this.toggleAdmin,
          haveConsent: this.state.haveConsent,
          setConsent: this.setConsent,
          checkPushSubscription: this.checkPushSubscription,
          fetchSettings: this.fetchSettings,
          fetchCurrentUser: this.fetchCurrentUser,
          checkAccount: this.checkAccount,
        }}
      >
        {this.props.children}
        {this.state.enabledFeatures && this.state.enabledFeatures[SHOW_ANNOUNCEMENTS] && <DisplayAnnouncement needCheckForAnnouncement={true} />}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
