import React, { Component } from 'react';
import './Users.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { InputSearch } from '../ui/inputs/search/Search';
import { withTranslation } from 'react-i18next';
import { List } from '@mui/material';
import UserListItem from './list/item/UserListItem';
import { getUsers, deleteUser } from '../../utils/UsersService';
import { withSnackbar } from 'notistack';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";
import {featureEnabled} from "../../utils/FeatureTogglerService";
import {SHOW_IBAN} from "../../constants/Features";

const searchAPIDebounced = AwesomeDebouncePromise(getUsers, Api.SEARCH_DEBOUNCE);

// user new create 

class Users extends Component {

  constructor(props) {
    super(props)
    this.state = {
      query: '',
      usersList: [],
      loadedRowsMap: {},
      searching: false
    }
    this.deleteMsg = props.t('confirm.Delete')
  }

  deleteUser = async (userId) => {
  //  const confirmed = window.confirm(this.deleteMsg);
    //  if (confirmed) {
        const { operation, status, detail } = await deleteUser(userId);
        if (operation) {
          this.setState({ usersList: [...this.state.usersList.filter(e => e.id !== userId)] })

          this.props.enqueueSnackbar(
            this.props.t('notification.success.deleteUser'),
            {
              variant: 'success',
            }
          );
        } else if (status && status === 202) {
          this.props.enqueueSnackbar(
            this.props.t('notification.warning.' + detail),
            {
              variant: 'warning',
            }
          );
        }
    //  }
  }

  createUser = () => {

   const isAdministrator = this.props.isAdmin;
    console.log('is Admin ', isAdministrator);
    this.props.history.push({
      pathname: '/user/-1/edit',
      state: { isAdmin: isAdministrator }
    })
  }

  goToProfile = (user) => {
    this.props.history.push({
      pathname: '/user/' + user.id,
      // params: { testvalue: "hello" },
      state: { user, canBack: true }
    })
  }

  fetchUsers = async (query, offset, loadingMore) => {
    try {
      let hasMore = false;

      const resp = await searchAPIDebounced(query, offset)
      if (resp.nextOffset) hasMore = true

      if (!loadingMore) {
        this.setState(prevState => ({ usersList: [...resp.items], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
      } else {
        this.setState(prevState => ({ usersList: [...new Set([...prevState.usersList, ...resp.items])], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
      }

    }
    catch (e) {
      console.error(e)
    }
  }

  search = async (e) => {
    await this.setState({ query: e.target.value })
    this.fetchUsers(this.state.query)
  }

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    await this.fetchUsers();
  }

  render() {

    const { t, location, history } = this.props

    return (
      <React.Fragment>
        {/* <ConfirmDialog open={true} title={t('confirm.Delete')}/> */}
        <Topbar
          title={t("users.Title")}
          location={location}
          history={history}
          cbCreate={this.createUser}
        >
          <InputSearch onChange={this.search} placeholder={t("general.Search")} />
        </Topbar>

        <List className="UsersList" >
          <InfiniteScroll
            dataLength={this.state.usersList.length}
            hasMore={(this.state.hasMore)}
            next={() => this.fetchUsers(this.state.query, this.state.nextOffset, true)}
            loader={
              <p>{t('general.loading')}...</p>
            }
          >
            {this.state.usersList.length > 0 &&
              this.state.usersList.map((e, i) => (
                <UserListItem
                  user={e}
                  key={(e.id + i)}
                  onDelete={this.deleteUser}
                  onMainClick={this.goToProfile}
                />
              ))}
          </InfiniteScroll>
        </List>

      </React.Fragment>

    );
  }
}
export default withSnackbar(withTranslation()(Users))