import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, InputBase, Typography, Select, MenuItem } from '@mui/material';

import Flags from 'assets/icons/publicApp/flags.svg';
import { updateSettingsLocale } from 'utils/SettingsService';
import i18n from 'utils/i18n';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    '&:focus': {},
  },
}));

const LocalizationSelection = ({ currentUser, lng, locales }) => {
  const [val, setVal] = useState(lng);

  const handleChange = async (event) => {
    setVal(event.target.value);
    let newLng = event.target.value;
    await localStorage.setItem('currentLng', newLng);
    newLng = newLng.toLowerCase();
    await updateSettingsLocale(newLng, currentUser.id);
    await i18n.changeLanguage(newLng);
  };

  const setFormLang = async () => {
    const formattedLanguage = locales.find((x) => x.key.toLowerCase() === val.toLowerCase())?.key;
    if (formattedLanguage) {
      setVal(formattedLanguage);
    }
  };

  useEffect(() => {
    if (locales.length > 0) {
      setFormLang();
    }
  }, [locales]);

  const CountryFlag = ({ countryCode }) => (
    <svg className="sidenav-country-flag">
      <use href={`${Flags}#${countryCode}`} />
    </svg>
  );

  return (
    <Select value={val} onChange={handleChange} input={<BootstrapInput />} className="sidenav-language-container">
      {locales &&
        locales.map((e, i) => (
          <MenuItem key={i} value={e.key}>
            <Grid container alignItems="center">
              <CountryFlag countryCode={e.key.slice(-2)} />
              <Typography pl={1}>{e.lng.toUpperCase()}</Typography>
            </Grid>
          </MenuItem>
        ))}
    </Select>
  );
};

export default LocalizationSelection;
