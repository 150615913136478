import {Button, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import {motion} from "framer-motion";

const ActionButton = ( props) => {
    const { onClick, type} = props;
    // const theme = useTheme();
    switch (type) {
        case 'delete':
            return (
                <Button className="actionBtn" aria-label="Delete" onClick={onClick}>
                    <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </Button>
            );
        // case 'custom':
        //         return (
        //             <Button className="actionBtn" aria-label="Delete" sm={border: 1px solid $secondary !important;
        //                 box-shadow: grey;
        //                 background: #FFFFFF;
        //                 display: flex;
        //                 align-items: center;
        //                 border-radius: 5px !important;
        //                 justify-content: center;
        //                 padding: 0 !important;
        //                 width: 25px;
        //                 height: 25px;
        //                 min-width: 60px !important;
        //                 color: $secondary !important;}
        //
        //
        //                     onClick={onClick}>
        //                 <Typography className="shareBtn-text">{title}</Typography>
        //             </Button>
        //         );
            }

    }

export default ActionButton;

