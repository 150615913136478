import { Topbar } from '../ui/topbar/Topbar';
import React, { useState, useEffect } from "react";
import { List, Grid } from "@mui/material";
import ListHead from '../ui/list/head/ListHead';
import { getParkingRates, deleteParkingRate } from "../../utils/ParkingRatesService";

import ParkingRatesItem from "./ParkingRatesItem";
import DeleteDialog from "../ui/dialogs/DeleteDialog";
import { useSnackbar } from "notistack";
import i18next from 'i18next';

const ParkingRates = (props) => {
    const title = i18next.t('parkinger.Rates');
    const listHead = i18next.t('parkinger.ParkingRates');
    const { enqueueSnackbar } = useSnackbar();
    const [rates, setRates] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');
    const [body, setBody] = useState('');
    const goBack = () => {
        props.history.goBack()
    }

    const onCreate = () => {
        props.history.push({
            pathname: '/parkingRates/form',
            state: {
                rates: {
                    id: '',
                    title: '',
                    rate: ''
                }, isChange: false
            }
        });
    };



    const deleteRate = async (id) => {


        const deleteRateFn = async (id) => {

            try {
                const { affectedIds } = await deleteParkingRate(id);
                if (affectedIds && affectedIds.length) {

                    { getRates() }
                    setOpen(false);

                    enqueueSnackbar(
                        (i18next.t('ParkingRatesForm.RateSuccessfullyDeleted')),
                        {
                            variant: 'success',
                        }
                    );
                }
            } catch (e) {
                console.error(e);
            }
        };
        deleteRateFn(id);

    };


    const getRates = async () => {
        const data = await getParkingRates();
        setRates(data);
    };

    useEffect(() => {
        { getRates() }

    }, []);


    return (
        <div>

            <Topbar title={title} />
            <Grid item paddingTop={7}>
                <ListHead title={listHead} onCreate={onCreate}> </ListHead>
                <List>
                    {rates && rates.map((e, i) => (
                        <ParkingRatesItem
                            Item
                            key={(e.id + i)}
                            rates={e}
                            deleteRate={deleteRate}
                            history={props.history}
                        />
                    ))
                    }
                </List>
            </Grid>
        </div>
    );

};

export default ParkingRates;