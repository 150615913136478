import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, Container, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateSettingsLocale } from 'utils/SettingsService';
import { getLocales } from 'utils/TranslationsService';
import i18n from 'utils/i18n';
import PublicTopbar from '../PublicTopbar';
import RadioButton from 'components/ui/buttons/RadioButton';
import './Settings.scss';
import Flags from 'assets/icons/publicApp/flags.svg';

const AccountLanguages = (props) => {
  const { t } = useTranslation();
  const user = props.currentUser;
  const [currentLng, setCurrentLng] = useState(localStorage.getItem('currentLng'));
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  // TODO: Get language names from db.
  const languageNames = [
    {
      name: 'English',
      key: 'en-gb',
    },
    {
      name: 'Lietuvių',
      key: 'lt-lt',
    },
    {
      name: 'Polski',
      key: 'pl-pl',
    },
    {
      name: 'Deutsch',
      key: 'de-de',
    },
    {
      name: 'Íslenska',
      key: 'is-is',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await props.checkAccount(props.history, false);
    await fetchLocales();
  };

  const fetchLocales = async () => {
    try {
      const { data } = await getLocales();
      const locales = await data.items.map((e) => {
        return { key: e.key, lng: e.key.slice(-2) };
      });

      const formattedLanguages = locales.map((locale) => {
        const language = languageNames.find((lang) => lang.key.toLowerCase() === locale.key.toLowerCase());
        return {
          key: locale.key.toLowerCase(),
          lng: locale.lng.toUpperCase(),
          name: language ? language.name : 'Unknown',
        };
      });

      setLanguages(formattedLanguages);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const clickLanguage = async (l) => {
    setCurrentLng(l);
    localStorage.setItem('currentLng', l);
    if (user) {
      await updateSettingsLocale(l, user.id);
    }
    i18n.changeLanguage(l);
  };

  const CountryFlag = ({ countryCode }) => (
    <svg className="img-flag">
      <use href={`${Flags}#${countryCode}`} />
    </svg>
  );

  return (
    <div className="public-container">
      <PublicTopbar title={t('publicApp.Language')} enableShadow />
      <Container pt={1} />
      <Typography variant="h4" className="h4-text-bold" sx={{ pt: 1.5, pl: 4, pb: 1 }}>
        {t('publicApp.ChooseLanguage')}
      </Typography>
      <Grid sx={{ px: 1 }}>
        {loading ? (
          <Skeleton height={100} variant="rounded" animation="wave" sx={{ bgcolor: '#fff' }} />
        ) : (
          <Card className="card-shadow">
            <Grid py={1}>
              {languages.map((language) => (
                <Grid key={language.key} sx={{ p: 1, px: 2 }}>
                  <Grid container justifyContent="space-between" alignItems="center" onClick={() => clickLanguage(language.key)} sx={{ cursor: 'pointer' }}>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item pr={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
                          <CountryFlag countryCode={language.lng} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h4" className="h4-text-light">
                            {t(language.name)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent="center">
                      <RadioButton value={language.key} color="secondary" onChange={() => {}} checked={currentLng.toLowerCase() === language.key} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Card>
        )}
      </Grid>
    </div>
  );
};

export default AccountLanguages;
