import './SettingsList.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography, Grid } from '@mui/material';
import {
  getSettings,
  requestNotificationPermissionAndUpdate,
  updateDateTimeSetting,
  updateUserSetting,
} from '../../utils/SettingsService';
import {
  UserNotificationConfig,
  DeadlineTime,
  ReminderTime,
  InputTypes,
} from './SettingProperties';
import ListHead from '../ui/list/head/ListHead';
import { useSnackbar } from 'notistack';
import SettingsItem from './SettingsItem';
import i18next from 'i18next';
import { featureEnabled } from '../../utils/FeatureTogglerService';
import { AUTO_MATCH, SET_UNBOOKING_TIME } from '../../constants/Features';

export default function SettingsList({
  showBack,
  title,
  currentUser,
  settingsFilterFn,
  settings,
  forceEnableEditing,
  lastUpdateDt,
  isAdmin,
  showAdmin,
  autoMatch,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [settingsList, setSettingsList] = useState(settings || []);
  const [dateSettingsList, setDateSettingsList] = useState(settings || []);
  const [switchSettingsList, setSwitchSettingsList] = useState(settings || []);
  const [otherSettingsList, setOtherSettingsList] = useState(settings || []);
  const [globalSettingsList, setGlobalSettingsList] = useState(settings || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [settingItem, setSettingItem] = useState(null);
  const [clearEnabled, setClearEnabled] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(new Date());
  const [isShow, setIsShow] = useState(null);
  const [setBookingTime, setSetBookingTime] = useState(null);

  const isSystemSettings = showAdmin !== null ? showAdmin : false;

  const [settingsFilter, setSettingsFilter] = useState(
    () => (items) =>
      settingsFilterFn ? settingsFilterFn(items) : defaultFilter(items)
  );

  const [dateSettingsFilter, setDateSettingsFilter] = useState(
    () => (items) => dateFilter(items)
  );

  const [switchSettingsFilter, setSwitchSettingsFilter] = useState(
    () => (items, switchSettingsFilter) => settingsFilterFn ? switchFilter(items, switchSettingsFilter) : switchFilter(items, switchSettingsFilter)
  );

  const [otherSettingsFilter, setOtherSettingsFilter] = useState(
    () => (items) => othersFilter(items)
  );

  const getUserSettings = (item) => {
    const userconsfig = [
      UserNotificationConfig,
      'UserNotificationConfigViolation',
      'UserNotificationConfigSilencePeriod',
      'UserNotificationConfigBookedSpace',
      'UserNotificationConfigReminderNotification',
      'UserNotificationConfigAdminViolationReport',
      'VehiclesLimit',
      DeadlineTime,
      ReminderTime,
    ];
    return userconsfig.includes(item.property);
  };

  const getDateUserSettings = (item) => {
    const userconsfig = [DeadlineTime, ReminderTime];

    if (isAdmin) {
      if (!item.ownerId) {
        return userconsfig.includes(item.property);
      }
    } else {
      return userconsfig.includes(item.property);
    }
  };

  const getOtherUserSettings = (item) => {
    const userconsfig = ['VehiclesLimit'];

    if (isAdmin) {
      if (!item.ownerId) {
        return userconsfig.includes(item.property);
      }
    } else {
      return userconsfig.includes(item.property);
    }
  };

  const getSwitchUserSettings = (item, showfeature) => {
    let userconsfig = [
      UserNotificationConfig,
      'UserNotificationConfigViolation',
      //'UserNotificationConfigSilencePeriod',
      'UserNotificationConfigBookedSpace',
      'UserNotificationConfigReminderNotification',
      'UserNotificationConfigAdminViolationReport',
    ];

    if (showfeature) {
      userconsfig = [
        UserNotificationConfig,
        'UserNotificationConfigViolation',
        'UserNotificationConfigSilencePeriod',
        'UserNotificationConfigBookedSpace',
        'UserNotificationConfigReminderNotification',
        'UserNotificationConfigAdminViolationReport',
      ];
    }
    // const userconsfig = [
    //   UserNotificationConfig,
    //   'UserNotificationConfigViolation',
    //   //'UserNotificationConfigSilencePeriod',
    //   'UserNotificationConfigBookedSpace',
    //   'UserNotificationConfigReminderNotification',
    //   'UserNotificationConfigAdminViolationReport',
    // ];
    if (isAdmin) {
      if (!item.ownerId) {
        return userconsfig.includes(item.property);
      }
    } else {
      //console.log ('else')
      return userconsfig.includes(item.property);
    }
  };

  const getSystemSettings = (items) => {
    const filtered = {};

    if (!items) return filtered;
    items.map((item, i) => {
      if (
        !item.ownerId &&
        getUserSettings(item)
      )
        filtered[item.property] = item;
    });

    return filtered;
  };

  const defaultFilter = (items) => {
    const filtered = {};

    if (!items) return filtered;
    items.map((item, i) => {
      if (!getUserSettings(item)) {
        return false;
      }

      let cachedSetting = filtered[item.property];
      if (
        !cachedSetting ||
        (!cachedSetting.ownerId &&
          item.ownerId &&
          item.ownerId === currentUser.id)
      ) {
        filtered[item.property] = item;
        return true;
      }

      return false;
    });

    return filtered;
  };
  const dateFilter = (items) => {
    const filtered = {};

    if (!items) return filtered;
    items.map((item, i) => {
      if (!getDateUserSettings(item)) {
        return false;
      }

      let cachedSetting = filtered[item.property];
      if (
        !cachedSetting ||
        (!cachedSetting.ownerId &&
          item.ownerId &&
          item.ownerId === currentUser.id)
      ) {
        filtered[item.property] = item;
        return true;
      }

      return false;
    });

    return filtered;
  };
  const switchFilter = (items, showfeature) => {
    const filtered = {};

    if (!items) return filtered;
    items.map((item, i) => {
      if (!getSwitchUserSettings(item, showfeature)) {
        return false;
      }

      let cachedSetting = filtered[item.property];
      if (
        !cachedSetting ||
        (!cachedSetting.ownerId &&
          item.ownerId &&
          item.ownerId === currentUser.id)
      ) {
        filtered[item.property] = item;
        return true;
      }

      return false;
    });

    return filtered;
  };
  const othersFilter = (items) => {
    const filtered = {};
    if (!items) return filtered;
    if (!isAdmin) return filtered;
    items.map((item, i) => {
      if (!getOtherUserSettings(item)) {
        return false;
      }

      let cachedSetting = filtered[item.property];
      if (
        !cachedSetting ||
        (!cachedSetting.ownerId &&
          item.ownerId &&
          item.ownerId === currentUser.id)
      ) {
        filtered[item.property] = item;
        return true;
      }

      return false;
    });

    return filtered;
  };

  const getItem = (nameProp) => {
    const list = globalSettingsList;
    const prop = nameProp;
    return list.find((x) => {
      return x.property === prop;
    });
  };

  const getGlobalNotificationValue = (item, configKey) => {
    const prop = item.property;
    const globalItem = getItem(prop);
    const value = globalItem.value;
    let config = null;
    const initNotificationConfig = () => {
      if (globalItem && globalItem.value) {
        return JSON.parse(globalItem.value);
      }
    };
    const notificationConfig = initNotificationConfig();
    Object.keys(notificationConfig).map((x, i) => {
      if (x === configKey) {
        config = notificationConfig[x];
      }
    });
    return config;
  };

  useEffect(() => {
    if (settings) {
      // passed settings, so no need to fetch them again,
      // this should be handled by parent component then
      setSettingsList(settings);
      return;
    }

    const fetchData = async () => {
      const showfeature = await featureEnabled(AUTO_MATCH);
      //  const auto_match = await featureEnabled(AUTO_MATCH);
      if (showfeature) {
        setIsShow(showfeature);
      }
      const showBooking = await featureEnabled(SET_UNBOOKING_TIME);
      //  const auto_match = await featureEnabled(AUTO_MATCH);
      if (showBooking) {
        setSetBookingTime(showBooking);
        console.log('showBooking', setBookingTime);
      }

      let checkDisabled = !forceEnableEditing;
      var { items } = await getSettings(currentUser?.id, checkDisabled);
      const globalSettings = getSystemSettings(items);
      setGlobalSettingsList(Object.values(globalSettings));

      const filtered = settingsFilter(items);
      const datefiltered = dateSettingsFilter(items);
      const switchfiltered = switchSettingsFilter(items, showfeature);
      const othersfiltered = otherSettingsFilter(items);

      setSettingsList(Object.values(filtered));
      setDateSettingsList(Object.values(datefiltered));
      setSwitchSettingsList(Object.values(switchfiltered));
      setOtherSettingsList(Object.values(othersfiltered));
    };

    fetchData();
  }, [
    currentUser.id,
    settingsFilter,
    successUpdate,
    settings,
    forceEnableEditing,
    lastUpdateDt,
    isShow,
    setBookingTime,
  ]);

  const getClearEnabled = (item) => {
    return false;
  };

  const handleClose = () => {
    setOpenDialog(false);
    setClearEnabled(false);
  };

  const onSuccessNotify = () => {
    enqueueSnackbar(t('notification.success.updateSetting'), {
      variant: 'success',
    });

    handleClose();
    setSuccessUpdate(new Date());
  };

  const onSaveNotification = async (settingItem) => {
    try {
      await requestNotificationPermissionAndUpdate(
        isSystemSettings,
        currentUser,
        settingItem,
        JSON.parse(settingItem.value),
        t
      );

      onSuccessNotify();
    } catch (error) {
      console.log(error);
    }
  };

  const onSaveDate = async (settingItem) => {
    try {
      await updateDateTimeSetting(isSystemSettings, settingItem, currentUser);

      onSuccessNotify();
    } catch (error) {
      console.log(error);
    }
  };

  const onSaveDefault = async (settingItem) => {
    try {
      await updateUserSetting(isSystemSettings, settingItem, settingItem.id);
      onSuccessNotify();
    } catch (error) {
      console.log(error);
    }
  };

  const handleYes = async () => {
    if (!settingItem) return;

    switch (settingItem.property) {
      case UserNotificationConfig:
      case 'UserNotificationConfigViolation':
      case 'UserNotificationConfigSilencePeriod':
      case 'UserNotificationConfigBookedSpace':
      case 'UserNotificationConfigReminderNotification':
        try {
          await requestNotificationPermissionAndUpdate(
            currentUser,
            settingItem,
            JSON.parse(settingItem.value),
            t
          );

          onSuccessNotify();
        } catch (error) {
          // leave open
          console.log(error);
        }

        break;
      case ReminderTime:
      case DeadlineTime:
        try {
          await updateDateTimeSetting(
            isSystemSettings,
            settingItem,
            currentUser
          );
          onSuccessNotify();
        } catch (error) {

          console.log(error);
        }
        break;
      default:
        try {
          await updateUserSetting(settingItem, settingItem.id);
          onSuccessNotify();
        } catch (error) {
          console.log(error);
        }
        break;
    }
  };

  const getType = (item) => {
    let itemType = InputTypes.find(x => x.property === item.property)?.type;
    return (itemType);
  }
  const setEditDisabled = (item) => {
    if (forceEnableEditing) {
      return false;
    }

    if (item?.disabled) {
      return item.disabled;
    }

    return false;
  };

  return (
    <React.Fragment>
      <ListHead title={t(title)} className="settingsContainer" />
      <Grid paddingLeft={2}>
        <Typography className="h3" color="#707070">
          {i18next.t('parkinger.Deadline')}
        </Typography>
      </Grid>
      <List>
        {dateSettingsList &&
          dateSettingsList.map((item, i) => (
            <React.Fragment key={item.id}>
              <ListItem className="settingsContainerListItem">
                <SettingsItem
                  autoMatch={isShow}
                  setBookingTime={setBookingTime}
                  item={item}
                  itemType={getType(item)}
                  setSettingItem={setSettingItem}
                  onHandleYes={handleYes}
                  clearEnabled={clearEnabled}
                  onSaveDate={onSaveDate}
                  isAdmin={isAdmin}
                ></SettingsItem>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
      <Grid paddingLeft={2}>
        <Typography className="h3" color="#707070">
          {i18next.t('parkinger.Notifications')}
        </Typography>
      </Grid>
      <Grid container xs justifyContent="center" alignItems="center">
        <Grid item xs>
        </Grid>
        <Grid item paddingRight={2.5}>
          <Typography className="subtittle1">
            {i18next.t('parkinger.ReceiveNotifications')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs justifyContent='center' alignItems='center' p={1}>
        <Grid item xs> </Grid>
        <Grid item paddingRight={0}>

          <Typography  >
            {i18next.t('ReceiveNotifications.Email')} | {i18next.t('ReceiveNotifications.Push')}
          </Typography>
        </Grid>
      </Grid>
      <List>
        {switchSettingsList &&
          switchSettingsList.map((item, i) => (
            <React.Fragment key={item.id}>
              <ListItem className="settingsContainerListItem">
                <SettingsItem
                  globalSetting={getGlobalNotificationValue}
                  item={item}
                  itemType={getType(item)}
                  setSettingItem={setSettingItem}
                  onHandleYes={handleYes}
                  clearEnabled={clearEnabled}
                  onSaveNotification={onSaveNotification}
                  isAdmin={isAdmin}
                ></SettingsItem>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
      <Grid paddingLeft={2}>
        {isAdmin && (
          <Typography className="h3" color="#707070">
            {i18next.t('parkinger.OtherLimits')}
          </Typography>
        )}
      </Grid>
      <List>
        {otherSettingsList &&
          otherSettingsList.map((item, i) => (
            <React.Fragment key={item.id}>
              <ListItem className="settingsContainerListItem">
                <SettingsItem
                  item={item}
                  itemType={getType(item)}
                  setSettingItem={setSettingItem}
                  onHandleYes={handleYes}
                  clearEnabled={clearEnabled}
                  onSaveDefault={onSaveDefault}
                ></SettingsItem>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </React.Fragment>
  );
}
