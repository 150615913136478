import React from 'react';
import './SilencePeriodFormHead.scss';
import background_y from '../../../assets/images/holiday.png';
import background_g from '../../../assets/images/needs_reservation.png';

import { Box, Typography, Grid } from '@mui/material';

const SilencePeriodFormHead = (props) => (
  <React.Fragment>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {props.isSilencePeriod ? (
        <img
          src={background_g}
          className="SilencePeriodHeadBackround"
          alt="background"
        />
      ) : (
        <img
          src={background_y}
          className="SilencePeriodHeadBackround"
          alt="background"
        />
      )}

      {/* <Box className="SilencePeriodHeadCard">
        <Typography className="SilencePeriodName">
          {'subscriptions.SilencePeriod'}
        </Typography>
        <Typography>{props.space.parkingLotName}</Typography>
        <Box className="triangleDown" />
      </Box> */}
    </Grid>
  </React.Fragment>
);

export default SilencePeriodFormHead;
