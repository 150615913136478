import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid,  Container } from '@mui/material';
import './Settings.scss';
import PublicTopbar from '../PublicTopbar';
import LinkBank from '../providers/kevin/LinkBank';

class AccountPayments extends Component {
  constructor() {
    super();
  }

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, false);
  };

  navigate = async (pathname) => {
            this.props.history.push({ pathname: pathname });
        }

  render() {
    const { t } = this.props;

    return (
      <Grid className="public-container">
        <PublicTopbar enableShadow title={t('publicApp.PaymentMethod')} />

        <Container sx={{ paddingTop: 1 }}></Container>
        <LinkBank navigate={this.navigate}/>

      </Grid>
    );
  }
}

export default withSnackbar(withTranslation()(AccountPayments));
