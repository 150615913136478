import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import './Topbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPlus, faEdit, faArrowLeft, faCogs , faEnvelope} from '@fortawesome/free-solid-svg-icons';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid'
import Colors from '../../../constants/Colors';
import {Typography} from "@mui/material";

export class Topbar extends Component {

  render() {
    const { title, location, cbCreate, cbEdit, cbBack, cbSettings, cbSend, disableShadow, childClass, hideSidebar  } = this.props;

    return (
      <AppBar
        color='inherit'
        elevation= {disableShadow ? 0 : 2}
        height = '50px'
        sx={{
          height: '50px'
         // bgcolor: theme.palette.background.default,
         // transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
       // class = 'App'
        className={disableShadow ? 'disableShadow' : null}
      >
        <Toolbar >
          <Grid
            container
            direction="row"
            // justify="space-between"
            alignItems="center"
            justifyContent='space-between'
          >
            {cbBack
              ?
              <IconButton edge="start" onClick={this.props.cbBack} aria-label="Menu" className ='iconLeft'>
                <FontAwesomeIcon icon={faArrowLeft} color={Colors.iconHead} />
              </IconButton>
              :
              this.props.closeDialog
                ?
                <IconButton edge="start" onClick={this.props.closeDialog} aria-label="Menu" className ='iconLeft'>
                  <FontAwesomeIcon icon={faArrowLeft} color={Colors.iconHead} />
                </IconButton>
                :
                hideSidebar
                  ? 
                  <IconButton />
                  :
                  <Link to={
                    {
                      pathname: location && location.pathname,
                      state: {
                        openSideNav: true
                      }
                    }
                  }>
                    <IconButton edge="start" onClick={this.props.closeDialog} aria-label="Menu" className ='iconLeft'>
                      <FontAwesomeIcon icon={faBars} color={Colors.iconHead} />
                    </IconButton>
                  </Link>
            }
            <Typography className ='h2' color = '#3A4D8F'>
              {title}
            </Typography>
            {/*<h1 className="title">*/}
            {/*  {title}*/}
            {/*</h1>*/}
            {cbCreate
              ?
              <IconButton onClick={cbCreate} edge="end" aria-label="Action" className ='iconLeft'>
                <FontAwesomeIcon icon={faPlus} color={Colors.iconHead} />
              </IconButton>
              : cbEdit
                ?
                <IconButton onClick={cbEdit} edge="end" aria-label="Action" className ='iconLeft'>
                  <FontAwesomeIcon icon={faEdit} color={Colors.iconHead} />
                </IconButton>

                : cbSettings
                ?
              <IconButton onClick={cbSettings} edge="end" aria-label="Action" className ='iconLeft'>
              <FontAwesomeIcon icon={faCogs} color={Colors.iconHead} />
              </IconButton>
                        : cbSend
                            ?
                            <IconButton onClick={cbSend} edge="end" aria-label="Action" className ='iconLeft'>
                              <FontAwesomeIcon icon={faEnvelope} color={Colors.iconHead} />
                            </IconButton>
              :
              <IconButton />

            }
          </Grid>

        </Toolbar>
        {this.props.children &&
          <Toolbar className={childClass}>
            {this.props.children}
          </Toolbar>
        }

      </AppBar>
    );
  }
}