

 // material
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Box, Button, ButtonBase, Divider, useMediaQuery } from '@mui/material';
 import {useEffect, useState, useCallback} from 'react';
 import useAuth from 'hooks/useAuth';
 // other
 import firebase from 'firebase';
 // project imports
 import MainCard from '../../ui-component/MainCard';

 import { getCurrentUser } from '../../utils/UsersService';
 import { getUserAvailableSites } from '../../utils/AvailableSitesService';
 import AuthCardWrapper from '../auth/authentication/AuthCardWrapper';
 import AuthWrapper1 from '../auth/authentication/AuthWrapper1';
 import AnimateButton from '../../ui-component/AnimateButton';
 import { Link } from 'react-router-dom';

const UserSites = () => {
  const { logout, user } = useAuth();
// const [user , setUser] = useState(null);
   const [isAuth, setIsAuth] = useState(false);
   const [isAdmin, setIsAdmin] = useState(false);
   const [sites, setSites] = useState([]);
   const [currentUser, setCurrentUser] = useState([]);
   const [haveConsent, setHaveConsent] = useState(false)
   const sparams = window.location.search;

   const isNew = true;
   const fetchCurrentUser = async (isNew) => {
     const currentUser = await getCurrentUser(isNew)
     if (currentUser) {
       await setCurrentUser(currentUser);
       setIsAuth(true);
       //  this.checkPushSubscription(currentUser)
     } else {
       this.setState({ isAuth: false })
     }
   }
   const id = currentUser.id;

   // TODO to store
   const handleDrawerOpen = (id) =>
   {

     localStorage.removeItem('activeSite');
     localStorage.setItem('activeSite', id);
   }

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };
   const fetchAvailableSites = useCallback(async () => {
     try {
       const { data } = await getUserAvailableSites(id);
       if (data.items){
         setSites(data.items);
         localStorage.setItem('sites', data.items);
       } else {
         setSites([{siteId: 1 , siteName: 'PUBLIC', siteUri: 'kazkas', id: 'abc'},
           {siteId: 2 , siteName: 'SEB', siteUri: 'kazkas', id: 'abc'},
           {siteId: 3 , siteName: 'SWEDBANK', siteUri: 'kazkas', id: 'abc'}
         ]);
       }
     } catch (error) {
       setSites([{siteId: 1 , siteName: 'PUBLIC', siteUri: 'kazkas', id: 'abc'},
         {siteId: 2 , siteName: 'SEB', siteUri: 'kazkas', id: 'abc'},
         {siteId: 3 , siteName: 'SWEDBANK', siteUri: 'kazkas', id: 'abc'}
       ]);
       throw new Error(error);

     }
   }, []);

   useEffect(
     () => {
       {fetchCurrentUser()}
       {fetchAvailableSites()}
     }, []
   );
   const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
   return (
     <AuthWrapper1>
     <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }} >
       <Grid item xs={12}>
         <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
           <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
             <AuthCardWrapper>
               <Grid container spacing={2} alignItems="center" justifyContent="center">
                 <Grid item sx={{ mb: 3 }}>
                   {/*<Link to="#">*/}
                   {/*  <Logo />*/}
                   {/*</Link>*/}
                 </Grid>
                 <Grid item xs={12}>
                   <Grid container alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                     <Grid item xs={12}>
                       <Typography
                         color={theme.palette.secondary.main}
                         gutterBottom
                         variant={matchDownSM ? 'h3' : 'h2'}
                       >
                         Stovėjimo aplinkos
                       </Typography>
                     </Grid>
                     <Grid item xs={12}>
                       <Typography variant="caption" fontSize="16px" textAlign="center">
                       Pasirinkite į kurią aplinką norite pereiti
                       </Typography>
                     </Grid>
                   </Grid>
                 </Grid>
                 <Grid item xs={12}>
                   {/*pasiekiami site */}
                   <Grid container>
                     <Grid container spacing={3} >
                       {sites.map((sites, index) => {
                         const darkBorder = theme.palette.primary[200] + 75;
                         return (
                           <Grid item xs={12}  key={index} justifyContent='center'>
                             <AnimateButton>
                             <MainCard
                               boxShadow
                               sx={{

                               //  pt: 1.75,
                                 // border: plan.active ? '2px solid' : '1px solid',
                                 // borderColor: plan.active ? 'secondary.main' : darkBorder
                               }}
                             >
                               <ButtonBase disableRipple alignItems="center" onClick={handleDrawerOpen(sites.id)}>

                                   <Grid item container  alignItems="center" xs={12}>
                                     <Typography
                                     color={theme.palette.primary.main}
                                     textAlign='center'
                                     justifySelf='center'
                                     //gutterBottom
                                     variant={matchDownSM ? 'h3' : 'h2'}
                                       >
                                       {sites.siteName}</Typography>
                                   </Grid>


                               </ButtonBase>


                             </MainCard>
                             </AnimateButton>
                           </Grid>
                         )
                       })};
                     </Grid>
                   </Grid>

                 </Grid>
                 <Grid item xs={12}>
                   <Divider />
                 </Grid>
                 <Grid item xs={12}>
                   <Grid item container direction="column" alignItems="center" xs={12}>
                     <Button
                       disableElevation
                       fullWidth
                       onClick={handleLogout}
                       size="large"
                       variant="outlined"
                       sx={{
                         color: 'grey.700',
                         backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                         borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : theme.palette.grey[300]
                       }}
                     >
                       Log out
                     </Button >
                   </Grid>
                 </Grid>
               </Grid>
             </AuthCardWrapper>
           </Grid>
         </Grid>
       </Grid>
       <Grid item xs={12} sx={{ m: 3, mt: 1 }}>

       </Grid>
     </Grid>
     </AuthWrapper1>
   )



  // );
 };

export default UserSites ;