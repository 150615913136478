import PropTypes from 'prop-types';
import React, { useState } from 'react';
import i18next from 'i18next';
import './PublicAuth.scss'
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Grid, Stack, Typography } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import useScriptRef from '../../../../hooks/useScriptRef';
import Visibility from '@mui/icons-material/Visibility';
import SquareIcon from '@mui/icons-material/Square';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NotificationManager } from 'react-notifications';
import PasswordIcon from '@mui/icons-material/Password';
import EmailIcon from '@mui/icons-material/Email';

const AuthLogin = ({ onCreated }) => {
    const scriptedRef = useScriptRef();
    const [send, setSend] = useState(true);
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
        
        const { firebaseRegister, firebaseEmailPasswordSignIn,  updateProfile} = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [singUp, setSingUp] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {

        // console.log('submit')
        // NotificationManager.info('development in progress');
        event.preventDefault();
        if (singUp) {
            console.log(email, password)
            // await createUserWithEmailAndPassword(auth, email, password)
            // .then((userCredential) => {
            //     // Signed in
            //     const user = userCredential.user;
            //     console.log(user);
            //     // navigate("/login")
            //     // ...
            // })
            // .catch((error) => {
            //     const errorCode = error.code;
            //     const errorMessage = error.message;
            //     console.log(errorCode, errorMessage);
            //     // ..
            // });
            await firebaseRegister(email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                //localStorage.setItem('currentPath', '/login');
                
                console.log(user);
                onCreated(email);
                //setSingUp(true);
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
        }
        else{
            //await firebaseEmailPasswordSignIn(email, password);
            // .then((userCredential) => {
            //     // Signed in
            //     const user = userCredential.user;
            //     localStorage.setItem('currentPath', '/publicMap');
            //     localStorage.setItem('emailForSignIn', email);
            //      updateProfile();
            //     console.log(user);
            // })
            // .catch((error) => {
            //     const errorCode = error.code;
            //     const errorMessage = error.message;
            //     console.log(errorCode, errorMessage)
            // });
        }
    };
    const handleClickShowPassword = () => {
        setShowPassword(true);
    }
    const handleMouseDownPassword = () => {
        setShowPassword(false);
    }
    const handleChangeAction = () => {
        const action = !singUp;
        setSingUp(action);
    }

    return (
        <>
            <Box sx={{ mt: 6 }}>
                <form noValidate onSubmit={handleSubmit}>
                <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth >
                                <TextField
                                    id="outlined-size-small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="authInput"
                                    placeholder="Email"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                    {/* <FormControl fullWidth >
                        <TextField
                            label="Email"
                            id="outlined-size-small"
                            size="small"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl> */}
                    <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth >
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    id="outlined-adornment-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="authInput"
                                    placeholder='Password'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Box>
                    {/* <Box sx={{ mt: 2 }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel size="small" htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                size="small"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Box> */}
                    <Box sx={{ mt: 3 }}>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className='auth-button'
                        >
                            {!singUp ?
                                ('Sign in with Email')
                                : ('Sing Up')}
                        </Button>
                    </Box>
                    {/* <Grid item xs={12} sx={{ mt: 2 }}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item>
                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                    <Typography sx={{ mt: 0 }}
                                        variant="caption"
                                        fontSize={"0.875rem"}
                                    >
                                        <Button sx={{ p: 0 }} onClick={handleChangeAction}>
                                            {singUp ?
                                                ('Don’t have an account? Sign up')
                                                : ('Already have an account?')}
                                        </Button>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </form>
            </Box>
        </>
    );
};

AuthLogin.propTypes = {
    loginProp: PropTypes.number
};

export default AuthLogin;
